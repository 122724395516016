import React from "react";
import PropTypes from "prop-types";

export const Search = ({ onChange, text }) => {
  return (
    <span className="inventory-export__search">
      <label>{Mashie.Resources.GetString("OrganizationSearchDialog_FreeText")}</label>
      <input type="text" onChange={onChange} value={text} />
    </span>
  );
};

Search.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string
};
