"use strict";

export function feature_js_recipelabel() {
    var foundVariant = $('.recipelabel-list-group-item');
    if (foundVariant.length == 0) {
        $('.custom-ingredient-allergen-container').hide();
    }

    if ($('[js-content]').attr('js-is-locked')) {
        $('#recipelabel-information-locked').show();
        $('#recipelabel-information-unlocked').hide();
        $('.recipelabel-templete-label').prop('readonly', true);
        $('.recipelabel-template-checkbox').prop('disabled', true);
        $('#wysiwyg').hide();
        $('#no-wysiwyg').show();
    }
    else {
        $('#recipelabel-information-locked').hide();
        $('#recipelabel-information-unlocked').show();
        $('.recipelabel-templete-label').prop('readonly', false);
        $('.recipelabel-template-checkbox').prop('disabled', false);
        $('#wysiwyg').show();
        $('#no-wysiwyg').hide();
    }

    $('[id$=SaveBarButton').click(function () {
        if (!$('[js-content]').attr('js-is-locked')) $('[recipe-information-save]').trigger('click');
    });

    $('[id$=LockStateButton]').click(function () {
        if (!$('[js-content]').attr('js-is-locked')) $('[recipe-information-save]').trigger('click');
    });
}