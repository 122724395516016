import { ClientDietRequest, MealOption } from "./MealSettings";

interface state {
    mealOptions: MealOption[];
}

interface cachedState {
    state: state;
}

const mapMealOptionToClientDietRequest = (
    mealOption: MealOption
): ClientDietRequest => {
    const { mealOptionId, foodFree, toBeOrdered, disabled, dietPreferences } =
        mealOption;

    const dietPreferencesId = dietPreferences.map((p) => p.id);

    return {
        mealOptionId,
        toBeOrdered,
        disabled,
        foodFree,
        dietPreferenceIds: dietPreferencesId,
    };
};
const input = $("[id$=hf_clientdietsetting]")[0] as HTMLInputElement;

export const setValueToInput = (mealOptions: MealOption[]) => {
    const updatedRequests: ClientDietRequest[] = mealOptions.map((option) =>
        mapMealOptionToClientDietRequest(option)
    );
    input.value = JSON.stringify(updatedRequests);
};

export const resetCachedStorage = () => {
    sessionStorage.clear();
    input.value = "";
};

export const getCachedMealOptions = (): MealOption[] | null => {
    try {
        var cashedState = JSON.parse(
            sessionStorage.getItem("mealOptions") || ""
        ) as cachedState;
        return input.value ? cashedState.state.mealOptions : null;
    } catch {
        console.log("storage not found");
    }

    return null;
};
