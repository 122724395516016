"use strict";

export function feature_js_table_add_row(el) {
    el.click(function () {
        var tableContainer = el.closest('[js-table]');
        var placeholder = tableContainer.find('[js-table--add-row-placeholder]').html();
        var tbody = tableContainer.find('table > tbody');

        var ordinal = _.max(_.map(tbody.find('[js-table--sortable-ordinal]'), function (v) {
            return Number(jQuery(v).val());
        }).concat(0));

        var template = _.template(placeholder);
        var compiled = template({ index: ordinal + 1 });
        tbody.append(compiled);

        tbody.closest('form').trigger('dirty');
        jQuery(document).feature();
    });
}