import React from 'react';
import { Flex, Box } from '../../../react-shared/flexbox';
import Spinner from "../../../react-shared/Spinner";
import { ClimateSelect } from './ClimateSelect';
import { ClimateSearchField } from './ClimateSearchField';

export class ClimateSearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: -1,
            isSearching: false,
            climatedata: [],
            error: ""
        };
    }

    setIsSearching = (isSearching) => {
        this.setState({ isSearching: isSearching });
    }

    onError = () => {
        this.updateSearchResult();
        this.setState({
            error: Mashie.Resources.GetString('Climate.Search.Error')
        });
    };

    updateSearchResult = data => {
        this.setState({
            climatedata: [],
            selectedValue: -1
        });

        if (!data || data.length == 0) {
            return;
        }

        this.setState({
            error: '',
            climatedata: data
        });
    };

    setSelectedValue = (data, selectedValue) => {
        this.setState({ selectedValue: selectedValue });
        this.props.setSelectedValue(data);
    }

    render() {
        return (
            <Flex flexDirection={'row'}>
                <Box flexBasis={'435px'}>
                    <Flex flexDirection={'row'}>
                        <Box flexBasis={'400px'} alignSelf={'flex-start'}>
                            <ClimateSearchField
                                itemName={this.props.itemName}
                                isLocked={this.props.isLocked}
                                slvId={this.props.slvId}
                                setIsSearching={this.setIsSearching}
                                updateSearchResult={this.updateSearchResult}
                                onError={this.onError}
                            />

                        </Box>
                        <Box flexBasis={'35px'} alignSelf={'flex-end'}>
                            {this.state.isSearching && <Spinner size={23} />}
                        </Box>
                    </Flex>
                </Box>
                <Box flexBasis={'80%'} alignSelf={'flex-start'}>
                    <ClimateSelect
                        error={this.state.error}
                        climatedata={this.state.climatedata}
                        selectedValue={this.state.selectedValue}
                        selectedClimateChanged={this.setSelectedValue}
                    />
                </Box>
            </Flex>
        );
    }
}