"use strict";
import React from "react";
import Store, { translateLogType } from "./IntegraitonplatformStore";
import { observer } from "mobx-react";
import Translate from "../../react-shared/Translations";
import { getEmptyTableContent } from "./integration-platform";
import LoadingButton from "@mui/lab/LoadingButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ErrorIcon from "@mui/icons-material/Error";
import { AbnormalState } from "../../react-shared/EmptyState/AbnormalState";

@observer
export class EventLogPage extends React.Component {
  constructor(props: any) {
    super(props);
  }

  getLogTableContent = () => {
    return (Store.logs ?? []).map((r, index) => {
      return (
        <tr key={index}>
          <td>{Store.convertUTCDateToLocalDate(r.created)}</td>
          <td>{translateLogType(r.type)}</td>
          <td>{r.logMessage}</td>
        </tr>
      );
    });
  };

  render() {
    console.log(Store.EventLogTabState);
    return (
      <>
        <div style={{ padding: "10px" }}>
          <div style={{ paddingBottom: "10px" }}>
            <b>{Translate("IntegrationPlatform_From")}:</b>
            <input
              style={{ margin: "0 7px", padding: "2px 8px 2px 10px", border: "1px solid gray", borderRadius: "4px" }}
              type="date"
              value={Store.logsFromDate}
              onChange={(e) => Store.handleLogsFromDateValueChange(e.target.value)}
            />
            <b>{Translate("IntegrationPlatform_To")}:</b>
            <input
              style={{ margin: "0 7px", padding: "2px 8px 2px 10px", border: "1px solid gray", borderRadius: "4px" }}
              type="date"
              value={Store.logsToDate}
              onChange={(e) => Store.handleLogsToDateValueChange(e.target.value)}
            />
            <LoadingButton
              style={{ margin: "0 10px", fontSize: "inherit", textTransform: "capitalize" }}
              disabled={Store.EventLogTabState == "LOADING"}
              loading={Store.EventLogTabState == "LOADING"}
              color="primary"
              variant="contained"
              loadingPosition="end"
              endIcon={
                Store.EventLogTabState == "ERROR" ? (
                  <>
                    <ErrorIcon />
                  </>
                ) : (
                  <>
                    <PlayArrowIcon sx={{ fontSize: "2.3rem" }} />
                  </>
                )
              }
              onClick={Store.loadLogs}
              title={Translate("IntegrationPlatform_ShowForSelectedPeriod")}
            >
              {Translate("IntegrationPlatform_ShowForSelectedPeriod")}
            </LoadingButton>
          </div>
          {Store.EventLogTabState != "OK" ? (
            <AbnormalState
              stateOfPage={Store.EventLogTabState}
              headerText={Store.primaryStateText}
              message={Store.secondaryStateText}
            />
          ) : (
            <table className="table table-bordered table-striped separate">
              <thead>
                <tr>
                  <th style={{ width: "300px" }}>{Translate("IntegrationPlatform_Date")}</th>
                  <th>{Translate("IntegrationPlatform_Status")}</th>
                  <th>{Translate("IntegrationPlatform_Message")}</th>
                </tr>
              </thead>
              <tbody>{(Store.logs ?? []).length > 0 ? this.getLogTableContent() : getEmptyTableContent(3)}</tbody>
            </table>
          )}
        </div>
      </>
    );
  }
}
