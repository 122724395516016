import React from "react";
import PropTypes from "prop-types";
import { InvSearchResultRow } from "./InvSearchResultRow";
import { Search } from "./Search";
import { SelectAllButton } from "./SelectAllButton";

export class SearchDialog extends React.Component {
  state = {
    selectedStock: []
  };

  componentDidUpdate(prevProps) {
    if (prevProps.results.length !== this.props.results.length) {
      this.setState({
        selectedStock: this.state.selectedStock.filter(inventory => this.props.results.filter(result => result.id === inventory).length)
      });
    }
  }

  toggleStockState = inventory => {
    if (this.state.selectedStock.includes(inventory)) {
      this.setState({ selectedInvs: this.state.selectedStock.filter(selected => selected.id !== inventory.id) });
      return;
    }
    this.setState({ selectedStock: [...this.state.selectedStock, inventory] });
  };

  willSelectAll = event => {
    event.preventDefault();
    this.setState({ selectedStock: this.props.results.map(result => result) });
  };

  willDeselectAll = event => {
    event.preventDefault();
    this.setState({ selectedStock: [] });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.selectedStock);
  };

  onClose = () => {
    this.props.willClose();
  };

  render() {
    return (
      <div>
        <Search onChange={this.props.searchWillChange} text={this.props.text} />
        <SelectAllButton
          selectedLength={this.state.selectedStock.length}
          resultsLength={this.props.results.length}
          onClickSelect={this.willSelectAll}
          onClickDeselect={this.willDeselectAll}
        />
        <div className="inventory-export__results">
          <ul>
            {this.props.results.map(inv => (
              <InvSearchResultRow checked={this.state.selectedStock.includes(inv)} inv={inv} onClick={this.toggleStockState} key={inv.id} />
            ))}
          </ul>
        </div>
        <div className="inventory-export__footer">
          <button type="button" className="btn btn-default" onClick={this.onSubmit}>
            {Mashie.Resources.GetString("btn_Select")}
          </button>
          <button type="button" className="btn btn-default" onClick={this.onClose}>
            {Mashie.Resources.GetString("btn_Cancel")}
          </button>
        </div>
      </div>
    );
  }
}

SearchDialog.propTypes = {
  districts: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.arrayOf(PropTypes.object),
  regions: PropTypes.arrayOf(PropTypes.object),
  results: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string,
  filterWillChange: PropTypes.func,
  searchWillChange: PropTypes.func,
  onSubmit: PropTypes.func,
  willClose: PropTypes.func
};
