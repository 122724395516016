"use strict";

export function feature_recipe_information_new(el) {
    el.click(function () {
        $('[js-new-input-container]').show();
        tinymce.get($('[js-recipelabel]').find('[js-wysiwyg]').attr('id')).setContent($('[js-recipelabel]').find('[js-orginalContent]').html());

        $('[js-recipelabel]').find('.active').removeClass('active');
        $('[js-recipelabel]').find('[js-wysiwyg]').text($('[js-recipelabel]').find('[js-orginalContent]').html());

        var allergens = $('[js-recipelabel]').find('[js-orginalAllergens]').val();
        $('[js-input-new-templete]').attr('data-allergens', allergens);
        $('[js-input-new-templete]').click();
        $('[js-input-new-templete]').focus();

        $('.custom-ingredient-allergen-container').show();
    });
}