import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import { translate } from "../Translations/labelStrings";

export interface DietPreferencesDialogProps {
    open: boolean;
    onConfirm: () => void;
    onAbort: () => void;
    children: ReactNode;
    title?: ReactNode;
    destructive?: boolean;
}

const DietPreferencesDialog = ({
    open,
    onConfirm,
    onAbort,
    children,
    title,
}: DietPreferencesDialogProps) => {
    return (
        <Dialog open={open} onClose={onAbort} maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onAbort}>
                    <Typography variant="button">
                        {translate("Abort_Button")}
                    </Typography>
                </Button>
                <Button onClick={onConfirm} variant="contained">
                    <Typography variant="button">
                        {translate("btn_Choose")}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DietPreferencesDialog;
