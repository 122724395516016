import {
    Checkbox,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import { OrderAppConfigState } from "../stores/OrderAppConfigState";
import { CustomerCategory } from "../domain/CustomerCategory";
import { useConfigurationsStore } from "../stores/orderAppConfigStore";
import { translate } from "../Translations/labelStrings";
import AppConfigLabeledCheckbox from "./AppConfigLabeledCheckbox";

interface Props {
    orderAppConfigurations: OrderAppConfigState[];
    customerCategories: CustomerCategory[];
    isLocked: boolean;
}

export const CategoryConfigurationTable = ({
    customerCategories,
    isLocked,
}: Props) => {
    const [showIngredientsHeaderChecked, setShowIngredientsHeaderChecked] =
        useState(false);
    const [showImagesHeaderChecked, setShowImagesHeaderChecked] =
        useState(false);
    const {
        orderAppConfigurations,
        deleteConfiguration,
        updateConfiguration,
        setAllShowImages,
        setAllShowIngredients,
    } = useConfigurationsStore();

    useEffect(() => {
        setShowIngredientsHeaderChecked(
            orderAppConfigurations.length > 0 &&
                orderAppConfigurations.every(
                    (config) => config.isIngredientVisible === true
                )
        );

        setShowImagesHeaderChecked(
            orderAppConfigurations.length > 0 &&
                orderAppConfigurations.every(
                    (config) => config.isImageVisible === true
                )
        );
    }, [orderAppConfigurations]);

    const getSelected = (id: string) => {
        return customerCategories.find((c) => c.id === id);
    };

    const chosenIds = orderAppConfigurations.map(
        (config) => config.customerCategoryId
    );

    const categoryItems = (category: CustomerCategory | undefined) => {
        return customerCategories.filter(
            (c) => c.id === category?.id || !chosenIds.includes(c.id)
        );
    };

    const handleDeleteClick = (config: OrderAppConfigState) => {
        deleteConfiguration(config);
    };

    const handleOnChange = (id: string, config: OrderAppConfigState) => {
        config.customerCategoryId = id;
        updateConfiguration(config);
    };

    const handleIngredientsChange = (
        config: OrderAppConfigState,
        checked: boolean
    ) => {
        config.isIngredientVisible = checked;
        updateConfiguration(config);
    };

    const handleImageChange = (
        config: OrderAppConfigState,
        checked: boolean
    ) => {
        config.isImageVisible = checked;
        updateConfiguration(config);
    };
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 400 }}>
                            <Typography
                                variant={"h6"}
                                color="text.primary"
                                fontWeight={"bold"}
                            >
                                {translate("OAC_customer_category_header")} *
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <AppConfigLabeledCheckbox
                                checked={showIngredientsHeaderChecked}
                                disabled={isLocked}
                                onChange={(checked) =>
                                    setAllShowIngredients(checked)
                                }
                                labelText={translate("lbl_Show_Ingredients")}
                            />
                        </TableCell>
                        <TableCell>
                            <AppConfigLabeledCheckbox
                                checked={showImagesHeaderChecked}
                                disabled={isLocked}
                                onChange={(checked) =>
                                    setAllShowImages(checked)
                                }
                                labelText={translate("lbl_show_images")}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderAppConfigurations.map((config, index) => {
                        let selected = getSelected(config.customerCategoryId);
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <FormControl
                                        required
                                        variant="standard"
                                        sx={{
                                            m: 1,
                                            minWidth: 150,
                                            marginTop: 0,
                                        }}
                                    >
                                        <Select
                                            value={selected?.id || ""}
                                            disabled={isLocked}
                                            onChange={(e) =>
                                                handleOnChange(
                                                    e.target.value,
                                                    config
                                                )
                                            }
                                        >
                                            {categoryItems(selected).map(
                                                (category) => (
                                                    <MenuItem
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        <Typography
                                                            variant={"h6"}
                                                            color="text.primary"
                                                        >
                                                            {category.name}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={config.isIngredientVisible}
                                        disabled={isLocked}
                                        onChange={(e) =>
                                            handleIngredientsChange(
                                                config,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={config.isImageVisible}
                                        disabled={isLocked}
                                        onChange={(e) =>
                                            handleImageChange(
                                                config,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() =>
                                                handleDeleteClick(config)
                                            }
                                            disabled={isLocked}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
