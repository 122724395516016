﻿"use strict";

exports.feature = function feature(name, callback) {
    jQuery(document).on('feature', function (evt, root) {
        var selector = '[' + name + ']:not([' + name + '---feature-on])';
        var items = jQuery(root).find(selector).addBack(selector);
        items.each(function () {
            var self = $(this);
            if (self.parentsUntil(root, '[js-feature-block]').length) return;
            var value = self.attr(name);
            self.attr(name + '---feature-on', true);
            setTimeout(function () {
                callback(self, value);
            }, 0);
        });
    });
};



exports.trigger = function trigger(context) {
    jQuery(document).trigger('feature', context || document);
};

jQuery.feature = exports.feature;
jQuery(function () { exports.trigger(); });
exports.trigger();
jQuery.fn.feature = exports.trigger;

jQuery.feature_import = function (name, loadModule, selectExport) {
    jQuery.feature(name, function (el, opt) {
        const load = loadModule();
        if (load && load.then) {
            load.then(run);
        } else {
            run(load);
        }
        function run(mod) {
            const method = selectExport(mod);
            method(el, opt);
        }
    });
}
