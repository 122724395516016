import React from "react";
import { SearchDialog } from "./SearchDialog";
import { FilterTool } from "../Filter";

export class ExportTool extends React.Component {
  filterTool;

  constructor(props, context, updater, filterTool = FilterTool) {
    super(props);
    this.filterTool = filterTool;
  }

  state = {
    organizations: [],
    filters: { districts: [], regions: [], types: [] },
    appliedFilters: { district: "", region: "", type: "", text: "" }
  };

  async componentDidMount() {
    const filters = await this.props.api.getFilters();
    const organizations = await this.props.api.getOrganizations();
    this.setState({ filters, organizations });
  }

  appliedFiltersWillChange = filter => {
    this.setState({ appliedFilters: { ...this.state.appliedFilters, ...filter } });
  };

  searchWillChange = event => {
    this.setState({ appliedFilters: { ...this.state.appliedFilters, text: event.target.value } });
  };

  get filteredOrganizations() {
    return this.filterTool.filteredOrganizations(this.state.organizations, this.state.appliedFilters);
  }

  render() {
    return (
      <div>
        <SearchDialog
          districts={this.state.filters.districts}
          regions={this.state.filters.regions}
          types={this.state.filters.types}
          text={this.state.appliedFilters.text}
          results={this.filteredOrganizations}
          filterWillChange={this.appliedFiltersWillChange}
          searchWillChange={this.searchWillChange}
          submitRoute={this.props.submitRoute}
          willClose={this.props.willClose}
        />
      </div>
    );
  }
}
