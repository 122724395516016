//================================================================//
// Settings
//================================================================//
/**
 * Chart settings object.  Follows the chartjs api documentation. 
 * See http://www.chartjs.org/docs for detailed information.
 * @param {*} maxPercent 
 */
const chartOptions = () => ({
    animationStartWithDataset: 1,
    animationStartWithData: 1,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    canvasBorders: false,
    legend: false,
    tooltips: {
        enabled: false
    },
    legendBorders: false,
    inGraphDataShow: false,
    spaceBetweenBar: 5,
    animationSteps: 90
});
//================================================================//
// Export
//================================================================//
export default chartOptions;