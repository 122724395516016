import moment from 'moment';
import Cookies from 'cookies-js';
import _ from 'lodash';

require('./datepicker-bootstrap/js/bootstrap-datepicker.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.sv.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.de.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.da.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.no.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.fi.js');
require('./datepicker-bootstrap/js/locales/bootstrap-datepicker.en-gb.js');

require('moment/locale/sv');
require('moment/locale/nn');
require('moment/locale/fi');
require('moment/locale/de');
require('moment/locale/da');
require('moment/locale/en-gb');


moment.updateLocale("fi", { week: { dow: 0 } });


var selectedLanguage = Cookies.get("cookieLanguage") || "sv-SE";
if (selectedLanguage === "nb-NO") {
    selectedLanguage = "nn";
}

if (selectedLanguage === "nn") {
    moment.locale(selectedLanguage.substring(0, 2));
    $.fn.datepicker.defaults.language = "no";
}
else if (selectedLanguage === "en-GB") {
    moment.locale(selectedLanguage.toLowerCase());
    $.fn.datepicker.defaults.language = selectedLanguage;
}
else
{
    moment.locale(selectedLanguage.substring(0, 2));
    $.fn.datepicker.defaults.language = selectedLanguage.substring(0, 2);
}

_.assignIn($.fn.datepicker.defaults, $.fn.datepicker.dates[$.fn.datepicker.defaults.language]);

// jQuery.ui overwrites $.fn.datepicker, so, alias to bootstrap_datepicker
$.fn.bootstrap_datepicker = $.fn.datepicker;