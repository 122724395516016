import request from 'superagent';
import { Events as TableEvents } from '../../features/table/selectable';
import _ from 'lodash';
import Sort from './sort';
import Animation from './animation';
import Filter from './filter';
import { Format } from '../../lib/string';

export function feature_js_recycle_module(module) {
    const DELETE_URL = '/mashie/RecycleBin/Delete/';
    const RESTORE_URL = '/mashie/RecycleBin/Restore/';
    const DropDown = $('#RecycleBinSelect');
    const Table = $('#RecycleBinTable');
    const Delete = $('#RecycleBinDelete');
    const Restore = $('#RecycleBinRestore');

    const buttonStateWillUpdate = state => {
        Delete.prop('disabled', state);
        Restore.prop('disabled', state);
    }
    const selectedRows = () => module.find('input.recycle-items__item:checkbox:checked');

    const updateRows = (rows, url) => {
        var dtoRows = [];
        rows.each((i, row) => dtoRows.push({
            Id: $(row).data('id'),
            Type: $(row).data('type')
        }));

        request
            .post(url)
            .send(dtoRows)
            .end((err, res) => {
                if (err) { return };
                if (rows.length > 20) {
                    rows.each((i, row) => $(row).closest('tr').remove());
                } else {
                    rows.each((i, row) => Animation.collapseAndRemoveRow(row));
                }
            });
    }

    const ConfirmOnDelete = () => window.confirm(
        Format(Mashie.Resources.GetString('RecycleConfirmDelete'), selectedRows().length)
    );

    const rowsWillUpdate = url => {
        if (url == DELETE_URL && ConfirmOnDelete() || url == RESTORE_URL) {
            updateRows(selectedRows(), url);
        }
    }

    Table.on(TableEvents.Selected, () => buttonStateWillUpdate(false));
    Table.on(TableEvents.Deselected, () => buttonStateWillUpdate(true));
    Delete.on('click', () => rowsWillUpdate(DELETE_URL));
    Restore.on('click', () => rowsWillUpdate(RESTORE_URL));
    Filter.initDropdownFilter(DropDown, $(Table).find('tbody tr'));
    Sort.initSortColumn(Table);
    buttonStateWillUpdate(true);
}