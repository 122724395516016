import React from "react";
import { Tabs } from "./Components/Tabs";
import { StocksAndCodesHighOrderComponent } from "./Components/StocksAndCodesHighOrderComponent";
import { InventoryHighOrderComponent } from "./Components/InventoryHighOrderComponent";
import { client } from "./Components/Client";

export const App = ({ url, auth, orgId }, context, updater, api = client(url, orgId)) => {
  return (
    <div>
      <Tabs>
        <div label="Lagerställen och inventeringskoder">{auth ? <StocksAndCodesHighOrderComponent api={api} /> : null}</div>
        <div label="Lager">{auth ? <InventoryHighOrderComponent api={api} /> : null}</div>
      </Tabs>
    </div>
  );
};
