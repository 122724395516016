"use strict";

jQuery.feature('js-busy-onclick', function (el) {

    el.click(function () {
        $('.spinnerImage').spin(true);

        setTimeout(function() {
            $('.spinnerImage').spin(false);
        }, 30000);
    });

    

});
