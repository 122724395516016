import React from 'react';
import PropTypes from 'prop-types';

export default class OrderLocksTable extends React.Component {

    static propTypes = {
        onDataChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.weekDays = [this.props.translations.sunday, this.props.translations.monday, this.props.translations.tuesday, this.props.translations.wednesday, this.props.translations.thursday, this.props.translations.friday, this.props.translations.saturday];

        this.state = {
            items: this.props.data,
            minTime: this.props.minTime || '00:00',
            maxTime: this.props.maxTime || '23:59'
        }
    }

    onChange = () => {
        this.props.onDataChange();
    };

    handleActiveChanged(i, event) {
        var items = this.state.items;

        if (typeof (this.props.onActiveChange) === 'function' && this.props.onActiveChange(items[i], event.target.checked) === false) return;

        items[i].active = event.target.checked;

        this.setState({
            items: items
        });
        this.onChange();
    }

    handleDaysChanged(i, event) {
        var items = this.state.items;
        items[i].days = event.target.value;

        this.setState({
            items: items
        });
        this.onChange();
    }

    handleTimeChanged(i, event) {
        var items = this.state.items;
        items[i].time = event.target.value;

        this.setState({
            items: items
        });

        this.onChange();
    }

    getNameOfTheWeek(i) {
        return this.weekDays[i];
    }

    getLastOrderType(o) {
        const days = parseInt(o.days);
        if (days == 0 && o.time != '23:59') {
            return this.props.translations.same_day + " " + this.props.translations.till + " " + o.time;
        }
        else if (days > 0) {
            const today = new Date();
            const todayDayofTheWeek = today.getDay();
            const diffDay = o.dayOfTheWeek - todayDayofTheWeek;
            const selectedDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + diffDay);
            const chosenDay = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate() - days);
            return this.weekDays[chosenDay.getDay()] + " " + this.props.translations.till + " " + o.time;
        }
        return this.props.translations.same_day;

    }


    renderRows() {
        const context = this;
        const isUnLocked = !this.props.locked;
        const hasActiveCheck = this.props.activeCheck;
        const minTime = this.state.minTime;
        const maxTime = this.state.maxTime;
        return this.state.items.map(function (o, i) {
            return (
                <tr key={"item-" + i}>
                    <td className="white lockTime">
                        {hasActiveCheck &&
                            <input type="checkbox" checked={o.active} disabled={!isUnLocked} onChange={context.handleActiveChanged.bind(context, i)} />
                        }
                    </td>
                    <td className="dayOfTheWeek" >
                        {context.getNameOfTheWeek([o.dayOfTheWeek])}
                    </td>

                    <td className="days">
                        {isUnLocked &&
                            <input width="10"
                                type="number"
                                value={o.days} min="0"
                                onChange={context.handleDaysChanged.bind(context, i)} required />
                        }
                        {!isUnLocked &&
                            <span>{o.days}</span>
                        }
                    </td>
                    <td className="time">

                        {isUnLocked &&
                            <input
                                type="time"
                                value={o.time} min={minTime} max={maxTime}
                                onChange={context.handleTimeChanged.bind(context, i)} required
                            />
                        }
                        {!isUnLocked &&
                            <span>{o.time}</span>
                        }
                    </td>
                    <td className="white lastOrderType">
                        {context.getLastOrderType(o)}
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th className="lockTime">
                                {this.props.translations.lock_time}
                            </th>
                            <th className="dayOfTheWeek">
                                {this.props.translations.weekday}
                            </th>
                            <th className="days">
                                {this.props.translations.days}
                            </th>
                            <th className="time">
                                {this.props.translations.time}
                            </th>
                            <th className="lastOrderType">
                                {this.props.translations.last_order_type}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                        <tr><td colSpan="5" className="filler white"></td></tr>
                    </tbody>
                </table>
            </div>
        );
    }
}