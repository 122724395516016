import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import DialogHeader from './DialogHeader';

export default class PopUp extends React.Component {
  constructor(props) {
    super(props);
  }
  hideDialog = () => {
    if (this.props.onHide) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={this.hideDialog}>
        <DialogHeader close={this.hideDialog}>
          {this.props.title}
        </DialogHeader>
        <DialogContent>{this.props.children}</DialogContent>
        <DialogActions />
      </Dialog>
    );
  }
}
