import React from "react";

export class ClimateInfo extends React.Component {
  render() {
    return (
      <div>
        <div>
          <label>
            <b>{Mashie.Resources.GetString("Item.Climate.Name")} :{" "}</b>
            {this.props.climateData.Name}
          </label>
        </div>
        <div>
          <label>
            <b>{Mashie.Resources.GetString("Item.Climate.Value")} :{" "}</b>
            {this.props.climateData.KgGreenHouseGasPerKg} kg CO<sub>2</sub>e/kg
          </label>
        </div>
        <div>
          <label>
            <b>{Mashie.Resources.GetString("Item.Climate.Region")} :{" "}</b>
            {(this.props.climateData.Region || {}).Name}  (
            {(this.props.climateData.Region || {}).InformationSource})
          </label>
        </div>
        <div>
          <label>
            <b>{Mashie.Resources.GetString("Item.Climate.Manufacturingprocess")} :{" "}</b>
            {(this.props.climateData.ProductionProcess || {}).Name} (
            {(this.props.climateData.ProductionProcess || {}).InformationSource})
          </label>
        </div>
        <div>
          <label>
            <b>{Mashie.Resources.GetString("Item.Climate.Activity")} :{" "}</b>
            {this.props.climateData.Activity}
          </label>
        </div>
      </div>
    );
  }
}
