"use strict";
import http from 'superagent';
import _ from 'lodash';
require('chart.js');

export function feature_js_charts_bar(el, url) {
	var canvas = $("<canvas>").appendTo(el), rdi;
	
	var rerender = _.debounce(function () {
		canvas.attr({ width: Math.max(50, el.width()), height: (rdi.labels.length * 40) });
		new Chart(canvas[0].getContext('2d')).HorizontalBar(rdi, {
            yAxisFontStyle: 'bold',
            yAxisFontSize: 44,
            yAxisUnitFontSize: 44,
            xAxisUnitFontSize: 44,
            xAxisFontSize: 44,
            inGraphDataShow: true,
            inGraphDataTmpl: "<%=v3%>%",
            inGraphDataXPosition: 3,
            inGraphDataYPosition: 2
        });
	}, 350);
	
	function render(data) {
		var labels = [];
        var datasets = [];
        var fillColor = [];
        var strokeColor = [];
        var amount = [];

        $.each(data, function (index, value) {
            labels.push(value.Name);
            fillColor.push(value.Color);
            strokeColor.push(value.Color);
            amount.push(value.Amount);
        });

        canvas.height = labels.length * 20;
        datasets.push({ "fillColor": fillColor.reverse(), "strokeColor": strokeColor.reverse(), "data": amount.reverse() });
        rdi = { "labels": labels.reverse(), "datasets": datasets, "title": "%" };
		rerender();
	};
	
	jQuery(window).on('resize', rerender);
	
	http.post(url)
		.end()
		.then(function(res) { render(res.body); });
}