import axios from "axios"
import { CustomerInvoiceResponse } from "./CustomerInvoice"

const BASE_URL = "/mashie/customerinvoiceaccount/"

export const fetchCustomerInvoice = async (customerId: string): Promise<CustomerInvoiceResponse> => {
    const url = `${BASE_URL}/${customerId}`;
    const response = await axios.get<CustomerInvoiceResponse>(url);
    return response.data;
}

