import request from 'superagent';

export function update(organizationId, itemId, climateData, onDone) {
    const stringJson = JSON.stringify(climateData);

    http_call({
        url: '/mashie/.mvc/climate/update',
        params: {
            organizationId: organizationId,
            itemId: itemId,
            value: climateData.KgGreenHouseGasPerKg,
            source: climateData.Source,
            json: stringJson
        },
        onDone: onDone
    });
}

export function get(organizationId, itemId, onSuccess, onError) {
    http_call({
        url: '/mashie/.mvc/climate/get',
        params: { organizationId: organizationId, itemId: itemId },
        onSuccess: onSuccess,
        onError: onError
    });
}

export function remove(organizationId, itemId, onDone) {
    http_call({
        url: '/mashie/.mvc/climate/delete',
        params: { organizationId: organizationId, itemId: itemId },
        onDone: onDone
    });
}

export function search(itemName, onSuccess, onError, onDone) {
    http_call({
        url: '/mashie/.mvc/climate/search',
        params: { slvName: itemName },
        onSuccess: onSuccess,
        onError: onError,
        onDone: onDone
    });
}

export function searchRecommendations(slvId, onSuccess, onError, onDone) {
    http_call({
        url: '/mashie/.mvc/climate/getrecommendations',
        params: { SLVID: slvId },
        onSuccess: onSuccess,
        onError: onError,
        onDone: onDone
    });
}

function http_call({ url, params, onSuccess, onError, onDone }) {
    var currentRequest = request
        .post(url)
        .send(params)
        .set('Accept', 'application/json');

    if (onError) {
        currentRequest = currentRequest.on('error', () => {
            onError();
            if (onDone) {
                onDone();
            }
        });
    }

    currentRequest.then(res => {
        try {
            if (onSuccess) {
                var data = JSON.parse(res.text);
                onSuccess(data);
            }
        } catch (e) {
            if (onError) {
                onError(e);
            }
        } finally {
            if (onDone) {
                onDone();
            }
        }
    });
}