import uuid from "uuid";

export const client = (url, orgId) => {
  const allOption = { name: Mashie.Resources.GetString("All"), id: "" };

  const getMapped = async (url, mapPredicate) => {
    const randomId = uuid();
    const result = await superagent.get(`${url}&${randomId}`);
    const body = result.body;
    const mapped = body.map(item => mapPredicate(item));
    return mapped;
  };

  return {
    getOrganizations: async () => {
      const route = `${url}/childorganizations?organizationId=${orgId}`;

      const mapped = await getMapped(route, org => ({
        name: org.Name,
        id: org.Id,
        district: org.DistrictId,
        region: org.RegionId,
        type: org.TypeId
      }));
      return mapped;
    },
    getFilters: async () => {
      const typeRoute = `${url}/organizationtypes?organizationId=${orgId}`;
      const regionRoute = `${url}/regions?organizationId=${orgId}`;
      const distrcitRoute = `${url}/districts?organizationId=${orgId}`;

      const mappedTypes = await getMapped(typeRoute, type => ({ name: type.Name, id: type.Id }));
      const mappedRegions = await getMapped(regionRoute, region => ({ name: region.Name, id: region.Id }));
      const mappedDistricts = await getMapped(distrcitRoute, district => ({ name: district.Name, id: district.Id }));

      const finalTypes = [allOption, ...mappedTypes];
      const finalRegions = [allOption, ...mappedRegions];
      const finalDistricts = [allOption, ...mappedDistricts];

      return { districts: finalDistricts, regions: finalRegions, types: finalTypes };
    },
    getStock: async () => {
      const stockRoute = `${url}/stock?organizationId=${orgId}`;
      const mappedStock = await getMapped(stockRoute, stock => ({ name: stock.Name, id: stock.Id }));

      return mappedStock;
    },

    exportInventory: async (stocks, orgs) => {
      const inventoryRoute = `${url}/inventory`;
      const response = await superagent
        .post(inventoryRoute)
        .set("Content-Type", "application/json")
        .send({ stockIds: stocks, destinationOrganizationsIds: orgs });
      return response;
    },
    exportStock: async orgs => {
      const stockRoute = `${url}/stock`;
      console.log(stockRoute);
      console.log(orgs);
      const response = await superagent
        .post(stockRoute)
        .set("Content-Type", "application/json")
        .send({ organizationIds: orgs });
      return response;
    },
    exportCodes: async orgs => {
      const codesRoute = `${url}/codes`;
      console.log(codesRoute);
      const response = await superagent
        .post(codesRoute)
        .set("Content-Type", "application/json")
        .send({ organizationIds: orgs });
      return response;
    }
  };
};
