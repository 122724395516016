import { useState } from "react";
import { Column, DietPreference, MealOption } from "../Domain/MealSettings";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import EditDietPreferencesDialog from "../DietPreferences";
import React from "react";
import ModeEditOutlineRounded from "@mui/icons-material/ModeEditOutlineRounded";
import { HeaderCheckBox } from "./HeaderCheckBox";
import { useMealOptionsStore } from "../stores/mealOptionsStore";
import { translate } from "../Translations/labelStrings";

interface Props {
    onShowHeaderClick: (checked: boolean) => void;
    showHeaderChecked: boolean;
    onToBeOrderedHeaderClick: (checked: boolean) => void;
    toBeOrderedHeaderChecked: boolean;
    onFoodFreeHeaderClick: (checked: boolean) => void;
    foodFreeHeaderChecked: boolean;
    isLocked: boolean;
}

const MealOptionsTable = ({
    onShowHeaderClick,
    showHeaderChecked,
    onToBeOrderedHeaderClick,
    toBeOrderedHeaderChecked,
    onFoodFreeHeaderClick,
    foodFreeHeaderChecked,
    isLocked,
}: Props) => {
    const [prefDialogOpen, setPrefDialogOpen] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState<MealOption>();

    const {
        mealOptions,
        modifyDietPreferences,
        modifyDietPreferencesSetAll,
        toggleShow,
        toggleToBeOrdered,
        toggleFoodFree,
    } = useMealOptionsStore();

    const handleEditClick = (option: MealOption) => {
        setSelectedMeal(option);
        setPrefDialogOpen(true);
    };

    const onAbortClick = () => {
        setPrefDialogOpen(false);
    };

    const onConfirm = (
        chosenOption: MealOption,
        dietPreferences: DietPreference[],
        setAll: boolean
    ) => {
        if (setAll) {
            modifyDietPreferencesSetAll(dietPreferences);
        } else {
            modifyDietPreferences(chosenOption, dietPreferences);
        }
        setPrefDialogOpen(false);
    };

    const columns: Column[] = [
        { id: "mealOption", label: translate("MealOption") },
        { id: "chosenPrefrenses", label: translate("dietary_requirements") },
        { id: "alternativeType", label: translate("Type") },
        {
            id: "show",
            label: translate("Show"),
            isCheckboxed: true,
            checked: showHeaderChecked,
            onChange: onShowHeaderClick,
        },
        {
            id: "toBeOrdered",
            label: translate("ToBeOrdred"),
            isCheckboxed: true,
            checked: toBeOrderedHeaderChecked,
            onChange: onToBeOrderedHeaderClick,
        },
        {
            id: "foodFree",
            label: translate("MenMP_MealFree"),
            isCheckboxed: true,
            checked: foodFreeHeaderChecked,
            onChange: onFoodFreeHeaderClick,
        },
    ];

    return (
        <Box mt={2}>
            {selectedMeal != null && prefDialogOpen && (
                <EditDietPreferencesDialog
                    open={prefDialogOpen}
                    mealOption={selectedMeal}
                    onAbort={() => onAbortClick()}
                    onConfirm={(meal, dietPreferences, setAll) =>
                        onConfirm(meal, dietPreferences, setAll)
                    }
                    isLocked={isLocked}
                />
            )}
            <TableContainer>
                <Table sx={{ minWidth: 200 }} size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, columnIndex) =>
                                column.isCheckboxed ? (
                                    <TableCell key={columnIndex}>
                                        <HeaderCheckBox
                                            column={column}
                                            isLocked={isLocked}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell key={columnIndex}>
                                        <Typography
                                            variant={"h6"}
                                            color="text.primary"
                                            fontWeight={"bold"}
                                        >
                                            {column.label}
                                        </Typography>
                                    </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mealOptions.map((option) => (
                            <TableRow key={option.mealOptionId}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ maxWidth: 100 }}
                                >
                                    <Typography
                                        variant={"h6"}
                                        color="text.primary"
                                    >
                                        {option.name}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ maxWidth: 200 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <Typography
                                                variant={"h6"}
                                                color="text.primary"
                                            >
                                                {option.dietPreferences
                                                    .sort((a, b) =>
                                                        a.name > b.name ? 1 : -1
                                                    )
                                                    .map(({ name }) => name)
                                                    .join(", ")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Tooltip
                                                arrow
                                                title={
                                                    <Typography
                                                        variant={"subtitle1"}
                                                    >
                                                        {translate(
                                                            "mc_editDietPreferences"
                                                        )}
                                                    </Typography>
                                                }
                                                placement="top"
                                            >
                                                <span>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleEditClick(
                                                                option
                                                            )
                                                        }
                                                        disabled={isLocked}
                                                    >
                                                        <ModeEditOutlineRounded />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant={"h6"}
                                        color="text.primary"
                                    >
                                        {option.dietPreferences.length > 0 ||
                                        option.foodFree
                                            ? translate("Special")
                                            : translate("Normal")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={!option.disabled}
                                        onChange={() => toggleShow(option)}
                                        disabled={isLocked}
                                        sx={{ paddingLeft: 0 }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={option.toBeOrdered}
                                        onChange={() =>
                                            toggleToBeOrdered(option)
                                        }
                                        disabled={isLocked}
                                        sx={{ paddingLeft: 0 }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={option.foodFree}
                                        onChange={() => toggleFoodFree(option)}
                                        disabled={isLocked}
                                        sx={{ paddingLeft: 0 }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default MealOptionsTable;
