import React from "react";
import { DietGroupedResponse, DietPreference } from "../Domain/MealSettings";
import DietPreferencesSingleSelect from "./DietPreferencesSingleSelect";
import { Box, Grid, Typography, styled } from "@mui/material";
import { Item } from "../DietPreferencesShared";
import { PrefCheckbox } from "./PrefCheckbox";

const ItemSingelStyle = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "left",
    color: theme.palette.text.secondary,
}));

export interface Props {
    dietGroup: DietGroupedResponse;
    selected: DietPreference[];
    onChange: (chosen: DietPreference[]) => void;
    isLocked: boolean;
}

export const DietPreferencesSelection = ({
    dietGroup,
    selected,
    isLocked,
    onChange,
}: Props) => {
    const handleChecked = (
        changePreference: DietPreference,
        checked: boolean
    ) => {
        if (checked) {
            onChange([...selected, changePreference]);
            return;
        }

        onChange(
            selected.filter(
                (preference) => preference.id !== changePreference.id
            )
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ paddingBottom: 1 }}>
                <Typography variant="h5" fontWeight={"bold"} paddingTop={4}>
                    {dietGroup.name}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {dietGroup.dietPreferences.map((pref) => (
                    <Grid item key={pref.id} xs={3}>
                        <Item>
                            <PrefCheckbox
                                key={pref.id}
                                dietPreference={pref}
                                chosenDietPreferences={selected}
                                handleChange={(value, checked) =>
                                    handleChecked(value, checked)
                                }
                                isLocked={isLocked}
                            />
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export const DietPreferencesSingleSelection = ({
    dietGroup,
    selected,
    isLocked,
    onChange,
}: Props) => {
    const handleSingleChange = (changePreference: DietPreference | null) => {
        if (changePreference != null) {
            onChange([changePreference]);
            return;
        }

        onChange([]);
    };

    return (
        <ItemSingelStyle>
            <DietPreferencesSingleSelect
                key={dietGroup.id}
                handleChange={handleSingleChange}
                title={dietGroup.name}
                selected={selected[0] ?? null}
                isLocked={isLocked}
                options={dietGroup.dietPreferences}
            />
        </ItemSingelStyle>
    );
};
