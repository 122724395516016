//================================================================//
// Imports
//================================================================//
import React, { Component } from "react";
import PropTypes from 'prop-types';
import Doughnut from "./EnergyDistributionDoughnutChart";
import Bar from "./EnergyDistributionBarChart";
/**
 * Wrapper class for the two energy charts. 
 */
class EnergyDistributionModule extends Component {
    //================================================================//
    // Class properties
    //================================================================//

    /**
     * The url to call the controller with to retrieve the chart data
     * If no data is coming through this url would be the first place
     * to start looking.
     */
    url = this.props.url;

    /**
     * Default state of the class.
     */
    state = {
        allChart: {},
        fatChart: {},
        allEnergies: [],
        fatEnergies: []
    };

    //================================================================//
    // Class Methods
    //================================================================//
    /**
     * Dont update the charts if there was an error.  Just return
     * @returns {} 
     */
    componentDidMount() {
        this.update().then(
            (response) =>
                this.parseData(JSON.parse(response)),
            (reject) => {
                console.log(reject);
                return;
            }
        )
    }



    /**
     * Build our data model and update the state with it.
     */
    parseData = ({ G1: allChart, G2: fatChart }) => {

        const { energy: { datasets: [{ data: allEnergies }] } } = allChart;
        const { energy: { datasets: [{ data: fatEnergies }] } } = fatChart;

        this.setState({
            allEnergies,
            fatEnergies,
            allChart,
            fatChart
        });
    }

    /**
     * Call and retireve our chart data from the NutrientController.
     */
    update = () => {
        return new Promise((resolve, reject) =>
            $.ajax({
                url: this.url,
                type: "POST"
            }).done(resolve).fail(reject)
        );
    }

    /**
     * Summarizes all fo the energy values passed in to the funciton.
     * @param {*} all Array containing numerical values.
     */
    getSumEnergies = (allEnergies) => allEnergies.reduce((sum, next) => sum + next, 0);

    /**
     * Gets the max value in percent form the passed in array and the deliminator with which 
     * to deduce the percentage. This is intended to caclulate the highest possible percentage
     * that the fat energy values contain.  
     * @param {*} all 
     * @param {*} energies 
     */
    getMaxPercent = (allEnergies, energies) => (Math.max(...energies) / this.getSumEnergies(allEnergies) * 100).toFixed(1);

    //================================================================//
    // Render Method
    //================================================================//
    /**
     * Render one chart for energies and one for fat. 
     * @returns {} 
     */
    render() {
        return (
            <div >
                <Doughnut allChart={this.state.allChart} sum={this.getSumEnergies(this.state.allEnergies)} />
                <Bar fatChart={this.state.fatChart}
                        sum={this.getSumEnergies(this.state.allEnergies)}
                        range={this.getMaxPercent(this.state.allEnergies, this.state.fatEnergies)} />
            </div>
        );
    }
}


//================================================================//
// Class Prop Types
//================================================================//
/**
 * We need to have this url to call the controller to get chart energies with.
 */
EnergyDistributionModule.propTypes = {
    url: PropTypes.string.isRequired
};
//================================================================//
// Export
//================================================================//
export default EnergyDistributionModule;