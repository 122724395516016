"use strict";

export function feature_js_stored_query_form(el, key) {
	sessionStorage[key] = el.serialize();	
	el.on('submit', function () {
		sessionStorage[key] = el.serialize();
	});
}

export function feature_js_stored_query(el, key) {
	var value = sessionStorage[key];
	if (!value) return;

	var href = el.attr('href') || '';
	el.attr('href', href + (href.indexOf('?') == -1 ? '?' : '&') + value);
}