"use strict";

export function feature_js_table_expand_row(el) {
    //init expand

    el.click(function () {
        var activity = $('[id*=\\.Activity]', el);
        var activityWidth = $('div:last', activity).width();
        activity.toggleClass('hiddentext');

        var completeActivity = $('[id*=\\.CompleteActivity]', el);
        completeActivity.toggleClass('hiddentext');
        var closeWidth = $('div:first', completeActivity).width();
        $('div:last', completeActivity).css('max-width', activityWidth - closeWidth);
    });
}