"use strict";
import React from "react";

export interface TabsProps {
  defaultActiveTabIndex: string | number;
  children?: any[];
}
export interface TabsState {
  activeTabIndex: any;
}

interface TabProps {
  isActive?: boolean;
  title: string;
  onClick?: () => void;
  children?: any;
}

export class Tabs extends React.Component<TabsProps, TabsState> {
  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      activeTabIndex: this.props.defaultActiveTabIndex,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tabIndex: any) {
    this.setState({
      activeTabIndex: tabIndex === this.state.activeTabIndex ? this.props.defaultActiveTabIndex : tabIndex,
    });
  }

  renderChildrenWithTabsApiAsProps() {
    return React.Children.map(this.props.children, (child: any, index) => {
      return React.cloneElement(child, {
        onClick: () => this.handleTabClick(index),
        tabIndex: index,
        isActive: index == this.state.activeTabIndex,
      });
    });
  }

  renderActiveTabContent() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children && children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  render() {
    return (
      <div className="ui-tabs ui-widget ui-widget-content ui-corner-all">
        <ul className="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" role="tablist">
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>
        <div className="tabs-active-content">{this.renderActiveTabContent()}</div>
      </div>
    );
  }
}

export const Tab = (props: TabProps) => {
  return (
    <li
      className={`ui-state-default ui-corner-top ${props.isActive ? "ui-tabs-active ui-state-active" : ""}`}
      role="tab"
    >
      <a
        className="ui-tabs-anchor"
        onClick={(event) => {
          event.preventDefault();
          if (props.onClick) props.onClick();
        }}
      >
        <span className="PageLabelClass">{props.title}</span>
      </a>
    </li>
  );
};
