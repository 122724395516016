"use strict";

import { CircularProgress, Fade } from "@mui/material";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import { PageState } from "../interfaces";

interface EmptyStateProps {
  headerText: string | undefined;
  message?: string | undefined;
  message2?: string | undefined;
  stateOfPage?: PageState;
}

export const AbnormalState = ({ headerText, message2, message, stateOfPage }: EmptyStateProps) => {
  const GetSymbol = () => {
    if (stateOfPage == "SUCCESS") {
      return (
        <DoneIcon
          style={{ width: "100px", height: "100px", color: "green" }}
          sx={{ svg: { circle: { strokeWidth: 1.6 } } }}
        />
      );
    }

    if (stateOfPage == "LOADING") {
      return (
        <CircularProgress style={{ width: "100px", height: "100px" }} sx={{ svg: { circle: { strokeWidth: 1.6 } } }} />
      );
    }

    if (stateOfPage == "ERROR") {
      return <ErrorIcon color="error" style={{ width: "90px", height: "90px" }} />;
    }

    if (stateOfPage == "NODATA") {
      return <ErrorIcon color="info" style={{ width: "90px", height: "90px" }} />;
    }
  };

  return (
    <Fade timeout={1000} in={stateOfPage != "OK"}>
      <div style={{ textAlign: "center", padding: "60px 70px" }}>
        <div style={{ fontSize: "1.3em", fontWeight: "bold", color: "#676767", marginBottom: "10px" }}>
          {headerText}
        </div>
        <div style={{ fontSize: "1.2em", color: "#676767", marginBottom: "20px" }}>{message}</div>
        <div style={{ fontSize: "1.2em", color: "#676767", marginBottom: "20px" }}>{message2}</div>

        {GetSymbol()}
      </div>
    </Fade>
  );
};
