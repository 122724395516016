import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MealOptionsTable from "./Components/MealOptionsTable";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { useMealOptionsStore } from "./stores/mealOptionsStore";
import { translate } from "./Translations/labelStrings";
import { AxiosError } from "axios";

interface Props {
    translations: Translations;
}

type Translations = {
    ClientId: string;
    CategoryId: string;
    IsLocked: boolean;
};

export const feature_js_client_diet_settings = (el: string) => {
    var $el = $(el);

    const translations: Translations = {
        ClientId: $el.attr("ClientId")!,
        CategoryId: $el.attr("CategoryId")!,
        IsLocked: !Boolean($el.attr("IsUnLocked")),
    };

    var element = document.getElementById("client-diet-settings");

    var element = document.getElementById("client-diet-settings");
    ReactDOM.render(
        <div>
            <ClientDietSettings translations={translations} />
        </div>,
        element
    );
};

export const ClientDietSettings = ({ translations }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [exceptionMessage, setException] = useState("");
    const [showHeaderChecked, setShowHeaderChecked] = useState(false);
    const [toBeOrderedHeaderChecked, setToBeOrderedHeaderChecked] =
        useState(false);
    const [foodFreeHeaderChecked, setFoodFreeHeaderChecked] = useState(false);
    const {
        setAllMealOptionShow,
        setAllMealOptionTobeOrdered,
        setAllMealOptionFoodFree,
        initializeState,
        mealOptions,
    } = useMealOptionsStore();
    useEffect(() => {
        const getMealOptions = async () => {
            try {
                await initializeState(
                    translations.ClientId,
                    translations.CategoryId
                );
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setHasError(true);
                setException((err as AxiosError).message);
                setIsLoading(false);
            }
        };

        setIsLoading(true);
        getMealOptions();
    }, []);

    useEffect(() => {
        setShowHeaderChecked(
            mealOptions.length > 0 &&
                mealOptions.every((option) => option.disabled === false)
        );
        setToBeOrderedHeaderChecked(
            mealOptions.length > 0 &&
                mealOptions.every((option) => option.toBeOrdered === true)
        );
        setFoodFreeHeaderChecked(
            mealOptions.length > 0 &&
                mealOptions.every((option) => option.foodFree === true)
        );
    }, [mealOptions]);

    const handleShowHeaderCheckboxChange = (checked: boolean) => {
        setAllMealOptionShow(checked);
        setShowHeaderChecked(checked);
    };

    const handleToBeOrderedHeaderCheckboxChange = (checked: boolean) => {
        setAllMealOptionTobeOrdered(checked);
        setToBeOrderedHeaderChecked(checked);
    };

    const handleFoodFreeHeaderCheckboxChange = (checked: boolean) => {
        setAllMealOptionFoodFree(checked);
        setFoodFreeHeaderChecked(checked);
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            ) : hasError ? (
                <Alert variant="outlined" severity="error">
                    <AlertTitle>
                        <Typography style={{ fontSize: 16 }}>
                            {translate("Server_Error")}
                        </Typography>
                    </AlertTitle>
                    <Typography style={{ fontSize: 16 }}>
                        {exceptionMessage}
                    </Typography>
                </Alert>
            ) : (
                <MealOptionsTable
                    onShowHeaderClick={handleShowHeaderCheckboxChange}
                    showHeaderChecked={showHeaderChecked}
                    onToBeOrderedHeaderClick={
                        handleToBeOrderedHeaderCheckboxChange
                    }
                    toBeOrderedHeaderChecked={toBeOrderedHeaderChecked}
                    onFoodFreeHeaderClick={handleFoodFreeHeaderCheckboxChange}
                    foodFreeHeaderChecked={foodFreeHeaderChecked}
                    isLocked={Boolean(translations.IsLocked)}
                />
            )}
        </>
    );
};
