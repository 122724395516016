"use strict";

export function feature_js_select_toggler(el, name) {
    var ctx = el.closest('form, body');

    var key = 'js-select-toggler-' + name + '-option';
    var contents = ctx.find('[js-select-toggler-' + name + '-contents]');

    el.on('change', function() {
        var active = ctx.find('[' + key + ']').filter(function () { return $(this).attr(key) == el.val(); });
        contents.html(active.html()).feature();
    }).change();
}