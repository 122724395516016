import PropTypes from "prop-types";
import React from "react";

export const SelectAllButton = ({ selectedLength, resultsLength, onClickSelect, onClickDeselect }) => {
  return selectedLength === resultsLength && resultsLength ? (
    <button type="buton" onClick={onClickDeselect}>
      Avmarkera alla
    </button>
  ) : (
    <button type="buton" disabled={!resultsLength} onClick={onClickSelect}>
      {Mashie.Resources.GetString("MarkAll")}
    </button>
  );
};

SelectAllButton.propTypes = {
  selectedLength: PropTypes.number,
  resultsLength: PropTypes.number,
  onClickSelect: PropTypes.func,
  onClickDeselect: PropTypes.func
};
