"use strict";

export function feature_js_store_field(el, key) {
    var load = el.attr('js-store-field--load') !== undefined, save = el.attr('js-store-field--save') !== undefined;
    if (!load && !save) load = save = true;

    var localStorageValue = window.localStorage[key];
    if (load && localStorageValue) el.val(localStorageValue);

    el.closest('form').on('submit', function () {
        if (save) window.localStorage[key] = el.val();
    });
}