import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "./Dropdown";
import { OrgSearchResultRow } from "./OrgSearchResultRow";
import { Search } from "./Search";
import { SelectAllButton } from "./SelectAllButton";

const Filters = {
  TYPE: { name: "type" },
  REGION: { name: "region" },
  DISTRICT: { name: "district" }
};

export class SearchDialog extends React.Component {
  state = {
    selectedOrgs: []
  };

  componentDidUpdate(prevProps) {
    if (prevProps.results.length !== this.props.results.length) {
      this.setState({
        selectedOrgs: this.state.selectedOrgs.filter(organization => this.props.results.filter(result => result.id === organization).length)
      });
    }
  }

  toggleOrganizationState = organizaiton => {
    if (this.state.selectedOrgs.includes(organizaiton)) {
      this.setState({ selectedOrgs: this.state.selectedOrgs.filter(item => item.id !== organizaiton.id) });
      return;
    }
    this.setState({ selectedOrgs: [...this.state.selectedOrgs, organizaiton] });
  };

  willSelectAll = event => {
    event.preventDefault();
    this.setState({ selectedOrgs: this.props.results });
  };

  willDeselectAll = event => {
    event.preventDefault();
    this.setState({ selectedOrgs: [] });
  };

  onSubmit = () => {
    this.props.submitRoute(this.state.selectedOrgs);
  };

  onClose = () => {
    this.props.willClose();
  };

  render() {
    return (
      <div>
        <Dropdown
          options={this.props.types}
          label={Mashie.Resources.GetString("OrganizationCentralAdmin_OrganizationType")}
          onChange={this.props.filterWillChange}
          type={Filters.TYPE}
        />
        <Dropdown
          options={this.props.regions}
          label={Mashie.Resources.GetString("OrgAP_Region")}
          onChange={this.props.filterWillChange}
          type={Filters.REGION}
        />
        <Dropdown
          options={this.props.districts}
          label={Mashie.Resources.GetString("OrgAP_District")}
          onChange={this.props.filterWillChange}
          type={Filters.DISTRICT}
        />
        <Search onChange={this.props.searchWillChange} text={this.props.text} />
        <SelectAllButton
          selectedLength={this.state.selectedOrgs.length}
          resultsLength={this.props.results.length}
          onClickSelect={this.willSelectAll}
          onClickDeselect={this.willDeselectAll}
        />
        <div className="inventory-export__results">
          <ul>
            {this.props.results.map(org => (
              <OrgSearchResultRow
                checked={this.state.selectedOrgs.includes(org)}
                org={org}
                onClick={this.toggleOrganizationState}
                key={org.id}
              />
            ))}
          </ul>
        </div>

        <div className="inventory-export__footer">
          <button type="button" className="btn btn-default" onClick={this.onSubmit}>
            {Mashie.Resources.GetString("btn_Select")}
          </button>
          <button type="button" className="btn btn-default" onClick={this.onClose}>
            {Mashie.Resources.GetString("Cancel")}
          </button>
        </div>
      </div>
    );
  }
}

SearchDialog.propTypes = {
  districts: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.arrayOf(PropTypes.object),
  regions: PropTypes.arrayOf(PropTypes.object),
  results: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string,
  filterWillChange: PropTypes.func,
  searchWillChange: PropTypes.func,
  submitRoute: PropTypes.func,
  willClose: PropTypes.func
};
