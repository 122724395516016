import { computed, observable, action } from "mobx";


class OrderAssortmentListStates  {
    @observable lockSettings = [];
    @observable assignedClientsByDay = [];

    @computed
    get activeDays() {
        return this.lockSettings.filter(s => s.active).map(s => s.dayOfTheWeek)
    }

    @computed
    get daysWithClients() {
        return this.assignedClientsByDay.filter(d => d.assignedClients.length > 0).map(d => d.dayOfWeek);
    }

    @action
    setLockSettings(settings) {
        this.lockSettings = settings;
    }

    @action
    setAssignedClientsByDay(clientsByDay) {
        this.assignedClientsByDay = clientsByDay;
    }
}

export default new OrderAssortmentListStates();