import React from "react";
import { Flex, Box } from "../../../../react-shared/flexbox";
import NotificationStore from "../../Store/notification-store";
import { observer } from "mobx-react";
import moment from "moment";
import { Grid, GridCell } from "../../../../react-shared/Grid/Grid";
import parse from "html-react-parser";
import format from "string-format";

interface NotificationProps {
    id: string;
    type: string;
    title: string;
    text: string;
    isRead: boolean;
    isCompleted: boolean;
    createdAt: string;
    createdBy: string;
    localizedTextValues: string[];
}


// @ts-ignore
@observer
export class Notification extends React.Component<NotificationProps> {
    constructor(props: NotificationProps) {
    super(props);
  }

  localize = (text: string) => {
    const localText = Mashie.Resources.GetString(text).includes(
      "Missing string"
    )
      ? text
      : format(
        Mashie.Resources.GetString(text),
        this.props.localizedTextValues
      );


      if (text === undefined){
        return "";
      }

      return format(localText);

    
  }

  render() {
    const textClasses = [
      "notification-text"
    ].join(" ");
    const notificationBoxClasses = [
      "notification-box"
    ].join(" ");

    var createdDate = moment
      .utc(this.props.createdAt)
      .local()
      .format("YYYY-MM-DD HH:mm");

    const title = this.localize(this.props.title);
    const text = this.localize(this.props.text);
    
    return (
      <div
        key={this.props.id}
        onClick={() => {
        
          !this.props.isRead
            ? NotificationStore.setAsRead(this.props.id)
            : null
            }
        }
      >
        <Grid
          className={notificationBoxClasses}
          columnTemplate="30px 70% 20%"
          rowTemplate="20px auto"
        >
          <GridCell
            className="readState"
            rowStart="1"
            rowEnd="1"
            columnStart="1"
            columnEnd="1"
            placeSelf="center"
          >
            {!this.props.isRead ? (
              <i className="fa fa-circle" aria-hidden="true"></i>
            ) : null}
          </GridCell>
          <GridCell
            className="note-date"
            rowStart="1"
            rowEnd="1"
            columnStart="2"
            columnEnd="2"
          >
            {createdDate} (
            {!this.props.createdBy ? "System" : this.props.createdBy})
          </GridCell>
          <GridCell
            className="doneState"
            rowStart="1" 
            rowEnd="1"
            columnStart="3"
            columnEnd="3"
          >
            {this.props.type === "ACTION" ? (
              <Flex alignItems="center">
                <Box style={{ height: "100%" }}>
                  {Mashie.Resources.GetString("Notification_IsCompleted")}
                </Box>
                <input
                  title={Mashie.Resources.GetString("Notification_IsCompleted_Shared_With_Organization")}
                  style={{ alignSelf: "normal" }}
                  onChange={() =>
                    NotificationStore.setDone(
                      this.props.id,
                      !this.props.isCompleted
                    )
                  }
                  type="checkbox"
                  checked={this.props.isCompleted}
                />
              </Flex>
            ) : null}
          </GridCell>
          <GridCell rowStart="2" rowEnd="2" columnStart="2" columnEnd="4">
            <div title={title} className="notification-title"> 
              {title}
            </div>
            <div className={textClasses}>{parse(text)}</div>
          </GridCell>
        </Grid>
        <div className="divider"></div>
      </div>
    );
  }
}
