import React from "react";
import PropTypes from "prop-types";

export const OrgSearchResultRow = ({ onClick, org, checked }) => {
  const onCheckBoxClick = () => {
    onClick(org);
  };

  return (
    <li onClick={onCheckBoxClick}>
      <input type="checkbox" checked={checked} />
      <span>{org.name}</span>
    </li>
  );
};

OrgSearchResultRow.propTypes = {
  onClick: PropTypes.func,
  org: PropTypes.object,
  checked: PropTypes.bool
};
