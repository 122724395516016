import React from "react";

export const Tab = ({ label, onClick, isActive }) => {
  const onTabClick = ev => {
    onClick(label);
  };

  const active = isActive ? " active" : "";

  return (
    <li className={`tab__content--item${active}`} onClick={onTabClick}>
      <div>{label}</div>
    </li>
  );
};
