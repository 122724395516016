
export function feature_js_priceweightcontrol(page) {
    const lossprecentElement = page.find('input[id$=txtWaterLiquidRatio]');

    const animationspeed = 3000;
    const noUpdateColor = "#F7C96B";
    const updateColor = "#95b417";
    var weightFactor = $(page.find('input[id$=RawWeightUnitFactor]')).val();

    function updatePercent() {
        
        const isVolume = page.find('select[id$=ddlServUnit] option:selected').attr('unittype') === 'VOLYM';
        const isWeight = page.find('select[id$=ddlServUnit] option:selected').attr('unittype') === 'VIKT';
        var servunitFactor = page.find('select[id$=ddlServUnit] option:selected').attr('unitfactor');

        const servingportion = $(page.find('input[id$=txtServPortion]')).val();
        const rawWeightInput = page.find('input[id$=TextBox1]');

        const rawWeight = rawWeightInput.val();
        var persentAfterLoss;
       

        if (isVolume) {
            //const volmecolor = noUpdateColor; //orange
            //animateBgColorFadeToWhite(lossprecentElement, volmecolor, animationspeed);
        }
        if (!isWeight) { //antal
            //var weightColor = noUpdateColor; //orange
            //animateBgColorFadeToWhite(lossprecentElement, weightColor, animationspeed);
            //persentAfterLoss = Math.round(servingportion.replace(',', '.') * 100,0);
        } else {
            persentAfterLoss = Math.round((100 * servingportion.replace(',', '.')) * servunitFactor.replace(',', '.') / (rawWeight.replace(',', '.') * weightFactor), 0);
            var initialBgColor = updateColor;
            animateBgColorFadeToWhite(lossprecentElement, initialBgColor, animationspeed);
        }

        $(lossprecentElement).attr('value', persentAfterLoss);
    }


    updatePercent();
    page.find('input[id$=txtServPortion]').on('input', function (event) {

        updatePercent();

    });

    lossprecentElement.on('input', function (event) {
        const isVolume = page.find('select[id$=ddlServUnit] option:selected').attr('unittype') === 'VOLYM';
        const isWeight = page.find('select[id$=ddlServUnit] option:selected').attr('unittype') === 'VIKT';
        var servunitFactor = page.find('select[id$=ddlServUnit] option:selected').attr('unitfactor');


        const rawWeightInput = page.find('input[id$=TextBox1]');
        const lossprecentElement = page.find('input[id$=txtWaterLiquidRatio]');
        const rawWeight = rawWeightInput.val();
        const percent = lossprecentElement.val();
        const servingPortionInput = page.find('input[id$=txtServPortion]');
        var servingportion;


        if (isVolume) {
            //const volmecolor = noUpdateColor; //orange
            //animateBgColorFadeToWhite(servingPortionInput, volmecolor, animationspeed);
        }
        if (!isWeight) { //antal
            //var weightColor = noUpdateColor; //orange
            //animateBgColorFadeToWhite(servingPortionInput, weightColor, animationspeed);
            //persentAfterLoss = Math.round(servingportion.replace(',', '.') * 100,0);
        } else {

            servingportion = (((rawWeight.replace(',', '.') * weightFactor) * (percent.replace(',', '.') / 100)) / servunitFactor.replace(',', '.')).toFixed(1);
            var initialBgColor = updateColor;
            animateBgColorFadeToWhite(servingPortionInput, initialBgColor, animationspeed);
        }

        servingPortionInput.attr('value', servingportion);





    });


    function animateBgColorFadeToWhite(element, initialBgColor, speed) {
        const isAnimating = $(element).is(':animated');

        if (!isAnimating) {
            $(element).animate({ 'background-color': initialBgColor }, 1, function () {
                $(element).animate({ 'background-color': 'white', borderWidth: "1px" }, speed);
            });
        }
    }
}