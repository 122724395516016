import React from "react";
import ReactDOM from "react-dom";
import PopUp from "../shared/react/Modal";
import request from "superagent";
import PropTypes from "prop-types";

export default class OrderListPriceQuestionPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderlistid: this.props.orderlistId,
      activeColumn: this.props.activeColumn,
      itemgroupid: this.props.itemgroupid,
      allGroups: false,
      onlyMissing: true,
      currentItemGroup: true,
      isOpen: true
    };
  }

  removeNode = () => {
    this.setState({ isOpen: false });
    $("#modal").remove();
  };

  changeSelection = e => {
    switch (e.target.name) {
      case "itemGroup":
        this.setState({
          allGroups: this.checkAll.checked,
          currentItemGroup: this.checkGroupOnly.checked,
          itemgroupid: this.checkAll.checked ? null : this.props.itemgroupid
        });
        break;
      case "onlyMissing":
        this.setState({
          onlyMissing: this.onlyMissingCheckbox.checked
        });
        break;
    }
  };

  applyProffitOptions() {
    let orderlistid = this.state.orderlistid;
    let value = this.props.value;

    var options = {
      OnlyZero: this.state.onlyMissing,
      ActiveColumn: this.state.activeColumn,
      itemGroupId: this.state.itemgroupid
    };

    request
      .post("/Mashie/OrderList/Profit/Add/" + orderlistid + "?value=" + value)
      .send(options)
      .end(function() {
        //ladda om sidan.
        window.location.href =
          "/mashie/WebForms/OrderSelectionAdminPage.aspx" +
          "?orderlistId=" +
          orderlistid +
          "&tabindex=1";
      });
  }

  render() {
    return (
      <PopUp
        onHide={this.removeNode}
        isOpen={this.state.isOpen}
        title={Mashie.Resources.GetString("Surcharge_popup_UseForMany")}
      >
        <div className="row">
          <div className="col-xs-12">
            <h2>
              {Mashie.Resources.GetString(
                "Surcharge_popup_UseSurchargeForManyRowsInList"
              )}:
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">
            <label>
              <input
                checked={this.state.allGroups}
                onChange={this.changeSelection.bind(this)}
                ref={chkAll => {
                  this.checkAll = chkAll;
                }}
                name="itemGroup"
                type="radio"
              />
              {Mashie.Resources.GetString("Surcharge_popup_WholeList")}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">
            <label>
              <input
                checked={this.state.currentItemGroup}
                onChange={this.changeSelection.bind(this)}
                ref={chkGroup => {
                  this.checkGroupOnly = chkGroup;
                }}
                name="itemGroup"
                type="radio"
              />
              {Mashie.Resources.GetString("Surcharge_popup_CurrentGroup")}
            </label>
          </div>
          <div className="col-xs-9">
            <label>
              <input
                name="onlyMissing"
                checked={this.state.onlyMissing}
                onChange={this.changeSelection.bind(this)}
                ref={chkOnlyMissing => {
                  this.onlyMissingCheckbox = chkOnlyMissing;
                }}
                type="checkbox"
              />
              {Mashie.Resources.GetString(
                "Surcharge_popup_UseOnlyOnRowsWithoutSurcharge"
              )}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <button
              className="mashiebutton1"
              onClick={this.applyProffitOptions.bind(this)}
            >
              {Mashie.Resources.GetString("Yes")}
            </button>
            <button className="mashiebutton1" onClick={this.removeNode}>
              {Mashie.Resources.GetString("No")}
            </button>
          </div>
        </div>
      </PopUp>
    );
  }
}

OrderListPriceQuestionPopup.propTypes = {
  activeColumn: PropTypes.string.isRequired,
  orderlistId: PropTypes.string.isRequired,
  itemgroupid: PropTypes.string.isRequired
};

jQuery.feature("js-orderlist-price-question", function(scope) {
  scope.on("change", el => {
    var orderlistId = el.target.dataset.orderlistid;
    var activeColumn = el.target.dataset.activecolumn;
    var itemgroupid = el.target.dataset.itemgroupid;
    var value = el.target.value;

    value = value.replace(",", ".");

    $("body").append('<div id="modal"></div>');
    var root = $("#modal")[0];
    ReactDOM.render(
      <OrderListPriceQuestionPopup
        activeColumn={activeColumn}
        value={value}
        orderlistId={orderlistId}
        itemgroupid={itemgroupid}
      />,
      root
    );
  });
});
