import { DietPreference, MealOption } from "./MealSettings";

const update = (mealOptions: MealOption[], change: MealOption) => {
    return mealOptions.map((option) =>
        change.mealOptionId === option.mealOptionId ? change : option
    );
};
const updateAll = (
    mealOptions: MealOption[],
    preferences: DietPreference[]
) => {
    return mealOptions.map((option) => ({
        ...option,
        dietPreferences: preferences,
    }));
};
const updateDisabled = (mealOption: MealOption, disabled: boolean) => {
    return {
        ...mealOption,
        disabled: disabled,
        toBeOrdered: disabled ? false : mealOption.toBeOrdered,
    };
};
const updateToBeOrdered = (mealOption: MealOption, toBeOrdered: boolean) => {
    return {
        ...mealOption,
        toBeOrdered: toBeOrdered,
        disabled: toBeOrdered ? false : mealOption.disabled,
    };
};
const updateFoodFree = (mealOption: MealOption, foodFree: boolean) => {
    return { ...mealOption, foodFree: foodFree };
};
const updateDietPreferences = (
    mealOption: MealOption,
    dietPreferences: DietPreference[]
) => {
    return { ...mealOption, dietPreferences: dietPreferences };
};

export const MealOptionUtils = {
    update,
    updateAll,
    updateDisabled,
    updateToBeOrdered,
    updateFoodFree,
    updateDietPreferences,
};
