"use strict";

import _ from 'lodash';

export function feature_js_pie_chart(el, cfg) {
    var cvs = $('<canvas>').appendTo(el), firstView = true;

    $(window).on('resize', _.debounce(resize, 500));
    el.on('resize', _.debounce(resize, 500));
    resize();

    function createLegend(data) {
        var ul = $('<ul />');
        var container = $('<div id="all-legend" class="chart-legend" />');

        _.each(data, function (d) {
            var legend = $(`<li>
                    <div class="legend-color" style="background-color:` + d.color + `"></div>
                    <div class="legend-description">
                        <div>` + d.title + `</div>
                    </div>
                </li>`);
            ul.append(legend);
        });

        container.append(ul);

        return container;
    }

    function resize() {
        cvs.attr('width', el.width()).attr('height', el.height());
        var data = JSON.parse(cfg || '[]');
        _.each(data, function(d) { _.defaults(d, { color: 'black' }); });
        var opts = _.defaults(JSON.parse(el.attr('js-pie-chart--opts') || '{}'), {
            bezierCurve: false,
            //inGraphDataShow: true,
            //inGraphDataTmpl: "<%=v1+' '+v2+''%>%",
            animationSteps: firstView ? 80 : 0,
            //inGraphDataFontColor: "black",
            //inGraphDataFontSize: 14
            legend: true,
            legendFontFamily: "'Verdana'",
            //legendFontSize: 12,
            //legendFontStyle: "normal",
            //legendFontColor: "#666",
            //legendBlockSize: 15,
            legendBorders: false
            //legendBordersWidth: 1,
            //legendBordersColors: "#666"
        });

        firstView = false;

        var pieData =
            {
                labels: data.map(function (d) { return d.title }),
                datasets: [{
                    backgroundColor: data.map(function (d) { return d.color }),
                    data: data.map(function (d) { return d.value }),
                    borderWidth: 1,
                }]
            };

        var pieOptions = {
            animationStartWithDataset: 1,
            animationStartWithData: 1,
            animationEasing: "easeOutBounce",
            animateRotate: true,
            canvasBorders: false,
            legend: false,
            legendBorders: false,
            inGraphDataShow: false,
            spaceBetweenBar: 5,
            animationSteps: 90

        };

        var myPieChart = new Chart(cvs[0].getContext('2d'), {
            type: 'doughnut',
            data: pieData,
            options: pieOptions
        });
        var container = cvs[0].parentElement.parentElement;
        $(container).append(createLegend(data));

            


        //new global.Chart(cvs[0].getContext('2d')).Pie(data, opts);
    }
}