import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
    CustomerInvoiceAccount,
    CustomerInvoiceResponse,
} from "../Domain/CustomerInvoice";
import { fetchCustomerInvoice } from "../Domain/customerInvoiceApi";
import { setValueToInput, setCustomerInvoiceChanged, sortCustomerInvoice } from "../Domain/customerInvoiceRequestUtil";
type CustomerInvoiceActions = {
    initializeState: (customerId: string) => Promise<void>;
    addInvoice: (customerInvoiceAccount: CustomerInvoiceAccount) => void;
    checkAvailable: (accountType: string, transactionType: string) => boolean;
    removeInvoice: (accountType: string, transactionType: string) => void;
    getInvoiceById: (
        accountType: string,
        transactionType: string
    ) => CustomerInvoiceAccount | undefined;
    updateInvoice: (customerInvoice: CustomerInvoiceAccount) => void;
};

export const useCustomerInvoiceStore = create<
    CustomerInvoiceResponse & CustomerInvoiceActions
>()(
    persist(
        (set, get) => ({
            customerInvoiceAccounts: [],
            customerInvoiceAccountPartHeaders: [],
            initializeState: async (customerId: string) => {
                const stateCustomerId = get().customerInvoiceAccounts?.[0]?.customerId ?? '';
                if (stateCustomerId === customerId) {
                    setValueToInput(get().customerInvoiceAccounts);
                    return;
                }

                const customerInvoiceResponse = await fetchCustomerInvoice(customerId);
                const customerInvoiceAccounts = customerInvoiceResponse.customerInvoiceAccounts.map((item) => {
                    return {
                        ...item,
                        id: item.transactionType + "-" + item.accountType,
                    };
                });

                setValueToInput(customerInvoiceAccounts);

                set(() => {
                    return {
                        customerInvoiceAccounts: sortCustomerInvoice(customerInvoiceAccounts),
                        customerInvoiceAccountPartHeaders:
                            customerInvoiceResponse.customerInvoiceAccountPartHeaders,
                    };
                });
            },
            addInvoice: (customerInvoiceAccount: CustomerInvoiceAccount) => {
                set((state) => {
                    const currentCustomerInvoiceAccounts = state.customerInvoiceAccounts;
                    currentCustomerInvoiceAccounts.push(customerInvoiceAccount);
                    setValueToInput(currentCustomerInvoiceAccounts);
                    setCustomerInvoiceChanged(true);
                    return {
                        customerInvoiceAccounts: sortCustomerInvoice(currentCustomerInvoiceAccounts),
                    };
                });
            },
            removeInvoice: (accountType: string, transactionType: string) => {
                set((state) => {
                    const currentCustomerInvoiceAccounts =
                        state.customerInvoiceAccounts.filter(
                            (item) =>
                                !(
                                    item.accountType === accountType &&
                                    item.transactionType === transactionType
                                )
                        );
                    setValueToInput(currentCustomerInvoiceAccounts);
                    setCustomerInvoiceChanged(true);
                    return {
                        customerInvoiceAccounts: currentCustomerInvoiceAccounts,
                    };
                });
            },
            getInvoiceById: (accountType: string, transactionType: string) => {
                return get().customerInvoiceAccounts.find(
                    (x) =>
                        x.accountType === accountType &&
                        x.transactionType === transactionType
                );
            },
            checkAvailable: (accountType: string, transactionType: string) => {
                const availableAccountType = get().customerInvoiceAccounts.find(
                    (x) =>
                        x.accountType === accountType &&
                        x.transactionType === transactionType
                );
                if (availableAccountType) return false;
                return true;
            },
            updateInvoice: (customerInvoice: CustomerInvoiceAccount) => {
                set((state) => {
                    const newUpdateCustomerInvoiceAccounts =
                        state.customerInvoiceAccounts.map((obj) =>
                            obj.accountType === customerInvoice.accountType &&
                                obj.transactionType === customerInvoice.transactionType
                                ? customerInvoice
                                : obj
                        );
                    setValueToInput(newUpdateCustomerInvoiceAccounts);
                    setCustomerInvoiceChanged(true);
                    return {
                        customerInvoiceAccounts: sortCustomerInvoice(newUpdateCustomerInvoiceAccounts),
                    };
                });
            },
        }),
        {
            name: "customerInvoices",
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);
