"use strict";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";

//Enter at your own risk.  Hastily written react app for a MRE (Minimum required effort) legacy rewrite to support multi select functionality.

export async function feature_js_inventory(el, data) {
  const parsed = JSON.parse(data);
  ReactDOM.render(<App url={parsed.url} auth={parsed.auth} orgId={parsed.org} />, el[0]);
}
