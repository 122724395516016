"use strict";

import React from 'react';
import ReactDOM from 'react-dom';
import { get } from './components/ReportsApiClient';

export function feature_js_reports(el) {
    var $el = $(el);

    const translations = {
        created: $el.attr("tr-created"),
        name: $el.attr("tr-name"),
        fileType: $el.attr("tr-file-type"),
        download: $el.attr("tr-download"),
        noFiles: $el.attr("tr-no-files")
    };

    const type = $el.attr("type");

    var element = document.getElementById('download-reports-scope');
    ReactDOM.render(<Reports translations={translations} type={type} />, element);
}

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: []
        };
    }

    componentDidMount() {
        this.getSelectedValue();
    }

    getSelectedValue = () => {
        get(
            (data) => {
                var reports = data.Reports
                    .sort((r1, r2) => new Date(r2.Created) - new Date(r1.Created))
                    .map(r => {
                        return {
                            ...r,
                            Created: new Date(r.Created).toLocaleString()
                        }
                    });

                this.setState({
                    reports: reports
                });
            },
            () => {
                this.setState({
                    reports: []
                });
            }
            , this.props.type);
    };

    getTableContent = () => {
        return this.state.reports.map(r => {
            return (
                <Row key={r.Id} report={r} translations={this.props.translations} />
            )
        });
    }


    getEmptyTableContent = () => {
        return (
            <tr>
                <td className="text-center" colSpan="4">{this.props.translations.noFiles}</td>
            </tr>
        );
    }

    render() {
        return (
            <div>
                <table className="table table-bordered table-striped separate">
                    <thead>
                        <tr>
                            <th>{this.props.translations.created}</th>
                            <th>{this.props.translations.name}</th>
                            <th>{this.props.translations.fileType}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.reports.length > 0 ? this.getTableContent() : this.getEmptyTableContent()}
                    </tbody>
                </table>
            </div>
        );
    }
}

class Row extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const report = this.props.report;
        const url = `/mashie/report/download?reportId=${report.Id}&fileName=${report.FileName}&mimeType=${report.MimeType}`;


        return (
            <tr>
                <td>{report.Created}</td>
                <td><a href={url}>{report.FileName}</a></td>
                <td>{report.UserFriendlyType}</td>
            </tr>
        );
    }
}