"use strict";

import { times } from "lodash";
import { observer } from "mobx-react";
import React from "react";

@observer
export class Drawer extends React.Component {
  constructor(props) {
    super(props);
  }

  closeIfClickOutside = (e) => {
    var wrapper = document.getElementById('drawerWrapper');
    const inside = wrapper.contains(e.target);
    if(!inside){
      if (this.props.onClickOutside){
        this.props.onClickOutside();
      }      
      document.removeEventListener('mousedown', this.closeIfClickOutside)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if(this.props.isOpen && !prevProps.isOpen ){
      document.addEventListener('mousedown', this.closeIfClickOutside)
    }
  }


  render() {

    

    var openStyle = this.props.isOpen
      ? {
          right: "0",
        }
      : { right: "-410px" };

    return (
      <nav id="drawerWrapper" style={openStyle} className="mashie-drawer">
        {this.props.children}
      </nav>
    );
  } 
}
