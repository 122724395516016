"use strict";
import http from 'superagent';

export function feature_js_view_labeling_queue_list(el) {
    el.on('click', '[js-mark-as-rendered]', function() {
        $(this).closest('tr').find('[js-render-marker]').removeClass('fa-circle-o');
    });
    el.on('click', '[js-unmark]', function() {
        http.post($(this).attr('js-unmark')).end();
        $(this).closest('tr').find('[js-render-marker]').addClass('fa-circle-o');
    });
}