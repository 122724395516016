import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
// import SaveIcon from "@mui/icons-material/Save";
// import CloseIcon from "@mui/icons-material/Close";

interface ModalProps {
  width?: string;
  height?: string;
  headerText: string;
  isOpen: boolean;
  children?: any;
  handleClose: () => void;
  handleSave?: () => Promise<void>;
  minWidth: string;
}

interface ModalState {
  isSaving: boolean;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper style={{ overflow: "unset", padding: "10px", width: "auto", maxWidth: "unset" }} {...props} />
    </Draggable>
  );
}

export class Modal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = { isSaving: false };
  }

  handleSave = async () => {
    this.setState({ isSaving: true });
    if (this.props.handleSave) {
      await this.props.handleSave();
      this.setState({ isSaving: false });
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <strong>{this.props.headerText}</strong>
        </DialogTitle>
        <DialogContent
          style={{
            overflow: "unset",
            minWidth: this.props.minWidth,
          }}
        >
          {this.props.children}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="medium"
            color="primary"
            loadingPosition="start"
            // startIcon={<CloseIcon />}
            variant="text"
            onClick={this.props.handleClose}
          >
            {Mashie.Resources.GetString("Abort_Button")}
          </LoadingButton>
          <LoadingButton
            size="medium"
            color="primary"
            loading={this.state.isSaving}
            loadingPosition="center"
            startIcon={null}
            variant="contained"
            onClick={this.handleSave}
          >
            {Mashie.Resources.GetString("Save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
