"use strict";

export function feature_js_table_select_all(el) {
    el.find("> thead").find('[js-table--select-all--header]').click(function () {
        var checked = $(this).prop('checked');
        selectAll(checked);
    });

    function selectAll(checked) {
        el.find("> tbody").find('[js-table--select-all--row]').each(function (index, obj) {
            if (checked) {
                var parentRow = $(obj).closest("tr");
                if ($(parentRow).hasClass('hidden') === false) {
                    $(obj).prop('checked', true);
                }
            }
            else {
                $(obj).prop('checked', false);
            }
        });
    }
}