import React from "react";
import NotificationAdminStore from "./NotificationAdminStore";
import { styled, alpha } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import { Button, Checkbox, Paper, Skeleton, Stack, TableContainer, TableHead, TableRow, FormControl, InputLabel, Select, MenuItem, InputBase, SelectChangeEvent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import { observer } from "mobx-react";

@observer
export class NotificationUserOverView extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    async componentDidMount() {
        await NotificationAdminStore.loadPage();
    }

    render() {
        const headerCellCss = {
            fontSize: "1.2rem",
            fontWeight: "bold",
        } as React.CSSProperties;

        const bodyCellCss = {
            fontSize: "1.2rem",
        } as React.CSSProperties;

        const filterTableCss = {
            paddingBottom: "20px",
        } as React.CSSProperties;

        const filterTableSearchCss = {
            paddingBottom: "20px",
            width: "300px"
        } as React.CSSProperties;


        const Search = styled('div')(({ theme }) => ({
            position: 'relative',
            borderRadius: '4px',
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            border: '1px solid rgba(224, 224, 224, 1)',
            lineHeight: '1.5rem',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        }));

        const SearchIconWrapper = styled('div')(({ theme }) => ({
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }));

        const StyledInputBase = styled(InputBase)(({ theme }) => ({
            color: 'inherit',
            width: '100%',
            '& .MuiInputBase-input': {
                padding: theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                transition: theme.transitions.create('width'),
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    '&:focus': {
                        width: '100%',
                    },
                },
            },
        }));

        const setRoleFilter = (event: SelectChangeEvent): void => {
            const selectedValue = event.target.value as string;
            NotificationAdminStore.setRoleFilter(selectedValue);
        }

        const setHasNotificationsFilter = (event: SelectChangeEvent): void => {
            const selectedValue = event.target.value as string;
            NotificationAdminStore.setHasNotificationFilter(selectedValue);
        }

        const setCustomerCategory = (event: SelectChangeEvent): void => {
            const selectedValue = event.target.value as string;
            NotificationAdminStore.setCustomerCategoriesFilter(selectedValue);
        }

        const setSearchTerm = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
            const selectedValue = event.target.value as string;
            NotificationAdminStore.setSearchTermFilter(selectedValue);
        }

        const users = NotificationAdminStore.getUsers();

        return (
            <div>
                <Table size="small" >
                    
                    <TableRow>
                        <TableCell style={filterTableCss}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-role-label">{Mashie.Resources.GetString(`Role`)}</InputLabel>
                                    <Select
                                        labelId="select-role-label"
                                        id="select-role"
                                        label="Role" onChange={setRoleFilter}
                                        value={NotificationAdminStore.rolesFilter}
                                    >
                                        <MenuItem value="">{Mashie.Resources.GetString("All")}</MenuItem>
                                        {
                                            NotificationAdminStore.getRoles().map((element) => {
                                                return <MenuItem key={element} value={element}>{element}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </TableCell>
                        <TableCell style={filterTableCss}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-hasnotification-label">{Mashie.Resources.GetString(`HasNotifications`)}</InputLabel>
                                    <Select
                                        labelId="dselect-hasnotification-label"
                                        id="select-hasnotification"
                                        label="HasNotifications"
                                        onChange={setHasNotificationsFilter}
                                        value={NotificationAdminStore.hasNotificationsFilter}
                                    >
                                        <MenuItem value="">{Mashie.Resources.GetString("All")}</MenuItem>
                                        <MenuItem value="true">{Mashie.Resources.GetString("Yes")}</MenuItem>
                                        <MenuItem value="false">{Mashie.Resources.GetString("No")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        <TableCell style={filterTableCss}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-customercategory-label">{Mashie.Resources.GetString(`CustomerCategory`)}</InputLabel>
                                    <Select
                                        labelId="select-customercategory-label"
                                        id="select-customercategory"
                                        label="CustomerCategory"
                                        onChange={setCustomerCategory}
                                        value={NotificationAdminStore.customerCategoriesFilter}
                                    >
                                        <MenuItem value="">{Mashie.Resources.GetString("All")}</MenuItem>
                                        {
                                            NotificationAdminStore.getCustomerCategories().map((element) => {
                                                return <MenuItem key={element} value={element}>{element}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </TableCell>
                        <TableCell></TableCell>
                        <TableCell style={filterTableSearchCss}>
                                <FormControl fullWidth>
                                    <Search key="search">
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase key="searchInput"
                                            placeholder={Mashie.Resources.GetString(`Search`)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            value={NotificationAdminStore.searchTermFilter}
                                            onChange={setSearchTerm}
                                            autoFocus={true}
                                        />
                                    </Search>
                                </FormControl>

                            </TableCell>
                        </TableRow>
                </Table>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellCss}>{Mashie.Resources.GetString(`Username`)}</TableCell>
                                <TableCell style={headerCellCss} align="left">
                                    {Mashie.Resources.GetString(`Role`)}
                                </TableCell>
                                <TableCell style={headerCellCss} align="center">
                                    {Mashie.Resources.GetString(`HasNotifications`)}
                                </TableCell>
                                <TableCell style={headerCellCss} align="left">
                                    {Mashie.Resources.GetString(`Area`)}
                                </TableCell>
                                <TableCell style={headerCellCss} align="left">
                                    {Mashie.Resources.GetString(`Scope`)}
                                </TableCell>
                                <TableCell style={headerCellCss} align="left">
                                    {Mashie.Resources.GetString(`Entitlement`)}
                                </TableCell>
                                <TableCell style={headerCellCss} align="left">
                                    {Mashie.Resources.GetString(`CustomerCategory`)}
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                NotificationAdminStore.IsLoadingUserList ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Stack spacing={1}>
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                                <Skeleton variant="rectangular" width="auto" height={40} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ) : (users.length > 0 ?
                                    (users.map((user, index) => {
                                        let userScopes = user.Scopes.map((s) => (
                                            <>
                                                {s}
                                                <br />
                                            </>
                                        ));

                                        const hasArea = user.Area !== null && user.Area !== "";
                                        const hasScope = user.Scopes.length > 0;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell style={bodyCellCss}>{user.Username}</TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    {user.RoleNameTranslated}
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="center">
                                                    <Checkbox checked={user.HasNotifications}></Checkbox>
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    {user.AreaTranslated}
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    {!hasScope && hasArea ? Mashie.Resources.GetString("All") : userScopes}
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    {user.EntitlementName}
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    {user.CustomerCategory}
                                                </TableCell>
                                                <TableCell style={bodyCellCss} align="left">
                                                    <Button onClick={() => NotificationAdminStore.loadUser(user)} color="primary">
                                                        <EditIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })) : (
                                        <TableRow>
                                            <TableCell style={bodyCellCss} align="center" colSpan={7} >
                                                {Mashie.Resources.GetString("TN_NoSearchResult")}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
