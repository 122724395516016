import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react'
import OrderAssortmentListStates from './OrderAssortmentListStates';
import './OrderAssortmentList.css'
import { reaction } from 'mobx';

export function feature_js_order_assortment_clients(el) {
    let $el = $(el);

    const translations = {
        sunday: $el.attr('data-tr-sunday'), //OL_Sunday
        monday: $el.attr('data-tr-monday'), //OL_Monday
        tuesday: $el.attr('data-tr-tuesday'), //OL_Tuesday
        wednesday: $el.attr('data-tr-wednesday'), //OL_Wednesday
        thursday: $el.attr('data-tr-thursday'), //OL_Thursday
        friday: $el.attr('data-tr-friday'), //OL_Friday
        saturday: $el.attr('data-tr-saturday'), //OL_Saturday
        selectAll: $el.attr('data-tr-select-all'),
        orderers: $el.attr('data-tr-orderers'),
        search: $el.attr('data-tr-search'),
        applyAll: $el.attr('data-tr-apply-all'),
        applyAllNotification: $el.attr('data-tr-apply-all-notification'),
        clients: $el.attr('data-tr-clients')
    };
    const allClients = $el.attr('data-all-clients');
    const dayAssignedClients = $el.attr('data-assigned-clients');
    const locked = $el.attr('data-lock-status') == 'locked';

    ReactDOM.render(<OrderAssortmentClients translations={translations} allClients={allClients} dayAssignedClients={dayAssignedClients} locked={locked} store={OrderAssortmentListStates} />, el[0]);
}



@observer
class OrderAssortmentClients extends React.Component {
    constructor(props) {
        super(props);

        this.allClients = JSON.parse(this.props.allClients);
        this.dayAssignedClients = JSON.parse(this.props.dayAssignedClients);

        this.state = {
            days: [
                this.getDayClients(1),
                this.getDayClients(2),
                this.getDayClients(3),
                this.getDayClients(4),
                this.getDayClients(5),
                this.getDayClients(6),
                this.getDayClients(0)
            ]
        };

        this.props.store.setAssignedClientsByDay(this.state.days);
        this.onChange();

        reaction(() => this.props.store.activeDays, () => this.onChange());
    }

    dayOfWeekToString(dayValue) {
        switch (dayValue) {
            case 1: return 'Monday';
            case 2: return 'Tuesday';
            case 3: return 'Wednesday';
            case 4: return 'Thursday';
            case 5: return 'Friday';
            case 6: return 'Saturday';
            case 0: return 'Sunday';
        }
    }

    getDayClients(dayOfWeek) {
        let day = this.dayAssignedClients.find(d => d.dayOfWeek == this.dayOfWeekToString(dayOfWeek));
        const toCheckableClients = function (client) {
            return {
                id: client.id,
                name: client.name,
                isActive: client.isActive,
                checked: false
            }
        }

        if (day) {
            var availableClients = this.allClients.filter(c => day.assignedCustomers.find(a => a.id == c.id) == null).map(c => toCheckableClients(c));
            return {
                dayOfWeek: dayOfWeek,
                availableClients: availableClients,
                searchText: '',
                filteredAvailableClients: availableClients,
                assignedClients: day.assignedCustomers.map(c => toCheckableClients(c))
            }
        } else {
            var availableClients = this.allClients.map(c => toCheckableClients(c))
            return {
                dayOfWeek: dayOfWeek,
                availableClients: availableClients,
                searchText: '',
                filteredAvailableClients: availableClients,
                assignedClients: []
            }
        }
    }

    updateState() {
        this.setState({
            days: this.state.days
        });

        this.props.store.setAssignedClientsByDay(this.state.days);
    }

    onChange = () => {
        const input = $("[id$=hdfAssignedCustomersByDays]")[0];
        var assignedCustomerByDays = this.state.days.filter(d => this.props.store.activeDays.includes(d.dayOfWeek)).map(d => {
            return {
                dayOfWeek: this.dayOfWeekToString(d.dayOfWeek),
                assignedCustomers: d.assignedClients
            };
        });
        input.value = JSON.stringify(assignedCustomerByDays);
        console.log(assignedCustomerByDays);
    }

    selectAllAvailableClients(day, event) {
        day.availableClients.forEach(c => c.checked = event.target.checked);
        this.updateState();
    }

    selectAllAssignedClients(day, event) {
        day.assignedClients.forEach(c => c.checked = event.target.checked);
        this.updateState();
    }

    addSelectedClients(day, event) {
        event.preventDefault();
        if (this.props.locked) return;

        let selectedClients = day.filteredAvailableClients.filter(c => c.checked);

        day.assignedClients = day.assignedClients.concat(selectedClients);
        day.availableClients = day.availableClients.filter(c => selectedClients.map(x => x.id).indexOf(c.id) == -1);
        this.updateFilteredAvailableClients(day);

        selectedClients.forEach(c => c.checked = false);
        this.updateState();
        this.onChange();
    }

    removeSelectedClients(day, event) {
        event.preventDefault();
        if (this.props.locked) return;

        let selectedClients = day.assignedClients.filter(c => c.checked);

        day.availableClients = day.availableClients.concat(selectedClients);
        this.updateFilteredAvailableClients(day);
        day.assignedClients = day.assignedClients.filter(c => !c.checked);

        selectedClients.forEach(c => c.checked = false);
        this.updateState();
        this.onChange();
    }

    handleSearchText(day, event) {
        day.searchText = event.target.value;
        this.updateFilteredAvailableClients(day);
        this.updateState();
    }

    updateFilteredAvailableClients(day) {
        if (day.searchText) {
            day.filteredAvailableClients = day.availableClients.filter(c => c.name && c.name.toLowerCase().indexOf(day.searchText.toLowerCase()) != -1);
        } else {
            day.filteredAvailableClients = day.availableClients;
        }
    }

    applyConfigurationToAll(day) {
        if (confirm(this.props.translations.applyAllNotification)) {
            this.state.days.forEach(d => {
                d.assignedClients = day.assignedClients.map(o => this.clone(o));
                d.availableClients = day.availableClients.map(o => this.clone(o));
                this.updateFilteredAvailableClients(d);
            });

            this.updateState();
            this.onChange();
        }
    }

    getNameOfTheWeek(dayValue) {
        return this.props.translations[this.dayOfWeekToString(dayValue).toLowerCase()];
    }

    renderCheckboxClient(client, day) {
        const checkboxId = `chkClient-${day.dayOfWeek}-${client.id}`;
        const activeClass = client.isActive ? 'active-client' : 'inactive-client';
        const handleChecked = (e) => {
            client.checked = e.target.checked;
            this.updateState();
        }

        return <td><input id={checkboxId} type="checkbox" checked={client.checked} onChange={handleChecked} disabled={this.props.locked} /><label className={activeClass} htmlFor={checkboxId}>{client.name}</label></td>
    }

    renderAvailableClients(day) {
        let rows = [];

        for (let i = 0; i < day.filteredAvailableClients.length / 2; i++) {
            const oddIndex = i * 2;
            const evenIndex = i * 2 + 1;
            rows.push(
                <tr key={i}>
                    {this.renderCheckboxClient(day.filteredAvailableClients[oddIndex], day)}
                    {
                        evenIndex <= day.filteredAvailableClients.length - 1 &&
                        this.renderCheckboxClient(day.filteredAvailableClients[evenIndex], day)
                    }
                </tr>
            );
        }

        return rows;
    }

    renderAssignedClients(day) {
        let rows = [];

        for (let i = 0; i < day.assignedClients.length; i++) {
            rows.push(
                <tr key={i}>
                    {this.renderCheckboxClient(day.assignedClients[i], day)}
                </tr>
            );
        }

        return rows;
    }

    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }

    render() {
        const context = this;

        return (
            <div className="order-assortment-clients">
                {
                    this.state.days.filter(d => context.props.store.activeDays.includes(d.dayOfWeek)).map(function (o) {
                        return (
                            <table key={o.dayOfWeek}>
                                <tbody>
                                    <tr>
                                        <td width="20px"></td>
                                        <td style={{ fontWeight: 'bold' }}>{context.getNameOfTheWeek(o.dayOfWeek)}</td>
                                    </tr>
                                    <tr>
                                        <td width="20px"></td>
                                        <td colSpan="2" width="600">
                                            <input id={"chkAllAvailableClient-" + o.dayOfWeek}
                                                type="checkbox"
                                                onChange={context.selectAllAvailableClients.bind(context, o)}
                                                disabled={context.props.locked} />
                                            <label htmlFor={"chkAllAvailableClient-" + o.dayOfWeek}>{context.props.translations.selectAll}</label>
                                            <div className="input-search-icon" style={{ width: '200px', marginLeft: '10px' }}>
                                                <input type="text"
                                                    placeholder={context.props.translations.search}
                                                    onChange={context.handleSearchText.bind(context, o)}
                                                    disabled={context.props.locked} />
                                            </div>
                                        </td>
                                        <td></td>
                                        <td width="300">
                                            <input id={"chkAllAssignedClient-" + o.dayOfWeek}
                                                type="checkbox"
                                                onClick={context.selectAllAssignedClients.bind(context, o)}
                                                disabled={context.props.locked} />
                                            <label htmlFor={"chkAllAssignedClient-" + o.dayOfWeek}>{context.props.translations.selectAll}</label>
                                            <span className="LabelClass" style={{ float: 'right' }}>({o.assignedClients.length})</span>
                                            <span className="LabelClass" style={{ float: 'right' }}>{context.props.translations.clients}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="20px"></td>
                                        <td colSpan="2" width="600">
                                            <div style={{ borderStyle: 'inset', overflow: 'auto', height: '300px', marginTop: '10px' }}>
                                                <table className="CheckBoxClass" border="0">
                                                    <tbody>
                                                        {context.renderAvailableClients(o)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="image" src="../Images/orgHogerPil.gif"
                                                                onClick={context.addSelectedClients.bind(context, o)}
                                                                style={{ borderWidth: 0 }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="image" src="../Images/orgVansterPil.gif"
                                                                onClick={context.removeSelectedClients.bind(context, o)}
                                                                style={{ borderWidth: 0 }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td width="300">
                                            <div style={{ borderStyle: 'inset', overflow: 'auto', height: '300px' }}>
                                                <table className="CheckBoxClass" border="0">
                                                    <tbody>
                                                        {context.renderAssignedClients(o)}
                                                    </tbody></table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4"></td>
                                        <td>
                                            <button type="button"
                                                onClick={context.applyConfigurationToAll.bind(context, o)}
                                                style={{ width: '100%', textAlign: 'center' }}
                                                disabled={context.props.locked}>
                                                {context.props.translations.applyAll}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })
                }
            </div>
        );
    }
}

export default OrderAssortmentClients;