"use strict";

require('./AdministrationLabelingQueue/edit.feature');
require('./AdministrationOrganizationUsers/Edit.feature');
require('./meal/meal-information.feature');
require('./menu/day-menu-information.feature');
require('./recipe/recipe-information.feature');
require('./login/index.feature');
require('./labeling-queue-list/index.feature');
require('./calendar/index.feature');
require('./order/SaveCustomerClientCustomOptioControl.feature');
require('./shared/information-print.feature');
require('./basket/index');
require('./HolidayEdit/holidayedit.feature');
require('./OrderListHoliday/OrderListHoliday.feature');
require('./Maintenance/maintenance_page.feature');
require('./DayMenu/DayMenuSpecification.feature');
require('./NutrientGroups/NutrientGroupsMain.feature');
require('./Sandbox/sandbox.feature');
require('./Maintenance/maintenance_page.feature');
require('./Unit/Unit-AdminPage.feature');
require('./Bugreport/BugReportMain.feature');
require('./History/history.feature');
require('./CustomerInvoiceAccountModal/index');