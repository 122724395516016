"use strict";
import _ from 'lodash';

var changes = [];

export default function changed(value) {
    var result = { value: value };
    changes.push(result);

    return function() {
        changes = _.reject(changes, function(x) { return x === result; });
    };
};

jQuery(window).on('beforeunload', function (evt) {
    var result = _.compact(_.map(changes, function (x) { return typeof x.value === 'function' ? x.value() : x.value; })).join('\n');
    if (result) return result;
});
