import React from "react";
import { Tab } from "./tab";

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeTab: this.props.children[0].props.label
  };

  onClick = label => {
    this.setState({ activeTab: label });
  };

  renderTabLabels = () =>
    this.props.children.map(child => {
      const { label } = child.props;
      const isActive = label === this.state.activeTab;
      return <Tab isActive={isActive} key={label} label={label} onClick={this.onClick} />;
    });

  renderTabContent = () =>
    this.props.children.map(child => {
      return child.props.label !== this.state.activeTab ? null : child.props.children;
    });

  render() {
    return (
      <div className="tabs">
        <ol className="tabs__nav">{this.renderTabLabels()}</ol>
        <div className="tabs__content">{this.renderTabContent()}</div>
      </div>
    );
  }
}
