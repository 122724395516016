jQuery.feature_import("js-lock",
  () => require("./lockable.js"),
  mod => mod.feature_js_lock
);
jQuery.feature_import("js-lockable--lock",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_lock
);
jQuery.feature_import("js-lockable--disable",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_disable
);
jQuery.feature_import("js-lockable--text",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_text
);
jQuery.feature_import("js-lockable--stars",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_stars
);
jQuery.feature_import("js-lockable--hide",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_hide
);
jQuery.feature_import("js-lockable--show",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_show
);
jQuery.feature_import("js-lockable--class",
  () => require("./lockable.js"),
  mod => mod.feature_js_lockable_class
);