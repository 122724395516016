﻿"use strict";
/* global Mashie:true */
if (typeof Mashie === 'undefined') Mashie = {};
if (typeof Mashie.Resources === 'undefined') Mashie.Resources = {}; 
if (typeof Mashie.LanguageCode === 'undefined') Mashie.LanguageCode = 'sv-se';

(function (r) {
    r.GetString = function (key) {
        if (typeof r.Resource[key] === 'undefined' || r.Resource[key] === '') {
            return 'Missing string: ' + key;
        } else {
            if (Mashie.Cookie.GetCookie('TranslationKey') == 'false') {
                return r.Resource[key];
            }
            else {
                return (r.Resource[key] + ' [' + key + ']');
            }
        }
    };

    r.GetLanguageCode = function () {
        return Mashie.LanguageCode;
    };

})(window.Mashie.Resources, jQuery);