// var Cookies = require('../vendor/jquery.cookie.js');
// import http from 'superagent';

// export function feature_js_mashie_translate_mode(el, url) {
//   var transCookie = Cookies.get('TranslationMode');
//   console.log(transCookie);

//   transCookie === 'True' ? el.css('display', 'flex') : el.hide();

//   var b = el.find('#globalization-control-button');

//   var iconWrapper = $('#globalization-control-icon');
//   var icon = $('#globalization-control-icon span');
//   var buttonWrapper = $('#globalization-control-button-wrapper');
//   var button = $('#globalization-control-button');

//   el.find('#globalization-control-button').on('click', function() {
//     console.log('clicked');

//     iconWrapper.css({ 'background-color': 'white', width: '100%' });
//     icon.css({ color: '#95b417' });
//     icon.addClass('fa-spin');
//     button.css({ width: '0', padding: '0' });
//     buttonWrapper.css({ width: '0' });
//     el.css({ width: '80px' });

//     http
//       .post(url)
//       .end()
//       .then(function(res) {
//         if (res.statusText === 'OK') {
//           console.log('YES');
//           iconWrapper.css({ 'background-color': '#95b417', width: '25%' });
//           icon.css({ color: 'white' });
//           icon.removeClass('fa-spin');
//           button.css({ width: '80%', padding: '5px 15px' });
//           buttonWrapper.css({ width: '100%' });
//           el.css({ width: '300px' });

//           $('#toast-control-success').css({ bottom: '0' });

//           setTimeout(function() {
//             $('#toast-control-success').css({ bottom: '-100px' });
//           }, 5000);
//         }
//       });
//   });
// }

// export function feature_js_globalization_admin(el) {
//   const activate = $('#globalization-admin-activate');
//   const refresh = $('#globalization0-admin-refresh');

//   activate.click(() => {
//     const url = activate.data('url');
//     http
//       .post(url)
//       .end()
//       .then(response => {
//         if (response.statusText === 'OK') {
//         }
//       });
//   });

//   refresh.click(() => {
//     const url = refresh.data('url');
//     http
//       .post(url)
//       .end()
//       .then(response => {
//         if (response.statusText === 'OK') {
//         }
//       });
//   });
// }
