import React from 'react';

var selectStyle = {
    borderRadius: '5px',
    height: '25px',
    maxWidth: '600px'
};

export class ClimateSelect extends React.Component {
    selectedChanged = (e) => {
        var index = e.currentTarget[e.currentTarget.selectedIndex].value;

        if (index === -1) {
            this.props.selectedClimateChanged(null, index);
        } else {
            const climatedata = this.props.climatedata[index]
            this.props.selectedClimateChanged(climatedata, index);
        }
    };

    getNoneSelectedValue = () => {
        if (this.props.selectedValue === -1) {
            return [
                <option value={-1} key={-1}>
                    {Mashie.Resources.GetString('Item.Climate.SelectValue')}
                </option>
            ];
        } else {
            return [];
        }
    }

    getClimateOptions = () => {
        var climateOptionValues = this.props.climatedata.map((p, i) => {
            return (
                <option value={i} key={i}>
                    {p.KgGreenHouseGasPerKg} - {p.Name} - ({p.Region.Name}) {p.ProductionProcess.Name} ({p.ProductionProcess.InformationSource})
                </option>
            );
        });

        return this.getNoneSelectedValue().concat(climateOptionValues);
    }

    render() {
        return (
            <div>
                <label>
                {Mashie.Resources.GetString('Item.Climate.ResultLabel')} :
                </label>
                <span style={{ color: 'red', marginLeft: '5px' }}>
                    {this.props.error}
                </span>

                <select
                    value={this.props.selectedValue}
                    onChange={this.selectedChanged}
                    style={selectStyle}
                    className="form-control"
                >
                {this.getClimateOptions()}
                </select>
                <label className="NavigationClass">
                    ({this.props.climatedata.length}
                    {' '}
                    {Mashie.Resources.GetString('Item.Climate.ValuesLabel')}
                    )
                </label>
            </div>
        );
    }
}