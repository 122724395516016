import rivets from '../../lib/rivets';

export function feature_js_orderListExceptionDays() {

    var saveButton = $("[id$=SaveBarButton]");
    var lockButton = $("[id$=LockStateButton]");
    var isLocked = $(lockButton).attr('isLocked') === "1" ? true : false;

    var orgId = $("#OrderListHolidays").attr('orgid');

    var scope = {
        locked: isLocked,
        holidays: [],
        warnings: [],
        orderLists: []
    }

    function getHolidayId() {
        return $('#holiday_dd option:selected').attr('id');
    }

    function isExceptionDayTabIsActive() {
        return $("#exceptiondays:visible").length > 0;
    }

    function getOrderLists(holidayId, orgid) {

        $.ajax({
            url: "/mashie/holiday/get/orderliststate/org/" + orgid + "/holiday/" + holidayId,
            success: function (data) {
                scope.orderLists = JSON.parse(data);
            },
            global: false,
            type: "POST"

        });

    }

    function toggleOrderListHoliday(holidayId, orderListId) {

        $.ajax({
            url: "/mashie/holiday/" + holidayId + "/toggle/orderlist/" + orderListId,
            success: function () {
                getOrderLists(holidayId, orgId);
            },
            global: false,
            type: "POST"

        });

    }
    function getHolidays(orgId) {
        $.ajax({
            type: "POST",
            url: "/mashie/holiday/get/" + orgId,
            success: function (data) {
                scope.holidays = JSON.parse(data);

                if (scope.holidays.length > 0) {
                    $.ajax.skipNextSpinner = true;
                    getOrderLists(getHolidayId(), orgId);
                }
            }
        });
    }

    $.ajax.skipNextSpinner = true;
    getHolidays(orgId);

    //$("#exceptiondays-tab").click(function () {
    //    getHolidays(orgId);

    //});




    scope.connectAll = function () {
        var holidayid = getHolidayId();
        $.ajax({
            type: "POST",
            global: false,
            url: "/mashie/holiday/" + holidayid + "/orderlist/connectall/" + orgId,
            success: function () {
                getOrderLists(holidayid, orgId);
            }
        });
    }
    scope.disconnectAll = function () {
        var holidayid = getHolidayId();
        $.ajax({
            type: "POST",
            global: false,
            url: "/mashie/holiday/" + holidayid + "/orderlist/deleteall",
            success: function () {
                getOrderLists(holidayid, orgId);

            }
        });
    }
    scope.toogleHolidayConnection = function (event) {

        var holidayid = getHolidayId();
        var orderlistId = $(event.currentTarget).attr('orderlistid');

        toggleOrderListHoliday(holidayid, orderlistId);
    }
    scope.selectHoliday = function (event) {
        var holidayId = getHolidayId();

        getOrderLists(holidayId, orgId);
    }

    rivets.bind($("#OrderListHolidays"), scope);

}