﻿$(document).ready(function () {
    var language = Mashie.Cookie.GetCookie("cookieLanguage") || 'sv-SE', momentLanguage = language;

    switch (language) {
        case "nb-NO":
            momentLanguage = "nn";
            $.datepicker.regional.no.dateFormat = "dd.mm.yy";
            $.datepicker.setDefaults($.datepicker.regional.no);
            break;

        case "en-GB":
            $.datepicker.setDefaults($.datepicker.regional["en-GB"]);
            break;

        case "da":
        case "da-DK":
            //$.datepicker.regional.da.dateFormat = "dd-mm-yy";
            moment.localeData('da')._longDateFormat.L = "DD-MM-YYYY";
            moment.localeData('da')._longDateFormat.l = "D-M-YYYY";
            $.datepicker.setDefaults($.datepicker.regional["da"]);
            break;
        case "fi":
        case "fi-FI":
            //$.datepicker.regional.fi.dateFormat = "d.m.yy";
            moment.localeData('fi')._longDateFormat.L = "D.M.YYYY";
            moment.localeData('fi')._longDateFormat.l = "D.M.YYYY";
            $.datepicker.setDefaults($.datepicker.regional["fi"]);

    default:
        momentLanguage = language = language.substring(0, 2);
        $.datepicker.setDefaults($.datepicker.regional[language]);
    }

    //$.validator.addMethod("dateValidator", function (value, element) {
    //    moment.locale(momentLanguage);
    //        var valid;
    //        if ($(element).parent().is('[allowEmptyText]')){
    //            valid = moment(value, 'L', true).isValid() || value === '';
    //            return valid;
    //        } else {
    //            valid = moment(value, 'L', true).isValid();
    //            return valid;
    //        }
    //    }
    //    ,Mashie.Resources.GetString("PMSC_ErrorFormat")
    //);

    //$.validator.addClassRules("validatedDate", {
    //    dateValidator: true
    //});
    
    //$("form:first").focusout().validate();
    $(document).trigger('reload-dates');
});

$(document).on('reload-dates', function () {
    $(".datePickerTextBox").datepicker({
        calendarWeeks: true,
        todayHighlight: true,
        buttonImage: "../Images/calendar.gif",
        buttonImageOnly: true,
        showOn: "button"
        
    });

    $(".datePickerTextBoxWithoutValidation").datepicker({
        calendarWeeks: false,
        todayHighlight: true,
        buttonImage: "../Images/calendar.gif",
        buttonImageOnly: true,
        showOn: "button",
        showWeek: true
        
    });
});