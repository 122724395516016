import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    Grid,
    Modal,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import MashiePage from "../../react-shared/Page/MashiePage";
import { useConfigurationsStore } from "./stores/orderAppConfigStore";
import { AxiosError } from "axios";
import { CategoryConfigurationTable } from "./components/CategoryConfigurationTable";
import { translate } from "./Translations/labelStrings";
import AppConfigLabeledCheckbox from "./components/AppConfigLabeledCheckbox";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const feature_js_order_app_config = () => {
    var element = document.getElementById("order-app-config");
    render(
        <div>
            <OrderAppConfig />
        </div>,
        element
    );
};

export const OrderAppConfig = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLocked, setIsLocked] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [exceptionMessage, setException] = useState("");
    const [isNoCategoryChosenAlertOpen, setIsNoCategoryChosenAlertOpen] =
        useState(false);
    const {
        initialState,
        createConfiguration,
        setGeneralInformationVisible,
        setMealFreeDescriptionVisible,
        updateGeneralInformation,
        updateMealFreeDescription,
        saveOrUpdateConfigurations,
        orderAppConfigurations,
        customerCategories,
        isGeneralInformationVisible,
        generalInformation,
        isMealFreeDescriptionVisible,
        mealFreeDescription,
    } = useConfigurationsStore();
    const handleCloseNoCategoryChosenAlert = () =>
        setIsNoCategoryChosenAlertOpen(false);

    useEffect(() => {
        const getCustomerCategory = async () => {
            try {
                await initialState();
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setHasError(true);
                setException((err as AxiosError).message);
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        getCustomerCategory();
    }, []);

    const handleNewClick = () => {
        createConfiguration();
    };

    const handleLockClick = () => {
        if (isLocked) {
            setIsLocked(false);
        } else {
            var invalid = validateCategoryNotChosenConfigurations();
            if (invalid) {
                setIsNoCategoryChosenAlertOpen(true);
            } else {
                saveOrUpdateConfigurations();
                setIsLocked(true);
            }
        }
    };
    const handleSaveClick = () => {
        validateCategoryNotChosenConfigurations()
            ? setIsNoCategoryChosenAlertOpen(true)
            : saveOrUpdateConfigurations();
    };

    const validateCategoryNotChosenConfigurations = (): boolean => {
        const result =
            orderAppConfigurations.filter(
                (order) => order.customerCategoryId == ""
            ).length > 0;

        return result;
    };

    const theme = useTheme();
    return (
        <>
            {isNoCategoryChosenAlertOpen && (
                <Modal
                    open={isNoCategoryChosenAlertOpen}
                    onClose={handleCloseNoCategoryChosenAlert}
                >
                    <Alert severity="error" sx={style}>
                        <AlertTitle>
                            <Typography
                                id="modal-modal-title"
                                variant="h4"
                                component="h1"
                            >
                                {translate("Error")}
                            </Typography>
                        </AlertTitle>
                        <Typography id="modal-modal-description" variant="h6">
                            {translate("No_Category_Assigned")}
                        </Typography>
                    </Alert>
                </Modal>
            )}
            <MashiePage
                pageName={translate("OrderAppConfigurationHeader")}
                locked={isLocked}
                OnLockClick={handleLockClick}
                OnNewClick={handleNewClick}
                onSaveClick={handleSaveClick}
            >
                {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : hasError ? (
                    <Alert variant="outlined" severity="error">
                        <AlertTitle sx={{ fontSize: 20 }}>
                            {translate("Error")}
                        </AlertTitle>
                        <p style={{ fontSize: 16 }}>{exceptionMessage}</p>
                    </Alert>
                ) : (
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <CategoryConfigurationTable
                                    orderAppConfigurations={
                                        orderAppConfigurations
                                    }
                                    customerCategories={customerCategories}
                                    isLocked={isLocked}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1} paddingTop={1}>
                                    <Grid item xs={12}>
                                        <AppConfigLabeledCheckbox
                                            checked={
                                                isGeneralInformationVisible
                                            }
                                            disabled={isLocked}
                                            onChange={(checked) =>
                                                setGeneralInformationVisible(
                                                    checked
                                                )
                                            }
                                            labelText={translate(
                                                "general_information_ingredients"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows={3}
                                            sx={{ width: "500px" }}
                                            disabled={isLocked}
                                            value={generalInformation}
                                            onChange={(e) =>
                                                updateGeneralInformation(
                                                    e.target.value
                                                )
                                            }
                                            InputProps={{
                                                style: {
                                                    fontSize:
                                                        theme.typography.body1
                                                            .fontSize,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} spacing={1}>
                                        <AppConfigLabeledCheckbox
                                            checked={
                                                isMealFreeDescriptionVisible
                                            }
                                            disabled={isLocked}
                                            onChange={(checked) =>
                                                setMealFreeDescriptionVisible(
                                                    checked
                                                )
                                            }
                                            labelText={translate(
                                                "meal_free_description"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows={3}
                                            sx={{
                                                width: "500px",
                                            }}
                                            disabled={isLocked}
                                            value={mealFreeDescription}
                                            onChange={(e) =>
                                                updateMealFreeDescription(
                                                    e.target.value
                                                )
                                            }
                                            InputProps={{
                                                style: {
                                                    fontSize:
                                                        theme.typography.body1
                                                            .fontSize,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </MashiePage>
        </>
    );
};
