jQuery.feature_import("recipe-information-active",
  () => require("./index.features"),
  mod => mod.feature_recipe_information_active
);
jQuery.feature_import("js-custom-allergens",
  () => require("./index.features"),
  mod => mod.feature_js_custom_allergens
);
jQuery.feature_import("js-recipelabel",
  () => require("./index.features"),
  mod => mod.feature_js_recipelabel
);
jQuery.feature_import("recipe-information-delete",
  () => require("./index.features"),
  mod => mod.feature_recipe_information_delete
);
jQuery.feature_import("js-recipe-modal",
  () => require("./index.features"),
  mod => mod.feature_js_recipe_modal
);
jQuery.feature_import("recipe-information-new",
  () => require("./index.features"),
  mod => mod.feature_recipe_information_new
);
jQuery.feature_import("recipe-information-save",
  () => require("./index.features"),
  mod => mod.feature_recipe_information_save
);
jQuery.feature_import("recipe-information-wysiwyg",
  () => require("./index.features"),
  mod => mod.feature_recipe_information_wysiwyg
);
jQuery.feature_import("js-priceweightcontrol",
  () => require("./index.features"),
  mod => mod.feature_js_priceweightcontrol
);
jQuery.feature_import("js-recipelabel",
  () => require("./index.features"),
  mod => mod.feature_js_recipelabel
);
