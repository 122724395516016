import changed from '../lib/changed';
import caption from '../lib/caption';

export function feature_js_form_dirty_formless(el) {

    var submitting = false, dirtyCallback;
    var dirtyAlert = el.find('js-form-dirty--alert');
    if (dirtyAlert.length === 0) {
        dirtyAlert = $("<div class='alert-dirty-form alert alert-warning'>").appendTo(el);
        dirtyAlert.append($("<span>").text(caption("Ref_Unsaved")));
    }
    dirtyAlert.addClass("hidden");

    $('#lockButton,#saveButton,.saveButton').click(function () {
        //if (!dirtyAlert.hasClass("hidden")) {
        //    dirtyAlert.addClass("hidden");
             
        //}
        if (dirtyCallback) dirtyCallback();
    });
    
    el.on('change keyup', 'input[dirty-input],select[dirty-input],textarea[dirty-input]', dirtyCheck);

    function dirtyCheck() {
        if (!dirtyAlert.hasClass("hidden")) return;

        if (dirtyCallback) dirtyCallback();
        dirtyCallback = changed(function () {
            if (!submitting) return caption("Your changes have not been saved, are you sure you want to leave the page?");
        });

       
        el.addClass('form-dirty');
        dirtyAlert.removeClass("hidden");
    }
}


var selector = ['input', 'textarea', 'select'].map(c => c + ':not([js-form-dirty--ignore]):not([js-lockable--lock])').join(',');

export function feature_js_form_dirty(el) {
    var isDirty = false, submitting = false, serialized = el.find(selector).serialize();
    var dirtyAlert = el.find('js-form-dirty--alert');
    if (dirtyAlert.length === 0) {
        dirtyAlert = $("<div class='alert-dirty-form alert alert-warning hidden'>").appendTo(el);
        dirtyAlert.append($("<span>").text(caption("Ref_Unsaved")));
    }

    el.on('submit', function () { submitting = true; });
    el.on('change keyup', selector, dirtyCheck);
    el.on('dirty', dirtyCheck);
	
	    $('#lockButton,#saveButton,.saveButton').click(function () {
		submitting = true;
       
    });

    function dirtyCheck() {
        if (isDirty) return;
        if (el.find(selector).serialize() === serialized) return;

        changed(function () {
            if (!submitting) return caption("Your changes have not been saved, are you sure you want to leave the page?");
        });

        isDirty = true;
        el.addClass('form-dirty');
        dirtyAlert.removeClass("hidden");
    }
}