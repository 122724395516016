"use strict";

jQuery.feature_import("js-basket-modal",
  () => import("./index.features.js"),
  mod => mod.feature_js_basket_modal
);
jQuery.feature_import("js-basket-transfer",
  () => import("./index.features.js"),
  mod => mod.feature_js_basket_transfer
);
jQuery.feature_import("js-basket-hierarchy-delete",
  () => import("./index.features.js"),
  mod => mod.feature_js_basket_hierarchy_delete
);
jQuery.feature_import("js-basket-hierarchy-copy",
  () => import("./index.features.js"),
  mod => mod.feature_js_basket_hierarchy_copy
);
jQuery.feature_import("js-basket",
  () => import("./index.features.js"),
  mod => mod.feature_js_basket
);