import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";

interface Props {
    checked: boolean;
    disabled: boolean;
    onChange: (checked: boolean) => void;
    labelText: string;
}

const AppConfigLabeledCheckbox = ({
    checked,
    disabled,
    onChange,
    labelText,
}: Props) => {
    return (
        <Box sx={{ display: "flex" }}>
            <Checkbox
                checked={checked}
                disabled={disabled}
                onChange={(e) => {
                    return onChange(e.target.checked);
                }}
                sx={{ py: 0 }}
            />
            <Typography
                variant={"h6"}
                color={"text.primary"}
                fontWeight={"bold"}
            >
                {labelText}
            </Typography>
        </Box>
    );
};

export default AppConfigLabeledCheckbox;
