"use strict";

jQuery.feature_import("js-table--add-row",
  () => require("./table.js"),
  mod => mod.feature_js_table_add_row
);
jQuery.feature_import("js-table--delete-row",
  () => require("./delete-row.js"),
  mod => mod.feature_js_table_delete_row
);
jQuery.feature_import("js-table--mark-all-as-deleted",
  () => require("./table.js"),
  mod => mod.feature_js_table_mark_all_as_deleted
);
jQuery.feature_import("js-table--mark-as-deleted-row",
  () => require("./table.js"),
  mod => mod.feature_js_table_mark_as_deleted_row
);
jQuery.feature_import("js-table--expand-row",
  () => require("./table.js"),
  mod => mod.feature_js_table_expand_row
);
jQuery.feature_import("js-table--sortable",
  () => require("./table.js"),
  mod => mod.feature_js_table_sortable
);
jQuery.feature_import("js-table--select-all",
  () => require("./table.js"),
  mod => mod.feature_js_table_select_all
);
jQuery.feature_import("js-table--filter",
  () => require("./table.js"),
  mod => mod.feature_js_table_filter
);
jQuery.feature_import("js-dataview-filter",
  () => require("./table.js"),
  mod => mod.feature_js_dataview_filter
);
jQuery.feature_import("js-dataview-sort",
  () => require("./table.js"),
  mod => mod.feature_js_dataview_sort
);
jQuery.feature_import("js-table-selectable-rows",
    () => import("./table.js"),
    mod => mod.feature_js_table_selectable
);
