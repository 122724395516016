"use strict";

export function feature_js_nutrients_admin(el) {
    $('.cbTableControlSelectedRow > input').on('change', function () {
        if ($(this).attr('checked')) {
            var _code = $(this).closest('tr').find('[parentCode]').attr('parentCode');
            var parent = $('table').find("[data-nut-code='" + _code + "']");
            var found = $(parent).closest('tr').find('input:checkbox');
            if (!$(found).attr('checked')) {
                $(found).trigger('click');
            }
        }
        else
        {
            var attr = $(this).closest('tr').find('[parentCode]').attr('parentCode');
            var code = $(this).closest('tr').find('[data-nut-code]').attr('data-nut-code');
            var children = $('table').find("[data-nut-parent-code='" + code + "']");
            if (children !== '') uncheckElement(children);
        }
    });
}


function uncheckElement(children) {
    $.each(children, function (index, value) {
        var code = $(value).closest('tr').find('[parentCode]').attr('parentCode');
        var parent = $('table').find("[data-nut-parent-code='" + code + "']");
        var found = $(parent).closest('tr').find('input:checkbox');

        $.each(found, function (index, value) {
            if ($(value).attr('checked')) $(value).trigger('click');
        });

        var subcode = $(value).closest('tr').find('[data-nut-code]').attr('data-nut-code');
        var subchildren = $('table').find("[data-nut-parent-code='" + subcode + "']");
        if (subchildren !== '') uncheckElement(subchildren);
    });
}