import axios from "axios"
import { ClientDietResponse, DietGroupedResponse } from "./MealSettings"

const BASE_URL = "/mashie/ClientDietSettings/diet-settings"

export const fetchMealOptions = async (clientId: string, categoryId: string): Promise<ClientDietResponse> => {
    const url = `${BASE_URL}/${clientId}/${categoryId}`;
    const response = await axios.get<ClientDietResponse>(url);
    return response.data;
}

export const fetchDietPreferences = async (categoryId: string): Promise<DietGroupedResponse[]> => {
    const url = `${BASE_URL}/diet-preferences/${categoryId}`;
    const response = await axios.get<DietGroupedResponse[]>(url);
    return response.data;
}