import __ from '../lib/caption';

export function feature_js_mediapicker(el, opts) {
    el.click(function () {
        var rootUrl = $(document.body).attr('js-basepath');
        var callback = '_mediapicker_' + (+new Date());
        var selectorUrl = rootUrl + '/WebForms/PictureSelector.aspx?return=' + callback + '&' + (opts || '');
        var targetSrc = el.attr('js-mediapicker-target') || el.parent().find('[js-mediapicker-target]');
        var targetName = el.attr('js-mediapicker-target-name') || el.parent().find('[js-mediapicker-target-name]');
        var namePrefix = el.attr('js-mediapicker-name-prefix') || '';
        var closeButton = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
        var modal = $('<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">');
        var modalDialog = $('<div class="modal-dialog modal-lg">').appendTo(modal);
        var modalContent = $('<div class="modal-content">').appendTo(modalDialog);
        var modalBody = $('<div class="modal-body">').appendTo(modalContent);
        var header = $('<div class="modal-header">');

        $(header)
        .append($('<h4 class="modal-title">').text(__('LibraryPictures')))
        .prependTo(modalContent);

        $('<iframe>')
            .css({ border: 0, height: 565, width: '100%' })
            .attr('src', selectorUrl)
            .appendTo(modalBody);

        var activeModal = modal.hide()
            .appendTo(document.body)
            .modal();
        //{ backdrop: 'static' }

        window[callback] = function returnValue(value, selected) {
            delete window[callback];
            activeModal.modal('hide');

            if (selected) {
                $(targetSrc).val(value.src).change().trigger('input');
                $(targetName).val(namePrefix + value.name).change().trigger('input');
                el.trigger('mediapicker:changed', value);
            }

            el.trigger('mediapicker:closed', value);
        };
    });
}