
import React from 'react';
import { ClimateInfo } from './ClimateInfo';
import { Flex, Box } from '../../../react-shared/flexbox';
import Spinner from '../../../react-shared/Spinner';
import { remove } from './ClimateApiClient';

export class ClimateDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteValue = () => {
    if (this.props.isLocked) {
      return;
    }

    remove(this.props.organizationId, this.props.itemId, this.props.refresh);
  };

  onDelete = e => {
    e.preventDefault();
    this.deleteValue();
  };
  getImage = (source) => {
    switch (source) {
      case "RISE":
        return <img
          src='../Images/Climate/Rise Logo.png'
          style={{ height: '100px' }}
        />
        break;
        case "Klimato":
          return <img
          src='../Images/Climate/Klimato Logo.png'
          style={{ height: '50px' }}
        />
        break;
      default:
        break;
    }

   
  }
  render() {
    var columnStyle = {
      fontSize: '1.20em',
      paddingTop: '20px'
    };

    var infoStyle = this.props.isSaving ? { color: '#bfbfbf' } : { color: 'inherit' }

    return (
      <div style={columnStyle}>
        <Flex flexDirection={'row'}>
          <Box flexBasis={'auto'}>
            <div style={infoStyle}>
              <ClimateInfo climateData={this.props.climateData} />
            </div>
          </Box>
          <Box style={{marginLeft: "20px"}} flexBasis={'20%'}>
            {this.getImage(this.props.climateData.Source)}
          </Box>
        </Flex>
        <Flex flexDirection={'row'}>
          <Box flexBasis={'auto'}>
            <button disabled={this.props.isLocked} onClick={this.onDelete} className="mashiebutton1a">
              {Mashie.Resources.GetString('Item.Climate.Remove')}
            </button>
          </Box>
          <Box flexBasis={'auto'} alignSelf={'center'} verticalAlign={'middle'}>
            {this.props.isSaving ? (
              <Spinner size={26} />
            ) : null}
          </Box>
        </Flex>
      </div>
    );
  }
}
