"use strict";

import React from 'react';
import ReactDOM from 'react-dom';
import { Flex, Box } from '../../react-shared/flexbox';
import { ClimateDetails } from './components/ClimateDetails';
import { ClimateSearchBar } from './components/ClimateSearchBar';
import { get, update } from './components/ClimateApiClient';

var organizationId;
var itemId;
var isLocked;
var itemName;
var SLVID;

export function feature_js_climate(el) {
  var $el = $(el);

  organizationId = $el.attr("organizationId");
  itemId = $el.attr("itemId");
  itemName = $el.attr("itemName");
  isLocked = $el.attr("isLocked").toLowerCase() == 'true';
  SLVID = $el.attr("SLVID");

  var element = document.getElementById("climate-edit-scope");
  ReactDOM.render(<Climate />, element);
}

class Climate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isSaving: false
        };
    }

    componentDidMount() {
        this.getSelectedValue();
    }

    getSelectedValue = () => {
        get(
            organizationId,
            itemId,
            (data) => {
                var selected = JSON.parse(data.Json);
                selected.Source = data.Source;
                // #24029 - display the actual data that has been used for climate's calculation
                selected.KgGreenHouseGasPerKg = data.KgPerKg;
                this.setState({
                    selected: selected
                });
            },
            () => {
                this.setState({
                    selected: null
                });
            }
        )
    };

    setSelectedValue = climateData => {
        if (isLocked || climateData === null) {
            return;
        }

        this.setState({ isSaving: true });

        update(
            organizationId,
            itemId,
            climateData,
            () => {
                this.getSelectedValue();
                this.setState({ isSaving: false }); 
            }
        )
    };

    render() {
        return (
            <div style={{ paddingTop: '30px' }}>
                <ClimateSearchBar
                    itemName={itemName}
                    slvId={SLVID}
                    isLocked={isLocked}
                    setSelectedValue={this.setSelectedValue}
                />
            <Flex flexDirection={'row'}>
                <Box flexBasis={'400px'} alignSelf={'flex-end'}>
                {/*  */}
                </Box>
                <Box flexBasis={'35px'} alignSelf={'flex-end'}>
                {/* utfyllnad */}
                </Box> 
                <Box flexBasis={'80%'} alignSelf={'flex-end'}>
                {this.state.selected == null ? null : (
                    <ClimateDetails
                    refresh={this.getSelectedValue}
                    climateData={this.state.selected}
                    isSaving={this.state.isSaving}
                    isLocked={isLocked}
                    organizationId={organizationId}
                    itemId={itemId}
                    />
                )}
                </Box>
            </Flex>
            </div>
        );
    }
}
