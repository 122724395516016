import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import ReactDOM from "react-dom";
// import { ReactComponent as YourSvg } from "./logo-with-text.svg";

export function feature_js_upgrade_page(el: any) {
  var $el = $(el);

  ReactDOM.render(<UpgradePage />, $el[0]);
}

export class UpgradePage extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div style={{ backgroundColor: "rgb(10, 97, 128)" }}>
        <div style={{ display: "grid", justifyContent: "center", marginTop: "40vh", position: "relative" }}>
          <div style={{ fontSize: "2.5em", color: "white", justifySelf: "center" }}>Uppgradering pågår</div>
          <div
            style={{ fontSize: "1.4em", color: "white", justifySelf: "center", width: "500px", textAlign: "center" }}
          >
            när uppgraderingen är färdig kommer du att hänvisas till inloggningen igen.
          </div>
          <div style={{ fontSize: "2.5em", color: "white", justifySelf: "center" }}>Upgrading system</div>

          <div
            style={{ fontSize: "1.4em", color: "white", justifySelf: "center", width: "500px", textAlign: "center" }}
          >
            when the upgrade is complete, you will be redirected back to the login page.
          </div>

          <div style={{ color: "white", justifySelf: "center" }}>
            <CircularProgress
              sx={{ svg: { circle: { strokeWidth: 0.5 } } }}
              style={{ position: "relative", top: "-385px", left: "-4px" }}
              size={600}
            />
          </div>
        </div>
        <img
          style={{ width: "294px", margin: "10px", position: "fixed", bottom: "10px", right: "10px" }}
          src="https://menu.matildaplatform.com/logo-with-text.svg"
        />
      </div>
    );
  }
}
