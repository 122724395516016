import React, { useState } from "react";
import { DietPreference } from "../Domain/MealSettings";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { translate } from "../Translations/labelStrings";

interface Props {
    options: DietPreference[];
    title: string;
    selected: DietPreference | null;
    handleChange: (value: DietPreference | null) => void;
    isLocked: boolean;
}

const DietPreferencesSingleSelect = ({
    selected,
    handleChange,
    isLocked,
    options,
}: Props) => {
    const [selectedItem, setSelectedItem] = useState<string>(
        selected?.id ?? ""
    );

    const onChange = (value: string) => {
        if (value !== "") {
            const chosenPreference = options.find((p) => p.id === value);
            handleChange(chosenPreference ?? null);
        } else {
            handleChange(null);
        }
        setSelectedItem(value);
    };

    return (
        <Box>
            <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, marginTop: 0 }}
            >
                <Select
                    value={selectedItem}
                    displayEmpty
                    onChange={(e) => onChange(e.target.value)}
                    disabled={isLocked}
                >
                    <MenuItem value="">
                        <Typography variant={"h6"} color="text.primary">
                            {translate("None_Selected")}
                        </Typography>
                    </MenuItem>
                    {options.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                            <Typography variant={"h6"} color="text.primary">
                                {opt.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default DietPreferencesSingleSelect;
