import http from 'superagent';

export function feature_recipe_information_delete(el) {
    el.click(function () {
        var url = $('[js-recipelabel]').find('[js-delete-templete]').attr('js-delete-templete');
        var selected = $('[js-recipelabel]').find('.active').closest('.list-group-item');
        var model = {
            ContentId: $('[js-content]').attr('js-contentId'),
            VersionKey: $(selected).attr('data-name'),
            Id: $(selected).attr('data-guid')
        }

        http.post(url).send(model).end(function () {
            http.get($('[js-recipelabel]').find('[js-refresh-templete]').attr('js-refresh-templete')).query({ id: model.ContentId, _: +new Date() }).end(function (result) {
                $('#recipe-template-partial-view').html(result.text);
            });
            clearWiziwig();
            clearAllergens();
        });

        function clearWiziwig() {
            tinymce.get($('[js-recipelabel]').find('[js-wysiwyg]').attr('id')).setContent('');
        }
        function clearAllergens() {
            $('#custom-ingredient-allergens-view .tableAllergens > tbody > tr input:checkbox').each(function () {
                $(this).prop('checked', false);
            });
        }
    });
}