"use strict";

import superagent from 'superagent';
import rivets from 'rivets';

export function feature_js_item_link() {

    var urlDelete = "ItemPage.aspx/RemoveItemLink";
    var search = "ItemPage.aspx/SearchItemLink";
    var urlLink = "ItemPage.aspx/InsertItemLink";

    var scope = {
        load: true,
        _items: {},
        searchhits: '',
        result: true
    }
    var isLocked = $("[id$='LockStateButton']").attr('lockstatus') == 0;

    var isSystemItem = $(".systemItemLabel").length == 1
    if (isLocked || isSystemItem) {
        lockLinkAddButton();
        lockLinkRemoveButton();
    } else {
        unlockLinkAddButton();
        unlockLinkRemoveButton();
    } 

    var link = $("[id$='redirect_linked_Button']").text();
    if (!link) {
        lockLinkRemoveButton();
    }

    $('[btn_item_add_link]').click(function () {
        if (!$('[btn_item_add_link]').hasClass('btn-disabled')) {
            $('[item-link-add-modal]').modal('show');
            $('#item-link-add-input').focus();
        }
    });

    $('[item-link-selected-link]').click(function (el) {
        var found = $('[item-link-selected-link]').find('.item_search_selected_Item');
        if (found.length != 0) {
            $(found).removeClass('item_search_selected_Item');
        }
        $(el.target).closest('tr').addClass('item_search_selected_Item');
    });


    $('[btn_item_remove_link]').click(function () {
        if (!$('[btn_item_remove_link]').attr('disabled')) {
            $('[ item-link-remove-modal]').modal('show');
        }
    });

    $('#item-link-add-input').keydown(function (e) {
        if (e.which == 13) {
            e.preventDefault();
            scope.load = false;
            superagent.post(search).send({
                input: $('#item-link-add-input').val()
            }).end(function (res) {
                scope._items = JSON.parse(res.body.d);
                scope.result = false;
                scope.searchhits = (res.body.d == "null") ? 0 : scope._items.length;
                scope.load = true;
            });
        }
    });

    $('#item-link-search-button').click(function () {
        scope.load = false;
        superagent.post(search).send({
            input: $('#item-link-add-input').val()
        }).end(function (res) {
            scope._items = JSON.parse(res.body.d);
            scope.result = false;
            scope.searchhits = (res.body.d == "null") ? 0 : scope._items.length;
            scope.load = true;
        });
    });

    $('[item-link-remove-btn]').click(function () {
        superagent.post(urlDelete).send({
            id: $("[id$='hfItem']").val()
        }).end(function () {
            $("[id$='hidParentSystem']").val("");
            $("[id$='redirect_linked_Button']").text("");
            $("[id$='txtSlvCode']").val("");
            $("[id$='lblSLV']").hide();
            $("[id$='txtSlvCode']").hide();
            $('[ item-link-remove-modal]').modal('hide');
        });
    });

    $('[item-link-add-btn]').click(function () {
        var found = $('[item-link-selected-link]').find('.item_search_selected_Item');
        if (!found.length) return;
        var item = $(found).find('.search_result_item_left').attr('data-guid');
        superagent.post(urlLink).send({
            linkId: item
        }).end(function (res) {
            var viewModel = JSON.parse(res.body.d);
            $("[id$='txtWeightFactor']").val(viewModel.WeightFactor);
            $("[id$='txtLoss']").val(viewModel.Loss);
            $("[id$='ddlUnitType']").val(viewModel.UnitType);
            $("[id$='txtSlvCode']").val(viewModel.AppId);
            $("[id$='redirect_linked_Button']").text(viewModel.Name);
            $('[item-link-add-modal]').modal('hide');
        });
    });

    function lockLinkRemoveButton() {
        $('[btn_item_remove_link]').attr('disabled', true).addClass('btn-disabled');
    }

    function lockLinkAddButton() {
        $('[btn_item_add_link]').attr('disabled', true).addClass('btn-disabled');
    }

    function unlockLinkRemoveButton() {
        $('[btn_item_remove_link]').attr('disabled', false).removeClass('btn-disabled');
    }

    function unlockLinkAddButton() {
        $('[btn_item_add_link]').attr('disabled', false).removeClass('btn-disabled');
    }

    rivets.bind($('[item-link-add-modal]'), scope);
}