import request from 'superagent';

export function get(onSuccess, onError) {
    http_call({
        url: '/mashie/orderlocks/data',
        onSuccess: onSuccess,
        onError: onError
    });
}

export function post(onSuccess, body, onError) {
    http_post({
        url: '/mashie/orderlocks/data',
        body: body,
        onSuccess: onSuccess,
        onError: onError
    });
}


function http_post({ url, body, onSuccess, onError, onDone }) {
    var currentRequest = request
        .post(url)
        .send(body)
        .set('Accept', 'application/json');

    if (onError) {
        currentRequest = currentRequest.on('error', () => {
            onError();
            if (onDone) {
                onDone();
            }
        });
    }

    currentRequest.then(res => {
        try {
            if (onSuccess) {
                var data = JSON.parse(res.text);
                onSuccess(data);
            }
        } catch (e) {
            if (onError) {
                onError(e);
            }
        } finally {
            if (onDone) {
                onDone();
            }
        }
    });
}


function http_call({ url, onSuccess, onError, onDone }) {
    var currentRequest = request
        .get(url)
        .set('Accept', 'application/json');

    if (onError) {
        currentRequest = currentRequest.on('error', () => {
            onError();
            if (onDone) {
                onDone();
            }
        });
    }

    currentRequest.then(res => {
        try {
            if (onSuccess) {
                var data = JSON.parse(res.text);
                onSuccess(data);
            }
        } catch (e) {
            if (onError) {
                onError(e);
            }
        } finally {
            if (onDone) {
                onDone();
            }
        }
    });
}