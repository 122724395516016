//================================================================//
// Imports
//================================================================//
import React from 'react'
import PropTypes from 'prop-types';
import chartOptions from './BarChartOptions';
import ChartCanvas from './ChartCanvas';
//import generateLegend from './generateLegend';

//================================================================//
// Chart Specific Props
//================================================================//
const chartProps = {
    type: "horizontalBar",
    prefix: "fat",
    title: "Fat_Distribution_Chart_Title"
}

let currentId = 0;

/**
 * Class to represent our horizontal bar chart.  Initiates our chart
 * instance and manages ctx and data opertaions.
 */
class EnergyDistributionBarChart extends React.Component {
    constructor() {
        super();
        this.canvasId = 'EnergyDistributionBarChart-' + currentId++;
    }

    //================================================================//
    // Class Methods
    //================================================================//
    /**
     * Creates a new internal version of our state data set.  We need
     * to recalculate the values in kJ to a percentage of the total 
     * energy in the item.  
     */
    getFatDataInPercent = (data, sum) =>({
        ...data,
        datasets: [
            {
                ...data.datasets[0],
                data: data.datasets[0].data.map(
                    amount => parseFloat(
                        ((amount / sum) * 100).toFixed(1)
                    )
                )
            }
        ]
    });

    /**
     * Returns a generated object to set our chart with.  
     */
    settings = (range, data) => ({
        type: chartProps.type,
        data: data,
        options: chartOptions(range)
    });

    /**
     * We only want our chart to initiate once the dom is in place.  Also we need to recreate the data model
     * a bit with our getFatDataInPercent method to represent the chart properly.  One last note here is that the
     * generateLegend method calculates its values based on the kJ values so because we changed our chart values to 
     * % we need to pass the original data in the format of a returned Chart object.  That is why the fatChart.energy
     * object is nested into the seen below objects. 
     */
    componentDidUpdate() {
        const { sum, range }    = this.props;
        const newData           = this.getFatDataInPercent(this.props.fatChart.energy, sum);
        const ctx               = document.getElementById(this.canvasId).getContext('2d');  
        new Chart(ctx, this.settings(range, newData));
    }

    /**
     * Make the chart visible only on update.
     */
    componentWillUpdate(){
        chartProps.visible = true
    }

    /**
     * Don't update the component if we have no data.  This will force the child canvas 
     * to receive a class as a property that sets the display to none.
     */
    shouldComponentUpdate(nextProps, nextState){
        return nextProps.fatChart.energy.datasets[0].data.length;
    }

    //================================================================//
    // Render Method
    //================================================================//
    render() {
        const legend = {
            chart: {
                config: { data: this.props.fatChart.energy }
            },
            sum: this.props.sum
        };

        return (
            <ChartCanvas 
                className={chartProps.visible ? '' : 'hide'}
                prefix={chartProps.prefix}
                canvasId={this.canvasId}
                title={chartProps.title}
                legendSettings={legend}
            />
        );
    }
}
//================================================================//
// Default Props
//================================================================//
EnergyDistributionBarChart.defaultProps = {
    range: 0
}
//================================================================//
// PropTypes
//================================================================//
EnergyDistributionBarChart.propTypes = {
    fatChart: PropTypes.object.isRequired,
    sum: PropTypes.number.isRequired,
    range: PropTypes.string.isRequired
}
//================================================================//
// Export
//================================================================//
export default EnergyDistributionBarChart;