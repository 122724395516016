"use strict";

export function feature_js_purchase_articlelist() {
    $('.expiredObject').parent('tr').css({
        'background-color': '#d9534f',
        'color': 'white'
    });
    $("[id$='txtAmount_NEW']").parent('td').css('padding', '0');
    $("[id$='lblAmount_NEW']").parent('td').css('padding', '0');

    $('.zero_value').parent('tr').css({
        'background-color': '#f0ad4e',
        'color': 'white'
    });

    $("[id$='txtAmount_NEW']").css('color', 'black');
    $('.expiredObject').parent('tr').find($("[id$='regexTxtAmount']").css('color', '#375382'));

    if ($('.expiredObject').length > 0) {
        $('#purchase_articlelist_expired').show();
    }
    if ($("[id$='chkSeeAllHidden']").attr('checked')) {
        $('#purchase_articlelist_zero').show();
    }
}