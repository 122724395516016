"use strict";

import React from "react";
import ReactDOM from "react-dom";
import { Flex, Box } from "../../react-shared/flexbox";
import NotificationStore from "./Store/notification-store";
import { observer } from "mobx-react";
import { Drawer } from "../../react-shared/Drawer/Drawer";
import { DropdownMenu } from "../../react-shared/DropdownMenu/DropdownMenu";
import { Notification } from "./components/Notification/Notification";
import { Setting } from "./components/Setting/Setting";
import Spinner from "../../react-shared/Spinner";
import { AbnormalState } from "../../react-shared/EmptyState/AbnormalState";

export function feature_js_notifications_drawer(el) {
  var element = document.getElementById("notifications-root");
  $("#notification-icon").on("click", NotificationStore.openDrawer);
  NotificationStore.updateReadCount();
  // NotificationStore.openDrawer(); //open drawer automaticly for development
  ReactDOM.render(<NotificationApp store={NotificationStore} />, element);
}

@observer
class NotificationApp extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  emptyState = () => {
    return (
      <AbnormalState
        headerText={Mashie.Resources.GetString("Notification_EmptyState_Header")}
        message={Mashie.Resources.GetString("Notification_EmptyState_Message")}
      />
    );
  };
  disabledState = () => {
    return (
      <AbnormalState
        headerText={Mashie.Resources.GetString("Notification_DisabledState_Header")}
        message={Mashie.Resources.GetString("Notification_DisabledState_Message")}
        message2={Mashie.Resources.GetString("Notification_DisabledState_Message2")}
      />
    );
  };

  errorState = () => {
    return (
      <AbnormalState
        headerText={Mashie.Resources.GetString("Notification_ErrorState_Header")}
        message={Mashie.Resources.GetString("Notification_ErrorState_Message")}
        message2={Mashie.Resources.GetString("Notification_ErrorState_Message2")}
      />
    );
  };

  render() {
    const header = (
      <Flex className="header" alignItems="center">
        <Box className="header-bell-icon">
          <i style={{ fontSize: "1.5em" }} className="fa fa-bell" aria-hidden="true"></i>
        </Box>
        <Box style={{ flexGrow: "1" }}>
          <Flex>
            <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              {Mashie.Resources.GetString("Notification_Header")}{" "}
              <span title="Antal olästa">({NotificationStore.unReadCount})</span>
            </div>
            <div style={{ margin: "0 15px" }}>{NotificationStore.isLoading ? <Spinner size={20} /> : null}</div>
          </Flex>
        </Box>
        <Box flexBasis="15%">
          <Flex style={{ width: "100%", alignItems: "center" }}>
            <Box style={{ flexGrow: "1" }}>
              <NotificationSettingsMenu />
            </Box>
            <Box className="closeButton">
              <i onClick={NotificationStore.closeDrawer} className="fa fa-times" aria-hidden="true"></i>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );

    let notifications = NotificationStore.currentNotifications.map((p) => {
      return (
        <Notification
          key={p.id}
          id={p.id}
          isRead={p.isRead}
          isCompleted={p.isCompleted}
          type={p.type}
          text={p.text}
          createdBy={p.createdBy}
          createdAt={p.createdAt}
          title={p.title}
          localizedTextValues={p.localizedTextValues}
        />
      );
    });

    if (notifications.length === 0) {
      if (NotificationStore.isNotificationsDisabled) {
        notifications = this.disabledState();
      } else {
        notifications = this.emptyState();
      }
    }

    return (
      <Drawer onClickOutside={NotificationStore.closeDrawer} isOpen={NotificationStore.drawerOpen}>
        {header}
        <div className="divider"></div>
        {NotificationStore.error ? (
          this.errorState()
        ) : (
          <div>
            {notifications}
            <div
              hidden={!NotificationStore.hasMorePages}
              onClick={NotificationStore.loadMoreNotifications}
              className="loadMoreButton"
            >
              {Mashie.Resources.GetString("Notification_Load_More")}
            </div>
          </div>
        )}
      </Drawer>
    );
  }
}

@observer
class NotificationSettingsMenu extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <DropdownMenu>
        <div className="headerMenu">
          <Setting
            checkType="Radio"
            checked={NotificationStore.isNotificationsDisabled}
            onClick={NotificationStore.disableNotifications}
            title={Mashie.Resources.GetString("Notification_Settings_TurnOff_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_TurnOff_Description")}
          />
          <div className="divider"></div>
          <Setting
            checkType="Radio"
            checked={NotificationStore.isAllNotificationsVisible}
            onClick={NotificationStore.showAllNotifications}
            title={Mashie.Resources.GetString("Notification_Settings_ShowAll_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_ShowAll_Description")}
          />
          <div className="divider"></div>
          <Setting
            checkType="Radio"
            checked={NotificationStore.isInformationVisible}
            onClick={NotificationStore.showInformationOnly}
            title={Mashie.Resources.GetString("Notification_Settings_ShowInformation_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_ShowInformation_Description")}
          />
          <div className="divider"></div>
          <Setting
            checkType="Radio"
            checked={NotificationStore.isActionVisible}
            onClick={NotificationStore.showActionsOnly}
            title={Mashie.Resources.GetString("Notification_Settings_ShowActions_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_ShowActions_Description")}
          />
          <div className="divider"></div>
          <Setting
            checkType="checkbox"
            checked={NotificationStore.showOnlyUnRead}
            onClick={NotificationStore.toggleUnRead}
            title={Mashie.Resources.GetString("Notification_Settings_OnlyUnRead_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_OnlyUnRead_Description")}
          />
          <div className="divider"></div>
          <Setting
            showRadio={false}
            onClick={NotificationStore.setAllAsRead}
            title={Mashie.Resources.GetString("Notification_Settings_Set_All_Read_Title")}
            description={Mashie.Resources.GetString("Notification_Settings_Set_All_Read_Description")}
          />
          <div className="divider"></div>
        </div>
      </DropdownMenu>
    );
  }
}
