import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import { DietPreference } from "../Domain/MealSettings";

export interface Props {
    dietPreference: DietPreference;
    chosenDietPreferences: DietPreference[];
    handleChange: (dietPreference: DietPreference, checked: boolean) => void;
    isLocked: boolean;
}

export const PrefCheckbox = ({
    chosenDietPreferences,
    dietPreference,
    handleChange,
    isLocked,
}: Props) => {
    const [checked, setChecked] = useState(
        chosenDietPreferences.some(
            (preference) => preference.id === dietPreference.id
        )
    );

    const onChange = (checked: boolean) => {
        handleChange(dietPreference, checked);
        setChecked(checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    key={dietPreference.id}
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={isLocked}
                />
            }
            label={
                <Typography variant={"h6"} color="text.primary" noWrap>
                    {dietPreference.name}
                </Typography>
            }
        />
    );
};
