import { ReactNode } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface DialogTitleProps {
  id: string;
  close?: () => void;
  children: ReactNode;
  className?: string;
}

export default function DialogHeader({ id, close, children, className }: DialogTitleProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0,
        paddingRight: theme.spacing(1.5),
        height: '30px',
        padding: '5px',
        background: '#375382',
        color: '#FFF'
      }}
      className={className ?? ''}
    >
      <Typography
        id={id}
        variant="h4"
        component="h2"
        sx={{
          marginTop: '2px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {children}
      </Typography>
      {close && (
        <Box
          sx={{
            paddingLeft: theme.spacing(2),
            marginTop: -theme.spacing(0.5),
          }}
        >
          <IconButton aria-label="Stäng dialogen" color="inherit" onClick={close} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
