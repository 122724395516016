"use strict";

import React from "react";
import ReactDOM from "react-dom";
import MashiePage from "../../react-shared/Page/MashiePage";
import { SnackbarProvider } from "notistack";
import Store, { translateTriggerState } from "./IntegraitonplatformStore";
import { observer } from "mobx-react";
import { Tab, Tabs } from "./Tabs";
import Translate from "../../react-shared/Translations";
import { Fade } from "@mui/material";
import { EventLogPage } from "./EventLogPage";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from "@mui/icons-material/Sync";
import { AbnormalState } from "../../react-shared/EmptyState/AbnormalState";
// import format from "string-format";

export function feature_js_integrationplatform() {
  var element = document.getElementById("integration-platform");
  ReactDOM.render(<App store={Store} />, element);
}

@observer
class App extends React.Component<any> {
  async componentDidMount(): Promise<void> {
    await Store.loadConfiguration("configuration");
  }

  render() {
    return (
      <MashiePage pageName={Translate("IntegrationPlatform_Title")}>
        <IntegrationPlatformPageContent />
        <SnackbarProvider style={{ fontSize: "1em" }} maxSnack={3} />
      </MashiePage>
    );
  }
}

export function getEmptyTableContent(colspan: any) {
  return (
    <tr>
      <td className="text-center" colSpan={colspan}>
        {Translate("IntegrationPlatform_NoContent")}
      </td>
    </tr>
  );
}

@observer
class IntegrationPlatformPageContent extends React.Component {
  constructor(props: any) {
    super(props);
  }

  getTriggerTableContent = () => {
    return Store.triggers.map((r) => {
      return (
        <tr key={r.id}>
          <td>{r.name}</td>
          <td>{translateTriggerState(r.status)}</td>
          <td>{r.prevExecutionTime}</td>
          <td>{r.nextExecutionTime}</td>
          <td>
            <button
              onClick={() => Store.toggleTrigger(r)}
              title={
                r.status.toLowerCase() === "normal"
                  ? Translate("IntegrationPlatform_Deactivate")
                  : Translate("IntegrationPlatform_Activate")
              }
            >
              <span className={`fa ${r.status.toLowerCase() === "normal" ? "fa-pause" : "fa-play"}`}></span>
            </button>
          </td>
        </tr>
      );
    });
  };

  refresh = () => {
    Store.loadConfiguration("configuration/" + Store.selectedConfigurationId);
  };

  render() {
    return (
      <div>
        <table style={{ paddingBottom: "15px", width: "300px" }}>
          <tbody>
            <tr>
              <td width="20"></td>
              <td width="130">
                <span className="PageLabelClass">{Translate("IntegrationPlatform_Configuration")}</span>
              </td>
              <td>
                <select
                  className="DropDownListClass"
                  style={{ width: "200px", padding: "4px 0px 4px 0" }}
                  value={Store.selectedConfigurationId}
                  onChange={(e) => Store.handleConfigValueChange(e.target.value)}
                >
                  {Store.configurations.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <LoadingButton
                  sx={{ textTransform: "capitalize", fontSize: "inherit", margin: "0 10px" }}
                  variant="contained"
                  loading={Store.ConfigurationLoadState == "LOADING"}
                  startIcon={<SyncIcon />}
                  loadingPosition="start"
                  onClick={this.refresh}
                  title={Translate("IntegrationPlatform_Refresh")}
                >
                  {Translate("IntegrationPlatform_Refresh")}
                </LoadingButton>
              </td>
            </tr>
          </tbody>
        </table>
        <Fade timeout={1000} in={true}>
          <div>
            <Tabs defaultActiveTabIndex={0}>
              <Tab title={Translate("IntegrationPlatform_ManualRun")}>{<this.Tab1Content />}</Tab>
              <Tab title={Translate("IntegrationPlatform_Triggers")}>{<this.Tab2Content />}</Tab>
              <Tab title={Translate("IntegrationPlatform_Logs")}>
                <EventLogPage />
              </Tab>
            </Tabs>
          </div>
        </Fade>
      </div>
    );
  }

  Tab2Content = observer(() => {
    return (
      <>
        {Store.ConfigurationLoadState == "OK" ? (
          <div style={{ padding: "10px" }}>
            <table className="table table-bordered table-striped separate">
              <thead>
                <tr>
                  <th>{Translate("IntegrationPlatform_Name")}</th>
                  <th>{Translate("IntegrationPlatform_State")}</th>
                  <th>{Translate("IntegrationPlatform_LastExecutionDate")}</th>
                  <th>{Translate("IntegrationPlatform_NextExecutionDate")}</th>
                  <th>{Translate("IntegrationPlatform_ActivateDeactivate")}</th>
                </tr>
              </thead>
              <tbody>{Store.triggers.length > 0 ? this.getTriggerTableContent() : getEmptyTableContent(5)}</tbody>
            </table>
          </div>
        ) : (
          <>
            <AbnormalState
              stateOfPage={Store.ConfigurationLoadState}
              headerText={Store.primaryStateText}
              message={Store.secondaryStateText}
            />
          </>
        )}
      </>
    );
  });

  Tab1Content = observer(() => {
    return (
      <>
        <div style={{ padding: "10px" }}>
          {Translate("IntegrationPlatform_ManualRunInstructions")}
          <br />
          <br />
          <b>{Translate("IntegrationPlatform_From")}:</b>
          <input
            style={{ margin: "0 7px", padding: "2px 8px 2px 10px", border: "1px solid gray", borderRadius: "4px" }}
            type="date"
            value={Store.runFromDate}
            onChange={(e) => Store.handleRunFromDateValueChange(e.target.value)}
          />
          <b>{Translate("IntegrationPlatform_To")}:</b>
          <input
            style={{ margin: "0 7px", padding: "2px 8px 2px 10px", border: "1px solid gray", borderRadius: "4px" }}
            type="date"
            value={Store.runToDate}
            onChange={(e) => Store.handleRunToDateValueChange(e.target.value)}
          />

          <LoadingButton
            size="medium"
            style={{ margin: "0 10px", fontSize: "inherit", textTransform: "capitalize" }}
            disabled={Store.ManualRunTabState == "LOADING" || Store.ConfigurationLoadState != "OK"}
            loading={Store.ManualRunTabState == "LOADING"}
            color="primary"
            endIcon={
              Store.ManualRunTabState == "ERROR" ? (
                <>
                  <ErrorIcon />
                </>
              ) : (
                <>
                  <PlayArrowIcon sx={{ fontSize: "2.3rem" }} />
                </>
              )
            }
            variant="contained"
            loadingPosition="end"
            onClick={Store.manualRun}
            title={Translate("IntegrationPlatform_START")}
          >
            {Translate("IntegrationPlatform_START")}
          </LoadingButton>
        </div>
        <div style={{ padding: "10px" }}>
          <AbnormalState
            stateOfPage={Store.ConfigurationLoadState == "OK" ? Store.ManualRunTabState : Store.ConfigurationLoadState}
            headerText={Store.primaryStateText}
            message={Store.secondaryStateText}
          />
        </div>
      </>
    );
  });
}
