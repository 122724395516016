import { createTheme, Components } from '@mui/material/styles';

const fontSize = 12;

const typography = {
    fontSize,
    h1: {
      fontSize: 31,
    },
    h2: {
      fontSize: 25,
    },
    h3: {
      fontSize: 21,
    },
    h4: {
      fontSize: 15,
    },
    h5: {
      fontSize: 12,
    },
    h6: {
      fontSize: 11,
    },
  };

  const components: Components = {
      MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                fontSize
            }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize,
          },
          shrink: {
            fontSize: 16,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          paragraph: {
            fontSize,
          },
          body1: {
            fontSize,
          },
        },
      },
  };
  
  const theme = createTheme({
    components,
    typography,
  });

  export default theme;
  