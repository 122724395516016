import React from "react";
import browser from 'detect-browser';

interface IGrid{
  rowTemplate: string,
  columnTemplate: string,
  style? : React.CSSProperties,
  className? : string ,
  children? : any
}

export class Grid extends React.Component<IGrid> {
  constructor(props : IGrid) {
    super(props);
  }

  render() {
    const innerStyle = {
      display: browser.name === "ie" ?  "-ms-grid" : "grid",
      msGridRows: this.props.rowTemplate,
      msGridColumns: this.props.columnTemplate,
      gridTemplateRows: this.props.rowTemplate,
      gridTemplateColumns: this.props.columnTemplate,
      
    };

    const style = { ...innerStyle, ...this.props.style };

    return (
      <div style={style} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}


interface IGridCell{
  rowStart: string,
  rowEnd: string,
  columnStart: string,  
  columnEnd: string,
  placeSelf?: string,  
  style? : React.CSSProperties,
  className? : string,
  justifySelf? : string,
  children? : any
}

export class GridCell extends React.Component<IGridCell> {
  constructor(props: IGridCell) {
    super(props);
  }
  render() {
    const innerStyle = {
      justifySelf: this.props.justifySelf ?? "start",
      msGridStart: "start",
      maxWidth: "100%",
      msGridRow: this.props.rowStart,
      msGridColumn: this.props.columnStart,
      gridRowStart: this.props.rowStart,
      gridRowEnd: this.props.rowEnd,
      gridColumnStart: this.props.columnStart,
      gridColumnEnd: this.props.columnEnd,
      placeSelf: this.props.placeSelf,
      msGridColumnAlign: this.props.placeSelf
    };
    const style = { ...innerStyle, ...this.props.style };

    return (
      <div style={style} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
