import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { translate } from "./Translations/labelStrings";
import { useCustomerInvoiceStore } from "./stores/customerInvoiceStore";
import { AxiosError } from "axios";

import { CustomerInvoiceAccount } from "./Domain/CustomerInvoice";
import CustomerInvoiceForm from "./components/CustomerInvoiceForm";

interface Props {
  params: Params;
}

type Params = {
  CustomerId: string;
  IsUnLocked: boolean;
};

export type CustomerInvoiceFormProps = {
  transactionType: string;
  accountType: string;
  account: string;
  minAmount: string;
  maxAmount: string;
  part1: string;
  part2: string;
  part3: string;
  part4: string;
  part5: string;
  part6: string;
  part7: string;
  part8: string;
  part9: string;
  part10: string;
  displayTransactionType: string;
  displayAccountType: string;
};
export enum MODE {
  ADD,
  EDIT,
}
export const feature_js_customer_invoice = (el: string) => {
  var $el = $(el);

  const params: Params = {
    CustomerId: $el.attr("CustomerId")!,
    IsUnLocked: Boolean($el.attr("IsUnLocked")),
  };

  var element = document.getElementById("customer-invoice");

  var element = document.getElementById("customer-invoice");
  ReactDOM.render(
    <div>
      <CustomerInvoice params={params} />
    </div>,
    element
  );
};

export const CustomerInvoice = ({ params }: Props) => {
  const defaultData = {
    customerId: params.CustomerId,
    transactionType: "Meal",
    accountType: "Debit",
    displayTransactionType: translate("TransactionType.Meal"),
    displayAccountType: translate("AccountType.Debit"),
    account: "",
    minAmount: "0",
    maxAmount: "0",
    part1: "",
    part2: "",
    part3: "",
    part4: "",
    part5: "",
    part6: "",
    part7: "",
    part8: "",
    part9: "",
    part10: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [exceptionMessage, setException] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [customerInvoiceAccount, setCustomerInvoiceAccount] =
    useState<CustomerInvoiceAccount>(defaultData);
  const {
    customerInvoiceAccountPartHeaders,
    initializeState,
    removeInvoice,
    customerInvoiceAccounts,
    checkAvailable,
    addInvoice,
    getInvoiceById,
    updateInvoice,
  } = useCustomerInvoiceStore();
  const [mode, setMode] = useState(MODE.ADD);
  const deleteInvoice = (accountType: string, transactionType: string) => {
    if (confirm(translate("CustomerInvoice_ConfirmDelete")))
      removeInvoice(accountType, transactionType);
  };

  const onSubmit = (data: CustomerInvoiceFormProps, mode: number) => {
    if (mode === MODE.ADD) {
      if (checkAvailable(data.accountType, data.transactionType)) {
        addInvoice({ ...data, customerId: params.CustomerId });
        setIsDialogOpen(false);
      } else {
          alert(translate("CustomerInvoice_onSubmitError"));
      }
    }
    if (mode === MODE.EDIT) {
      updateInvoice({ ...data, customerId: params.CustomerId });
      setIsDialogOpen(false);
    }
  };

  const onEdit = (accountType: string, transactionType: string) => {
    const data = getInvoiceById(accountType, transactionType) ?? defaultData;
    setCustomerInvoiceAccount(data);
    setMode(MODE.EDIT);
    setIsDialogOpen(true);
  };
  const onAdd = () => {
    setCustomerInvoiceAccount(defaultData);
    setMode(MODE.ADD);
    setIsDialogOpen(true);
  };
  const onAbort = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const getCustomerInvoices = async () => {
      try {
        await initializeState(params.CustomerId);
        setIsLoading(false);
      } catch (err) {
        setHasError(true);
        setException((err as AxiosError).message);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    getCustomerInvoices();
  }, []);
  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : hasError ? (
        <Alert variant="outlined" severity="error">
          <AlertTitle>
            <Typography style={{ fontSize: 16 }}>
              {translate("Server_Error")}
            </Typography>
          </AlertTitle>
          <Typography style={{ fontSize: 16 }}>{exceptionMessage}</Typography>
        </Alert>
      ) : (
        <>
          {params.IsUnLocked && (
            <img
              style={{ cursor: "pointer" }}
              src="/Mashie/Images/Toolbar/Gradient/New_on.png"
              onClick={() => onAdd()}
            />
          )}
          <CustomerInvoiceForm
            customerInvoiceAccount={customerInvoiceAccount}
            mode={mode}
            isDialogOpen={isDialogOpen}
            onSubmit={onSubmit}
            onAbort={onAbort}
            headers={customerInvoiceAccountPartHeaders}
          />
          <table className="mashie-table mashie-table-hover" id="accounts">
            <thead>
              <tr>
                {params.IsUnLocked && <td></td>}
                <td>{translate("AccountingFor")}</td>
                <td>{translate("AccountType")}</td>
                <td>{translate("MinimumAmount")}</td>
                <td>{translate("MaximumAmount")}</td>
                <td>{translate("Account")}</td>
                {customerInvoiceAccountPartHeaders.map((item, key) => (
                  <td key={key}>{item}</td>
                ))}
                {params.IsUnLocked && <td></td>}
              </tr>
            </thead>
            <tbody>
              {customerInvoiceAccounts.map((item, key) => (
                <tr key={key}>
                  {params.IsUnLocked && (
                    <td>
                      <button
                        type="button"
                        disabled={!params.IsUnLocked}
                        className="mashiebutton-edit"
                        onClick={() =>
                          onEdit(item.accountType, item.transactionType)
                        }
                      ></button>
                    </td>
                  )}
                  <td>{item.displayTransactionType}</td>
                  <td>{item.displayAccountType}</td>
                  <td>{item.minAmount}</td>
                  <td>{item.maxAmount}</td>
                  <td>{item.account}</td>
                  <td>{item.part1}</td>
                  <td>{item.part2}</td>
                  <td>{item.part3}</td>
                  <td>{item.part4}</td>
                  <td>{item.part5}</td>
                  <td>{item.part6}</td>
                  <td>{item.part7}</td>
                  <td>{item.part8}</td>
                  <td>{item.part9}</td>
                  <td>{item.part10}</td>
                  {params.IsUnLocked && (
                    <td>
                      <button
                        type="button"
                        disabled={!params.IsUnLocked}
                        className="mashiebutton-remove"
                        onClick={() =>
                          deleteInvoice(item.accountType, item.transactionType)
                        }
                      ></button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
