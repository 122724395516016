"use strict";

import React from "react";
import ReactDOM from "react-dom";
import MashiePage from "../../react-shared/Page/MashiePage";
import NotificationAdminStore from "./NotificationAdminStore";
import { NotificationUserOverView } from "./NotificationUserOverView";
import { NotificationAdminEditDialog } from "./NotificationAdminEditDialog";

export function feature_js_notifiation_admin() {
  var element = document.getElementById("notification-admin");
  ReactDOM.render(<NotificationAdminPage store={NotificationAdminStore} />, element);
}

class NotificationAdminPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <MashiePage pageName={Mashie.Resources.GetString("NotificationAdminPage_Header")}>
        <NotificationUserOverView />
        <NotificationAdminEditDialog />
      </MashiePage>
    );
  }
}
