import __ from '../../lib/caption';

export function feature_js_table_delete_row(el) {
    el.click(function () {
        var frm = el.closest('form');
        el.closest('tr').remove();
        frm.trigger('dirty');
    });
}
export function feature_js_table_mark_all_as_deleted(el) {
    el.change(function () {
        // Header
        var headerDeleteIcon = el.closest('th').find('[js-table--delete-icon]');
        headerDeleteIcon.toggleClass("fa-undo").toggleClass("fa-times");

        var headerDeleteIconWrapper = el.closest('[js-table--delete-icon-wrapper]');
        headerDeleteIconWrapper.toggleClass("text-success").toggleClass("text-danger");
        headerDeleteIconWrapper.attr('title', (el.is(':checked') ? __('UndoAll') : __('Basket_MarkAllForRemoval')));

        // Rows
        var rows = el.closest('table').find('[js-table--mark-as-deleted-row]');
        rows.each(function () {
            $(this).prop('checked', (el.is(':checked') ? true : false));

            var tdSiblings = $(this).closest('td').siblings();
            tdSiblings.toggleClass('table-cell-is-deleted', el.is(':checked'));

            var rowDeleteIcon = $(this).closest('td').find('[js-table--delete-icon]');
            el.is(':checked') ? rowDeleteIcon.addClass("fa-undo").removeClass("fa-times")
                : rowDeleteIcon.addClass("fa-times").removeClass("fa-undo");

            var rowDeleteIconWrapper = $(this).closest('[js-table--delete-icon-wrapper]');
            el.is(':checked') ? rowDeleteIconWrapper.addClass("text-success").removeClass("text-danger")
                : rowDeleteIconWrapper.addClass("text-danger").removeClass("text-success");
            rowDeleteIconWrapper.attr('title', (el.is(':checked') ? __('Undo') : __('Basket_MarkForRemoval')));
        });
    });
}
export function feature_js_table_mark_as_deleted_row(el) {
    el.change(function () {
        var tdSiblings = el.closest('td').siblings();
        tdSiblings.toggleClass('table-cell-is-deleted', el.is(':checked'));

        var deleteIcon = el.closest('td').find('[js-table--delete-icon]');
        deleteIcon.toggleClass("fa-undo").toggleClass("fa-times");

        var deleteIconWrapper = el.closest('[js-table--delete-icon-wrapper]');
        deleteIconWrapper.toggleClass("text-success").toggleClass("text-danger");
        deleteIconWrapper.attr('title', (el.is(':checked') ? __('Undo') : __('Basket_MarkForRemoval')));
    });
}
