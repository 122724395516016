import http from 'superagent';
import uuid from 'uuid';
import _ from 'lodash';

export function feature_recipe_information_save(el) {
    el.click(function () {
        var modellist = [];
        var container = $('[js-recipelabel]');
        var url = $(container).find('[js-save-templete]').attr('js-save-templete');
        var error = false;

        // If a previous save failed an error message is showing. Hide this error message. 
        $('.recipelabel-label-not-saved').hide();

        var templates = $('.list-group-item');

        // Validate new variant and push to modellist
        if ($('[js-new-input-container]').is(':visible')) {
            if ($('[js-input-new-templete]').val() != '') {
                var checkbox = $('[js-new-recipelabel-checkbox]').prop('checked');
                var input;
                if (checkbox || templates.length == 0) {
                    input = create($('[js-content]').attr('js-contentId'), uuid, true);
                }
                else {
                    input = create($('[js-content]').attr('js-contentId'), uuid, false);
                }
                modellist.push(input);
            }
            else {
                error = true;
            }
        }

        // Validate existing variant names
        if (!error && templates.length) {
            _.forEach(templates, function (n, key) {
                var firstchild = $(n).children()[0];
                var name = $(firstchild).val().trim();
                if (name === '') {
                    error = true;
                }
            });
        }
        if (!error) {
            if (templates.length) {
                _.forEach(templates, function (n, key) {
                    var firstchild = $(n).children()[0];
                    var secondchild = $(n).children()[1];
                    var model = { ContentId: $('[js-content]').attr('js-contentId'), Id: $(n).attr('data-guid'), Name: $(firstchild).val(), Text: $(firstchild).attr('data-text'), Standard: $(secondchild).prop('checked'), Active: $(firstchild).hasClass('active'), Allergens: JSON.parse($(firstchild).attr('data-allergens')) };
                    modellist.push(model);
                });
            }
            if (modellist.length) {
                http.post(url).send(modellist).end(function () {
                    http.get($(container).find('[js-refresh-templete]').attr('js-refresh-templete')).query({ id: $('[js-content]').attr('js-contentId'), _: +new Date() }).end(function (result) {
                        $('#recipe-template-partial-view').html(result.text);
                        $('.recipelabel-label-saved').fadeIn(500).delay(2000).fadeOut(1000);
                    });
                });
            }
        } else {
            $('.recipelabel-label-not-saved').fadeIn(500);
        }
    });

    function create(content, uuid, checkbox) {
        return {
            Standard: checkbox,
            Active: true,
            Id: uuid.v4(),
            ContentId: content,
            Name: $('[js-input-new-templete]').val(),
            Text: tinymce.get($('[js-recipelabel]').find('[js-wysiwyg]').attr('id')).getContent(),
            Allergens: JSON.parse($('[js-input-new-templete]').attr('data-allergens'))
        }
    }
}