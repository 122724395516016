jQuery.feature("js-open-modal",
    function (el, modalName) {

        el.click(function () {

            if (!modalName)  return;
            

            const modal = `${modalName}`;

            $(`#${modal}`).modal("show");
           
        });
    });

jQuery.feature("js-open-modal-no-postback",
    function (el, modalName) {

        el.click(function () {

            if (!modalName) return;


            const modal = `${modalName}`;

            $(`#${modal}`).modal("show");
            return false;
        });
    });