import request from 'superagent';

export function get(onSuccess, onError, type = 'all') {
    http_call({
        url: '/mashie/report/'+type, 
        onSuccess: onSuccess,
        onError: onError
    });
}


function http_call({ url, onSuccess, onError, onDone }) {
    var currentRequest = request
        .get(url)
        .set('Accept', 'application/json');

    if (onError) {
        currentRequest = currentRequest.on('error', () => {
            onError();
            if (onDone) {
                onDone();
            }
        });
    }

    currentRequest.then(res => {
        try {
            if (onSuccess) {
                var data = JSON.parse(res.text);
                onSuccess(data);
            }
        } catch (e) {
            if (onError) {
                onError(e);
            }
        } finally {
            if (onDone) {
                onDone();
            }
        }
    });
}