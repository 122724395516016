"use strict";

import moment from 'moment';
export function feature_js_save_customer_client() {

    var mapped = map($("table[id$='dgMenu'] span[class*=LabelClass]"));
    var dayformated = moment($("[id$='HeaderTextLabel']").attr('js-dateformat')).format('L');

    var match = filterResult(dayformated, mapped);

    if (match.length) {
        var container = $('#gridDiv');
        var element = $("#" + match[0].id + "");
        element.css({ 'background-color': 'palegoldenrod' });
        container.scrollTop(element.offset().top - (container.offset().top + 20) + container.scrollTop());
    }

    function map(el) {
        return el.map(function () {
            return { id: this.id, date: moment($('#' + this.id).text().replace(/[^\d.-]/g, '')).format('L') }
        }).get();
    };

    function filterResult(date, obj) {
        return obj.filter(function (obj) {
            return obj.date == date;
        });
    }
}