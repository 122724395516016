export const FilterTool = {
  filteredOrganizations: (organizations, filters) => {
    const filteredOrganizations = organizations.filter(org => org.name.toUpperCase().indexOf(filters.text.toUpperCase()) > -1);

    if (!filters.region && !filters.district && !filters.type) {
      return filteredOrganizations;
    }

    return filteredOrganizations.filter(org => {
      return (
        (filters.region ? filters.region === org.region : true) &&
        (filters.district ? filters.district === org.district : true) &&
        (filters.type ? filters.type === org.type : true)
      );
    });
  },

  filterStock: (stock, textFilter) => {
    return stock.filter(inv => inv.name.toUpperCase().indexOf(textFilter.toUpperCase()) > -1);
  }
};
