import React from "react";
import { InventoryExportModal } from "../inventoryExportModal";
import { ExportTool } from "../ExportTool";
import Spinner from "../../../../../react-shared/Spinner";

export class StocksAndCodesHighOrderComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    stockModalIsOpen: false,
    codesModalIsOpen: false,
    isSending: false
  };

  willOpenStockModal = () => this.setState({ stockModalIsOpen: true });
  willCloseStockModal = () => this.setState({ stockModalIsOpen: false });
  willOpenCodesModal = () => this.setState({ codesModalIsOpen: true });
  willCloseCodesModal = () => this.setState({ codesModalIsOpen: false });

  onSubmitStock = orgs => {
    this.willCloseStockModal();
    this.onSubmit(this.props.api.exportStock, orgs);
  };

  onSubmitCodes = orgs => {
    this.willCloseCodesModal();
    this.onSubmit(this.props.api.exportCodes, orgs);
  };

  onSubmit = async (update, orgs) => {
    this.setState({ isSending: true });
    const ids = orgs.map(org => org.id);
    await update(ids);
    this.setState({ isSending: false });
  };

  render() {
    return this.state.isSending ? (
      <Spinner />
    ) : (
      <div className="tab__locationandcodes">
        <div>
          <button type="button" onClick={this.willOpenStockModal}>
            {Mashie.Resources.GetString("btn_ExportStock")}
          </button>
        </div>
        <div>
          <button type="button" onClick={this.willOpenCodesModal}>
            {Mashie.Resources.GetString("btn_ExportInventoryCodes")}
          </button>
        </div>
        <InventoryExportModal
          isOpen={this.state.stockModalIsOpen}
          willClose={this.willCloseStockModal}
          title={Mashie.Resources.GetString("btn_ExportStock")}
        >
          <ExportTool api={this.props.api} submitRoute={this.onSubmitStock} />
        </InventoryExportModal>
        <InventoryExportModal
          isOpen={this.state.codesModalIsOpen}
          willClose={this.willCloseCodesModal}
          title={Mashie.Resources.GetString("btn_ExportInventoryCodes")}
        >
          <ExportTool api={this.props.api} submitRoute={this.onSubmitCodes} />
        </InventoryExportModal>
      </div>
    );
  }
}
