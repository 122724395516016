
import React from 'react';


export class Flex extends React.Component{ 
    constructor(props){
        super(props);
    }


    render(){

        
        const innerStyle = {
            display :'flex',
            flexDirection : this.props.direction,
            alignItems : this.props.alignItems
        }

        const style = {...innerStyle, ...this.props.style}

        return(
        <div style={style} className={this.props.className}>
         {this.props.children}
        </div>
        )
    }
}

export class Box extends React.Component{
    constructor(props){
        super(props);
    }
    render(){

        const innerStyle = {
            flexBasis:this.props.flexBasis,
            alignSelf : this.props.alignSelf,
            verticalAlign : this.props.verticalAlign
        }

        const style = {...innerStyle, ...this.props.style}

        return (
        <div style={style} className={this.props.className}>
            {this.props.children}
        </div>
        )
    }
}