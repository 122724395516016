const Animation = {

    expandRow: row =>
        $(row).children('td')
            .find('.recycle-items__row-wrapper')
            .contents()
            .unwrap()
            .children(),

    hideRows: row =>
        Animation.collapseRow(row).
            slideUp(() =>
                $(row).removeClass('recycle-items__row--visible')
                    .find('label')
                    .removeClass('js-table-selectable-visible')),

    showRows: row =>
        Animation.expandRow(row)
            .slideDown(() =>
                $(row).addClass('recycle-items__row--visible')
                    .find('label')
                    .addClass('js-table-selectable-visible'))
            .animate({
                paddingTop: 8,
                paddingBottom: 8
            }),

    collapseAndRemoveRow: row =>
        Animation.collapseRow(row)
            .slideUp(() => $(row.closest('tr'))
                .remove()),

    collapseRow: row =>
        $(row.closest('tr'))
            .children('td, th')
            .animate({
                paddingTop: 0,
                paddingBottom: 0
            })
            .wrapInner('<div class="recycle-items__row-wrapper"/>')
            .children()
}

export default Animation;