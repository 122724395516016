import { computed, observable, action, toJS } from "mobx";
import { bool } from "prop-types";
import request from "superagent";
import { v4 as uuidv4 } from "uuid";

class MealEconomyStore {
  @observable mealOptions = [];
  @observable isLocked = true;
  @observable customerCategoryId;
  @observable customAccountNames = {};
  @observable hardCodedDimensions = [
    "dimension1",
    "dimension2",
    "dimension3",
    "dimension4",
    "dimension5",
    "dimension6",
    "dimension7",
    "dimension8",
    "dimension9",
    "dimension10"
  ];
  @observable accountingList = [];
  @observable selectedDimension = {
    mealOptionId : "",
    account : "",
    type : "DEBIT"
  };
  @observable currentDimensions = [
    ["dimension1", ""],
    ["dimension2", ""],
    ["dimension3", ""],
    ["dimension4", ""],
    ["dimension5", ""],
    ["dimension6", ""],
    ["dimension7", ""],
    ["dimension8", ""],
    ["dimension9", ""],
    ["dimension10", ""],
  ]

  constructor() {

  }

  @computed
  get mealOptionGroupName() {
    if (this.mealOptions.length > 0) {
      var selected = this.mealOptions.filter(p => p.id == this.selectedDimension.mealOptionId);
      if(selected.length > 0){
        return selected[0].group.name;
      }else{
        return this.mealOptions[0].group.name;
      }
    }
  }

  @computed
  get isEditMode() {
    return this.selectedDimension.id != "" && this.selectedDimension.id
  }

  @computed
  get selectedId() {
    return this.selectedDimension.id;
  }

  get availableDimensions() {
    var current = this.currentDimensions.map(p => p[0]);
    var available = this.hardCodedDimensions.filter(p => !current.includes(p));
    this.setSelectedDimension(available[0])
    return available;
  }

  @action
  addAccount = () => {
    if (this.selectedDimension === "" || this.selectedDimension == null) { //inga tomma
      return;
    }

    var current = this.currentDimensions.map(p => p[0]);
    if (current.includes(this.selectedDimension)) { // inga existernade
      return;
    }

    this.currentDimensions.push([this.selectedDimension, ""]);
  };

  @action
  changeAccountType = (value) => {
    this.selectedDimension.type = value;
  };

  @action
  changeMealOption = (id) => {
    this.selectedDimension.mealOptionId = id;
  };

  @action
  changeAccount = (account) => {
    this.selectedDimension.account = account;
  };


  @action
  changeDimensionValue = (key, value) => {

    var dictionary = Object.fromEntries(this.currentDimensions);
    dictionary[key] = value;
    this.currentDimensions = Object.entries(dictionary);
  };

  @action
  new = () => {
    this.selectedDimension.id = "";
  };

  @action
  edit = (id) => {
    this.selectedDimension = {...this.accountingList.filter(p => p.id == id)[0]};
    this.currentDimensions = Object.entries(this.selectedDimension.accountDimensions);
    this.changeMealOption(this.selectedDimension.mealOptionId);
    this.changeAccountType(this.selectedDimension.type);  
  };

  @action 
  removeDimension = (i) => {
    var removed = this.currentDimensions.splice(i, 1);
  };



  @action
  setSelectedDimension = (selected) => {
    this.selectedDimension = selected;
  };


  @action
  save = () => {
    if (this.selectedDimension.id && this.selectedDimension.id !== "") {
      this.updateExisting();
    } else {
      this.createNew();
    }
  };

  @action
  deleteMealAccount = (id) => {
    request
      .post(`/mashie/meal-accounting/${id}/delete`)
      .send({

      })
      .on("error", (err) => { })
      .then(() => {
        this.loadList();
      });
  };

  @action
  updateExisting = () => {
    request
      .post(`/mashie/meal-accounting/${this.selectedDimension.id}`)
      .send({
        mealOptionId: this.selectedDimension.mealOptionId,
        type: this.selectedDimension.type,
        account: this.selectedDimension.account,
        accountDimensions: Object.fromEntries(this.currentDimensions)
      })
      .on("error", (err) => { })
      .then(() => {
        this.loadList();
      });
  };
 
  @action
  createNew = () => {
    request
      .post(`/mashie/meal-accounting`)
      .send({
        mealOptionId: this.selectedDimension.mealOptionId,
        type: this.selectedDimension.type,
        account: this.selectedDimension.account,
        accountDimensions: Object.fromEntries(this.currentDimensions)
      })
      .on("error", (err) => { alert(Mashie.Resources.GetString("could_not_save_accountdimension")) })
      .then((response) => {
        this.loadList();
        // let responseObj = JSON.parse(response.text);
        // this.selectedStoreId = responseObj.id
      });
  };

  @action
  loadList = () => {
    request
      .get(`/mashie/meal-accounting?customerCategoryId=${this.customerCategoryId}&nocache=${uuidv4()}`)
      .on("error", (err) => { })
      .then((response) => {
        let responseObj = JSON.parse(response.text);
        this.accountingList = responseObj.mealAccounts
        this.customAccountNames = responseObj.customAccountNames
      });
  };

  @action
  loadMealOptions = () => {
    request
      .get(`/mashie/meal-option?customerCategoryId=${this.customerCategoryId}&nocache=${uuidv4()}`)
      .on("error", (err) => { })
      .then((response) => {
        let responseObj = JSON.parse(response.text);
        this.mealOptions = responseObj.mealOptions
        this.selectedDimension.mealOptionId = this.mealOptions[0].id
      });
  };

}

export default new MealEconomyStore();

