//================================================================//
// Imports
//================================================================//
import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
//================================================================//
// Utility Component
//================================================================//

/**
 * Abstraction of each legend row.  Helps cleap the file and code cleaner. 
 */

class LegendRow extends React.Component {
    componentDidMount() {
        var canvas = document.getElementById(this.props.id);
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = this.props.color;
        ctx.fillRect(0, 0, 21, 21);
    }

    render() {
        const percentage = ((this.props.amount / this.props.total) * 100).toFixed(2);
        const canvasId = this.props.id;
        return (
            <li>

                <div className="legend-color"><canvas
                    className="canvas-style"
                    width="21"
                    height="21"
                    id={canvasId}>
                </canvas></div>
                <div className="legend-description">
                    <div>{this.props.title}</div>
                    <div className="ratio">{percentage}E%</div>
                </div>
            </li>);
    }
    
}
//================================================================//
// PropTypes
//================================================================//
LegendRow.propTypes = {
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired
}



/**
 * Abstraction of all legend.  Helps cleap the file and code cleaner. 
 */

class Legend extends React.Component {

    render() {
        const chart = this.props.chart;
        const total = this.props.total;
        const canvasId = this.props.canvasId;

        if (!chart.config.data || chart.config.data.datasets.length === 0) {
            return null;
        }

        const dataset = chart.config.data.datasets[0];
        if (dataset.data.length === 0) {
            return null;
        }

        const isOne = dataset.data.length === 1;

        const rows = dataset.data.map((d, i) => {
            return <LegendRow
                id={canvasId + "-" + i}
                color={isOne ? dataset.fillColor : dataset.fillColor[i]}
                title={isOne ? dataset.title : dataset.title[i]}
                total={total}
                amount={d}
                key={i}
            />
        });

        return (
            <ul>
                {rows}
            </ul>
        );
    }

}
//================================================================//
// PropTypes
//================================================================//
Legend.propTypes = {
    canvasId: PropTypes.string.isRequired,
    chart: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired
}
//================================================================//
// Export
//================================================================//
export default Legend;