import React from 'react'

import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';


type SpinnerProps = {
    fillContainer?: boolean;
    size?: number;
    timeout?: number;
};

export default function Spinner({ fillContainer, size, timeout = 0 }: SpinnerProps): JSX.Element {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box
      sx={
        fillContainer
          ? {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }
          : {
              textAlign: 'center',
              verticalAlign: 'middle',
            }
      }
    >
      {show && <CircularProgress size={size} color="success" />}
    </Box>
  );
}
