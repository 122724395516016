"use strict";

import React from "react";
import ReactDOM from "react-dom";
import request from "superagent";
import { Flex, Box } from "../../react-shared/flexbox";
// import { PersonRegistryStore } from './Store/person-registry-store';
import { observer } from "mobx-react";

export function feature_js_systemAlert(el) {
  var $el = $(el);
  var element = document.getElementById("system-alert-root");
  var value = $el.attr("js-system-alert");
  ReactDOM.render(<SystemAlertApp value={value} />, element);
}

@observer
class SystemAlertApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.message };
  }

  componentDidMount() {
    request.get("/mashie/system-alert").then((response) => {
      this.setState({ value: response.text });
    });
  }

  render() {

    var showMessage = !(!this.state.value || this.state.value === "");

    var className = showMessage ? "message" : "nomessage";
    return (
      <div className="alert-bar">
          <div
            className={className}
            dangerouslySetInnerHTML={{ __html: this.state.value }}
          ></div>
      </div>
    );
  }
}
