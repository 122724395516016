jQuery.feature("js-ui-toolbar", function (el) {
    el.find(":button")
        .on("click",
            function (evt) {
                evt.preventDefault();
                el.trigger(Events.Emitted, {action: $(this).data("action")});
            });
});

export const Events = {
    Emitted : "toolbar.button.emitted"
}


//document.addEventListener("DOMContentLoaded",
//    function() {
//        const toolbars = document.querySelectorAll('[data-toolbar] [data-action]');
//        console.log("toolbar" + toolbars);
       
//        toolbars.forEach(button => {
//            const action = button.getAttribute('data-action');
//            const controller = document.querySelector('[data-toolbar]').getAttribute('data-controller');
//            console.log("toolbar button" + button);

//            const url = `.mvc/${controller}/${action}`;

//            button.onclick = function () {
//                    console.log('hey');
//                    $.ajax({
//                        type: 'POST',
//                        dataType: 'json',
//                        cache: false,
//                        url: url,
//                        success: function (data, textStatus, jqXHR) {
//                            //Do Stuff
//                        },
//                        error: function (jqXHR, textStatus, errorThrown) {
//                            //Do Stuff or Nothing
//                        }
//                    });
//                }
//            }
//        );
//    });