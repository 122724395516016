"use strict";

export function feature_js_purchase_itemlist() {
    $('.expiredObject').parent('tr').css({
        'background-color': '#d9534f',
        'color': 'white'
    });
  if ($('.expiredObject').length > 0) {
        $('#purchase_articlelist_expired').show();
    }
    $('#purchase_articlelist_zero').hide();
}