//================================================================//
// Callback Methods
//================================================================//
/**
 * Generates ticks for the horizontal bar chart.
 * @param {*} value 
 * @param {*} index 
 * @param {*} values 
 * @param {*} maxPercent 
 */
const generateTick = (value, index, values, maxPercent) => {
    return `${value.toFixed(1)} E%`
}
/**
 * Wraps the chartjs generateTick callback method so that we can inject
 * a needed value into the callback.  Right now it seems the way the data
 * is structured that we dont need this. But I will leave it in for now
 * in case it needs to be updated in the future.  Remove this method if 
 * down the road it is still dormant. (2017.4.24)
 * @param {*} maxPercent 
 */
const generateTickWithMax = (maxPercent) => (value, index, values) =>{
    return generateTick(value, index, values, maxPercent);
}
//================================================================//
// Settings
//================================================================//
/**
 * Chart settings object.  Follows the chartjs api documentation. 
 * See http://www.chartjs.org/docs for detailed information.
 * @param {*} maxPercent 
 */
const chartOptions = (maxPercent) => ({
    legend: {
        display: false
    },
    tooltips: {
        enabled: false
    },
    scales: {
        xAxes: [{
            display: true,
            gridLines: {
                display: true
            },
            ticks: {
                beginAtZero: true,
                callback: generateTickWithMax(maxPercent)
            }
        }],
        yAxes: [{
            scaleLabel: {
                display: true
            },
            barPercentage: 0.7,
            gridLines: {
                display: false
            },

        }]
    }
});
//================================================================//
// Export
//================================================================//
export default chartOptions;

