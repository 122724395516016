"use strict";
import _ from 'lodash';

export function feature_js_meal_register_change() {

    if ($('[lockstatus]').attr('lockstatus') == "0")
        return;

    var saved = $('[id$="specc_changed"]').val();

    var ingredients = JSON.stringify(_.map($('[id$="dgIngredients"] .ingredientLabel'), function (x) {
        return $(x).text();
    }));

    if (saved != "" && saved.length > 0) {
        compare(saved, ingredients);
    }
    else if (ingredients.length > 0) {
        $('[id$="specc_changed"]').val(ingredients);
    }

    $('.changed-registered').on('change', function () {
        mealModfied();
    });

    function mealModfied() {
        if ($('[lockstatus]').attr('lockstatus') == "1") {
            $('[id$="mealChanged"]').val("1");
        }
    }

    function compare(saved, ingredients) {
        if (saved != "" && saved.length > 0) {

            var modified = validate_ingredients(JSON.parse(saved), JSON.parse(ingredients));

            if (!modified)
                recipeModfied();
        }
    }

    function validate_ingredients(a, b) {
        a = _.sortBy(a);
        b = _.sortBy(b);
        return _.isEqual(a, b);
    }
}