//================================================================//
// Imports
//================================================================//
import React from 'react';
import PropTypes from 'prop-types';
import Legend from './generateLegend'
/**
 * Abstraction of our canvas object.  We set the needed class attributes here for styling. 
 */
const ChartCanvas = ({ prefix, canvasId, title, className, legendSettings }) => (
    <div className={`${prefix}-wrapper canvas-wrapper ${className}`}>
        <h3>{Mashie.Resources.GetString(title)}</h3>
        <canvas
            className="canvas-style"
            width="900"
            height="600"
            id={canvasId}>
        </canvas>
        <div
            id={`${prefix}-legend`}
            className="chart-legend">
            <Legend canvasId={canvasId} chart={legendSettings.chart} total={legendSettings.sum}  />
        </div>
    </div>
);
//================================================================//
// Default Props
//================================================================//
ChartCanvas.defaultProps = {
    className: ""
}
//================================================================//
// PropTypes
//================================================================//
ChartCanvas.propTypes = {
    prefix: PropTypes.string.isRequired,
    canvasId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    legendSettings: PropTypes.object.isRequired
}
//================================================================//
// Export
//================================================================//
export default ChartCanvas;