"use strict";

jQuery(document).on('submit', '[js-lockable][locked=1]', function () { return false; });

export function feature_js_lock(el) {
    var locked = true;
    var button = $(el[0]);
    var lockedVisual = '<i class="fa fa-lock"></i>';
    var unlockedVisual = '<i class="fa fa-unlock"></i>';

    function setLocked(doLock) {
        var elementsToDisable = $("[onLock='disable']");
        var elementsToHide = $("[onLock='hide']");

        if (doLock) {
            button.html(lockedVisual);
            button.attr('locked', '1');
            locked = button.attr('locked') === '1';

            elementsToDisable.css({
                "cursor": "not-allowed"
            });
            elementsToHide.css({
                "display": "none"
            });

            elementsToDisable.attr('disabled', 'disabled');
        } else {
            button.html(unlockedVisual);
            button.attr('locked', '0');
            locked = button.attr('locked') === '1';

            elementsToDisable.css({
                "cursor": "pointer"
            });
            elementsToHide.css({
                "display": "inherit"
            });

            elementsToDisable.removeAttr('disabled');
        }
    }

    setLocked(true);
    button.addClass("EditLock");

    $(button).click(function (event) {
        if (locked) {
            setLocked(false);
        } else {
            setLocked(true);
        }

    });
}
export function feature_js_lockable_lock(el) {
    var setup = true;

    function updateState() {
        var lock = el.closest('[js-lockable]');
        var val = el.is(':checked');
        lock.attr('locked', val ? 1 : 0);
        lock.toggleClass('locked', val);
        lock.trigger('lock-toggle', { locked: val });
    }

    function toggleState() {
        var lock = el.closest('[js-lockable]');
        var wasLocked = '1' === lock.attr('locked');
        if (!wasLocked) {
            el.closest('form').submit();
        } else {
            updateState();
        }
    };

    updateState();
    el.change(toggleState);
}
export function feature_js_lockable_disable(el) {
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    el.on('mouseup click keyup', function (evt) { if (locked) evt.preventDefault(); });
    function toggle(evt) {
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.attr('disabled', locked);
        el.toggleClass('disabled', locked);
    }
}
export function feature_js_lockable_text(el, path) {
    var span = $("<span>").hide();
    el.after(span);
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    function toggle(evt) {
        span.attr('class', el.attr('class')).addClass('disabled');
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.toggle(!locked);
        span.toggle(locked).text(text(el, path) || ' ');
    }
}
export function feature_js_lockable_stars(el, path) {
    var span = $("<span>").hide();
    el.after(span);
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    function toggle(evt) {
        span.attr('class', el.attr('class')).addClass('disabled');
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.toggle(!locked);
        span.toggle(locked).html((text(el, path) || '').replace(/./g, '&bull;'));
    }
}
export function feature_js_lockable_hide(el) {
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    function toggle(evt) {
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.toggle(!locked);
    }
}
export function feature_js_lockable_show(el) {
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    function toggle(evt) {
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.toggle(locked);
    }
}
export function feature_js_lockable_class(el, cls) {
    var locked = false;
    el.closest('[js-lockable]').on('lock-toggle', toggle);
    toggle();

    function toggle(evt) {
        locked = '1' === el.closest('[js-lockable]').attr('locked');
        el.toggleClass(cls, !locked);
    }
}

function text(el, path) {
    var target = path ? el.find(path) : el;
    if (target.is('select')) return (target.children(':selected').text() || '').replace(/(^\s+)|(\s+$)/g, '');
    else if (!target.is(':input')) return (target.text() || '').replace(/(^\s+)|(\s+$)/g, '');
    return target.val();
}