const load = async () =>
  await import(/* webpackChunkName: "globalization" */ './index.features');

jQuery.feature_import(
  'js-globalization-admin',
  load,
  mod => mod.feature_js_globalization_admin
);

jQuery.feature_import(
  'js-globalization-refresh',
  load,
  mod => mod.feature_js_globalization_refresh
);

jQuery.feature_import(
  'js-globalization-tool',
  load,
  mod => mod.feature_js_globalization_tool
);
