import React from "react";

export class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen : false}

  }

  componentDidMount() {}

  closeIfClickOutside = (e) => {
    var wrapper = document.getElementById('downDownComponentWrapper');
    const inside = wrapper.contains(e.target);
    if(!inside){
      this.setState({isOpen : false})  
      document.removeEventListener('mousedown', this.closeIfClickOutside)
    }
  }

  onClick = () => {
    this.setState({isOpen : !this.state.isOpen}) 
  }

  selectOption = () => {
    this.setState({isOpen : false}) 
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if(this.state.isOpen && !prevState.isOpen ){
      document.addEventListener('mousedown', this.closeIfClickOutside)
    }
  }
 
  render() {

    
    var openStyle = this.state.isOpen
      ? {
          display: "block",
        }
      : { display: "none" };

    return (
      <div id="downDownComponentWrapper" className="downDownComponent" >
        <i
          onClick={this.onClick}
          className="fa fa-ellipsis-h dropdown-button"
          aria-hidden="true"
        ></i>
        <div onClick={this.selectOption} style={openStyle} className="dropdown-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
