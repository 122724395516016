import React from "react";
import { SearchDialog } from "./SearchDialog";
import { FilterTool } from "../Filter";

export class SimpleExportTool extends React.Component {
  filterTool;

  constructor(props, context, updater, filterTool = FilterTool) {
    super(props);
    this.filterTool = filterTool;
  }

  state = {
    stock: [],
    textFilter: ""
  };

  async componentDidMount() {
    const stock = await this.props.api.getStock();
    this.setState({ stock });
  }

  searchWillChange = event => {
    this.setState({ textFilter: event.target.value });
  };

  get filteredStock() {
    return this.filterTool.filterStock(this.state.stock, this.state.textFilter);
  }

  render() {
    return (
      <div>
        <SearchDialog
          text={this.state.textFilter}
          results={this.filteredStock}
          searchWillChange={this.searchWillChange}
          onSubmit={this.props.onSubmit}
          willClose={this.props.willClose}
        />
      </div>
    );
  }
}
