import { observable, action } from "mobx";
import { MultiValue } from "react-select";
import request, { SuperAgentStatic } from "superagent";
import { v4 as uuidv4 } from "uuid";


export enum ScopeTypeEnum {
    CUSTOMER,
    KITCHEN
}

export interface IArea{
    Name : string
}

export interface IUserAccessFilter {  
    Area?: string,
    Scopes?: IScope[]
}

export interface IScope {
    Id: string,
    Name: string,
    ScopeType: ScopeTypeEnum
}

export interface IScopes {
    Customers: IScope[]
}

export interface IUser {
    Id: string,
    Username : string,
    RoleName: string,
    RoleNameTranslated: string,
    HasNotifications : boolean,
    Area: string,
    AreaTranslated: string,
    Scopes : string[],
    EntitlementName : string,
    CustomerCategory: string,
    ScopesCombined: string,
}


class NotificationAdminStore {
    
    @observable private UserAccessFilter: IUserAccessFilter[] = [];
    @observable private Areas: string[] = [];
    @observable IsLoadingEditModal : boolean = false;
    @observable IsLoadingUserList : boolean = false;
    @observable private Scopes: IScopes = {
        Customers: []
    }
    @observable private User: IUser = { } as IUser;
    @observable private Users: IUser[] = [];

    @observable public rolesFilter: string = "";
    @observable public hasNotificationsFilter: string = "";
    @observable public customerCategoriesFilter: string = "";
    @observable public searchTermFilter?: string = "";

    

    setRoleFilter = (role:string) : void => {
        this.rolesFilter = role;
    }

    getRoles = (): string[] => {
        let data = this.Users.map(a => a.RoleNameTranslated).filter((value, index, self) => self.indexOf(value) === index).sort();
        return data;
    }

    setHasNotificationFilter = (hasNotifications: string): void => {
        this.hasNotificationsFilter = hasNotifications;
    }

    setCustomerCategoriesFilter = (customerCategory: string): void => {
        this.customerCategoriesFilter = customerCategory;
    }

    getCustomerCategories = (): string[] => {
        let data = this.Users.map(a => a.CustomerCategory).filter((value, index, self) => self.indexOf(value) === index && value.trim() != "" ).sort();
        return data;
    }

    setSearchTermFilter = (searchTerm?: string): void => {
        this.searchTermFilter = searchTerm;
    }

    getUsers = (): IUser[] => {
        let data = this.Users;
        if (this.rolesFilter) {
            data = data.filter(p => p.RoleNameTranslated == this.rolesFilter);
        }
        if (this.hasNotificationsFilter) {
            let hasNotifications: boolean = JSON.parse(this.hasNotificationsFilter);
            data = data.filter(p => p.HasNotifications === hasNotifications);
        }
        if (this.customerCategoriesFilter) {
            data = data.filter(p => p.CustomerCategory == this.customerCategoriesFilter);
        }
        if (this.searchTermFilter && this.searchTermFilter.length > 2) {
            let st = String(this.searchTermFilter).toLowerCase();
            data = data.filter(p => p.RoleNameTranslated.toLowerCase().includes(st) || p.Username.toLowerCase().includes(st) || p.EntitlementName.toLowerCase().includes(st) || p.CustomerCategory.toLowerCase().includes(st) || p.AreaTranslated.toLowerCase().includes(st) || p.ScopesCombined.toLowerCase().includes(st));
        }
        return data;
    }

    loadPage = async () : Promise<void> => {
        this.IsLoadingUserList = true;
        await this.loadAreas();
        await this.loadScopes();
        await this.loadUsers();
    }


    loadEditModal = async () : Promise<void> => {
        await this.loadUserAccessFilter();
    }

    isEditMode = () : boolean => {
        return this.User.Id != undefined 
    }

    getUser = () : IUser => {
        return this.User
    }

    loadUser = (user : IUser) => {
        this.User = user
        this.loadUserAccessFilter();
    }

    unLoadUser = () => {
        this.User = { } as IUser
        this.UserAccessFilter = []
    }

    getAreas = (): IArea[] => {

        var areas = this.UserAccessFilter.map(p => p.Area)

        var result = this.Areas
        .filter(p => !areas.includes(p))
        .map(p => <IArea> {
                Name : p
        } );

        return result;
    }

    getScopes = (area?: string): IScope[] => {
        switch (area) {
            case "INTEGRATION_HEALTHCARE":
            case "INTEGRATION_ECONOMY":
                return this.Scopes.Customers;
        }
        return [];
    }


    getCurrentUserAccessFilter = () => {
        return this.UserAccessFilter;
    }

    @action
    addAccessFilter = () => {
        this.UserAccessFilter.push({
            Area : undefined,
            Scopes : undefined
        } as IUserAccessFilter);

    }

    @action
    editAccessFilter = (index : number, area? : string, scopes? : MultiValue<IScope>) => {

        var selectedFilter = this.UserAccessFilter[index];
        selectedFilter.Area = area
        selectedFilter.Scopes = scopes as IScope[]
    }
    
    @action
    removeAccessFilter = (index : number) => {
        this.UserAccessFilter.splice(index, 1);
    }


    @action
    save = async (): Promise<void> => {

        var body = [...this.UserAccessFilter];
        await request
            .post(`/mashie/notifications/users/${this.User.Id}/userFilter`)
            .send(body)
            .on("error", () => { })
            .then(() => {
            });

        this.loadUsers();
    };

    @action
    private loadScopes = async (): Promise<void | SuperAgentStatic> => {
        const response = await request
            .get(`/mashie/notifications/scopes?nocache=${uuidv4()}`)
            .on("error", () => { });
        let scopes = JSON.parse(response.text) as IScopes;
        this.Scopes = {Customers : scopes.Customers};
    };

    @action
    loadUsers = async () : Promise<IUser[]> => {
        this.IsLoadingUserList = true;
        const response = await request
            .get(`/mashie/notifications/users/access-filter?nocache=${uuidv4()}`)
            .on("error", () => { });
        let data = JSON.parse(response.text) as IUser[];

        data.forEach(user => {
            const hasArea = user.Area !== null && user.Area !== "";
            user.RoleNameTranslated = Mashie.Resources.GetString(`ROL_RoleName_${user.RoleName}`);
            user.AreaTranslated = hasArea ? Mashie.Resources.GetString(`${user.Area}`) : "";
            user.EntitlementName = user.EntitlementName !== null ? user.EntitlementName : ""; 
            user.CustomerCategory = user.CustomerCategory !== null ? user.CustomerCategory : ""; 
            user.ScopesCombined = user.Scopes.join(' ');
        }); 

        this.Users = data;
        this.IsLoadingUserList = false;

     return this.Users;
}

    @action
    private loadUserAccessFilter = async (): Promise<void | SuperAgentStatic> => {
        this.IsLoadingEditModal = true;
        const response = await request
            .get(`/mashie/notifications/users/${this.User.Id}/areas?nocache=${uuidv4()}`)
            .on("error", () => { });
        let data = JSON.parse(response.text) as IUserAccessFilter[];
        this.UserAccessFilter = data.map(p => <IUserAccessFilter> 
            { 
              Area : p.Area,
              Scopes : p.Scopes
            });
        this.IsLoadingEditModal = false;
    };

    @action
    private loadAreas = async (): Promise<void | SuperAgentStatic> => {
        const response = await request
            .get(`/mashie/notifications/areas?nocache=${uuidv4()}`)
            .on("error", () => { });
        let areas = JSON.parse(response.text) as string[];
        this.Areas = areas;
    };

}

export default new NotificationAdminStore();