jQuery.feature_import("js-mail-attachments",
    () => import("./index.features"),
    mod => mod.feature_js_mail_attachments
);

jQuery.feature_import("js-mail-basket-modal",
    () => import("./index.features"),
    mod => mod.feature_js_mail_basket_modal
);

jQuery.feature_import("js-mail-message",
    () => import("./index.features"),
    mod => mod.feature_js_mail_message
);

jQuery.feature_import("js-organization-dropdown",
    () => import("./index.features"),
    mod => mod.feature_js_organization_dropdown
);

jQuery.feature_import("js-recipient-results",
    () => import("./index.features"),
    mod => mod.feature_js_recipient_results
);

jQuery.feature_import("js-recipient-search",
    () => import("./index.features"),
    mod => mod.feature_js_recipient_search
);