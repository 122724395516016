import React, { CSSProperties } from "react";

interface MasihePage {
    locked?: boolean;
    pageName: string;
    children?: any;
    onSaveClick?: () => void;
    OnNewClick?: () => void;
    OnLockClick?: () => void;
}

export default function MashiePage({
    locked,
    pageName,
    children,
    OnLockClick,
    OnNewClick,
    onSaveClick,
}: MasihePage): JSX.Element {
    const buttonVisabiltyStyle = (element: any): CSSProperties => {
        return {
            display: element ? "inherit" : "none",
        };
    };

    return (
        <section id="main" className="main-content">
            <ul className="nav nav-toolbox">
                <li className="nav-toolbox-item">
                    <button
                        style={buttonVisabiltyStyle(OnLockClick)}
                        onClick={OnLockClick}
                        id="lockButton"
                        className="btn btn-link"
                    >
                        {locked ? (
                            <span className="fa fa-lock"></span>
                        ) : (
                            <span className="fa fa-unlock"></span>
                        )}
                    </button>
                </li>
                <li className="nav-toolbox-item">
                    <button
                        style={buttonVisabiltyStyle(OnNewClick)}
                        onClick={OnNewClick}
                        id="newButton"
                        className="btn btn-link"
                        disabled={locked}
                    >
                        <span className="fa fa-file-o"></span>
                    </button>
                </li>
                <li className="nav-toolbox-item">
                    <button
                        style={buttonVisabiltyStyle(onSaveClick)}
                        onClick={onSaveClick}
                        id="saveButton"
                        className="btn btn-link"
                        disabled={locked}
                    >
                        <span className="fa fa-save"></span>
                    </button>
                </li>
            </ul>

            <div id="MashiePage">
                <div className="heading-stripe">
                    <span>{pageName}</span>
                </div>
                <section>{children}</section>
            </div>
        </section>
    );
}
