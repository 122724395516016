// jshint -W106
"use strict";
import _ from 'lodash';

var inited = false, uid = 0;

async function init() {
    inited = true;

    jQuery(document).on('focusin', function (e) {
        // some tinymce issue flips out when you try to focus on input fields in their popups, unless we do this.
        if (jQuery(e.target).closest(".mce-window").length) {
            e.stopImmediatePropagation();
        }
    });

    if (!global.tinymce) {
        var mce = (await import('../../../Scripts/tinymce/tinymce.min.js')).tinymce;
        _.assignIn(global.tinymce, mce);
    }
    global.tinyMCE.suffix = ".min";
    global.tinyMCE.baseURL = jQuery('body').attr('js-basepath') + 'Scripts/tinymce';
    global.tinyMCE.init(options.global);
}

var options = {};
options['global'] = {
    forced_root_block: '', 
    mode: 'none',
    plugins: ['charmap link image code'],
    menubar: false,
    toolbar_items_size: 'small',
    toolbar: 'undo redo | fontsizeselect fontselect | charmap bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink anchor image | code',

    setup: function (ed) {
        ed.on('change', function () {
            ed.save();
            jQuery(ed.targetElm).change().trigger('input');
        });
    }
}
options['basic'] = {
    forced_root_block: '',
    mode: 'none',
    plugins: ['charmap link image code'],
    menubar: false,
    height:"200px",
    toolbar_items_size: 'small',
    toolbar: 'undo redo | fontsizeselect fontselect | charmap bold italic',

    setup: function (ed) {
        ed.on('change', function () {
            ed.save();
            jQuery(ed.targetElm).change().trigger('input');
        });
    }
}

options['advanced'] = {
    mode: 'none',
    plugins: ['charmap link image code'],
    menubar: false,
    toolbar_items_size: 'small',
    toolbar: 'undo redo | fontsizeselect fontselect | charmap bold italic | alignleft aligncenter alignright alignjustify',

    setup: function (ed) {
        ed.on('change', function () {
            ed.save();
            jQuery(ed.targetElm).change().trigger('input');
        });
    }
}


export function feature_js_wysiwyg(el, opts) {
    (async function () {
        if (!inited) await init();
        el.attr('id', el.attr('id') || 'mce_' + (++uid)).attr('readyonly', true);
        new tinymce.Editor(el.attr('id'), options[opts] || options['global'], tinymce.EditorManager).render();
    })().catch(ex => console.error(ex));
}