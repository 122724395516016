import { computed, observable, action } from "mobx";
import request from "superagent";
import { v4 as uuidv4 } from "uuid";

class NotificationStore {
  @observable notifications = [];
  @observable isDrawerOpen = false;
  @observable error = false;
  @observable isLoading = true;
  @observable unReadCount = 0;
  @observable totalPages = 0;
  @observable page = 0;

  @observable showActions = true;
  @observable showInformation = true;
  @observable showOnlyUnRead = false;

  @observable nextUri = undefined;

  @computed
  // @ts-ignore
  get hasMorePages ()  {
    return this.totalPages > this.page;
  }

  @computed 
    // @ts-ignore
  get drawerOpen() {
    return this.isDrawerOpen;
  }

  @computed
    // @ts-ignore
  get currentNotifications() {
    return this.notifications;
  }

  @computed
    // @ts-ignore
  get isAllNotificationsVisible() {
    return this.showActions && this.showInformation;
  }
  @computed
    // @ts-ignore
  get isInformationVisible() {
    return this.showInformation && !this.isAllNotificationsVisible;
  }
  @computed
    // @ts-ignore
  get isActionVisible() {
    return this.showActions && !this.isAllNotificationsVisible;
  }

  @computed
    // @ts-ignore
  get isNotificationsDisabled() {
    return !this.showActions && !this.showInformation;
  }

  @action
  toggleUnRead = () => {
    this.showOnlyUnRead = !this.showOnlyUnRead;
    this.saveSettingsState();
  };

  @action
  disableNotifications = () => {
    this.showInformation = false;
    this.showActions = false;
    this.saveSettingsState();
  };

  @action
  showAllNotifications = () => {
    this.showInformation = true;
    this.showActions = true;
    this.saveSettingsState();
  };

  @action
  showInformationOnly = () => {
    this.showInformation = true;
    this.showActions = false;
    this.saveSettingsState();
  };

  @action
  showActionsOnly = () => {
    this.showInformation = false;
    this.showActions = true;
    this.saveSettingsState();
  };

  @action
  saveSettingsState = () => {
    request
      .post(`/mashie/notifications/settings`)
      .send({
        ShowActions: this.showActions,
        ShowInformation: this.showInformation,
        ShowOnlyUnRead: this.showOnlyUnRead,
      })
      .on("error", () => {})
      .then(() => {
        this.loadNotificationsFirstPage();        
      });
  };

  @action
  loadSettings = () => {
    request
      .get(`/mashie/notifications/settings?nocache=${uuidv4()}`)
      .on("error", () => {})
      .then((response) => {
        let settings = JSON.parse(response.text);
        this.showInformation = settings.showInformation;
        this.showActions = settings.showActions;
        this.showOnlyUnRead = settings.showOnlyUnRead;
        this.loadNotificationsFirstPage();
      });
  };

  @action
  openDrawer = () => {
    this.isDrawerOpen = true;
    this.loadSettings();
  };
  @action
  closeDrawer = () => {
    this.isDrawerOpen = false;
  };

  @action
  setAsRead = (id: any) => {
    request
      .post(`/mashie/notifications/${id}/set-as-read`)
      .on("error", () => {})
      .then(() => {
        // @ts-ignore
        const notification = this.notifications.find((p) => p.id == id) ?? {};
        // @ts-ignore
        notification.isRead = true;
        this.updateReadCount();
      });
  };

  @action
  setAllAsRead = () => {
    request
      .post(`/mashie/notifications/set-all-as-read`)
      .on("error", () => {})
      .then(() => {
        this.loadNotificationsFirstPage();
      });
  };

  @action
  setDone = (id : any, isCompleted : any) => {
    const notification = this.notifications.find((p : any) => p.id == id) ?? {} as any;
    notification.isCompleted = isCompleted;

    this.isLoading = true;
    request
      .post(`/mashie/notifications/${id}/set-done?isCompleted=${isCompleted}`)
      .on("error", () => {})
      .then(() => {
        this.isLoading = false;
      });
  };

  @action
  updateReadCount = () => {
    request
      .get(`/mashie/notifications/count-unread?nocache=${uuidv4()}`)
      .on("error", () => {})
      .then((response) => {
        this.unReadCount = response.text as any;
        if (this.unReadCount > 0) {
          // @ts-ignore
          $("#notification-icon").switchClass("fa-bell-o", "fa-bell");
        } else {
          // @ts-ignore
          $("#notification-icon").switchClass("fa-bell", "fa-bell-o");
        }
      });
  };

  @action 
  loadNotifications = (url : string, isFirstPage : boolean) => {
    this.isLoading = true;
    this.error = false;
    request
      .get(url)
      .on("error", () => {
        this.isLoading = false;
        this.error = true;
        this.notifications = [];
      })
      .then((response) => {
        let notificationsPagedResponse = JSON.parse(response.text);
        this.nextUri = notificationsPagedResponse._links.next.href;
        this.isLoading = false;
        this.totalPages = notificationsPagedResponse.totalPages;
        this.page = notificationsPagedResponse.page;

        if(isFirstPage){
          this.notifications = notificationsPagedResponse.notifications
        }else{
          this.notifications = this.notifications.concat(
            notificationsPagedResponse.notifications
          );
        }

        
      });
  };

  @action
  loadNotificationsFirstPage = () => {
    this.loadNotifications(`/mashie/notifications?size=8&nocache=${uuidv4()}`, true);
    this.updateReadCount();
  };

  @action
  loadMoreNotifications = () => {
    this?.loadNotifications(`${this.nextUri}&nocache=${uuidv4()}`, false);
  };
}

export default new NotificationStore(); 