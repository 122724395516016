import axios from "axios";
import { CustomerCategory } from "../domain/CustomerCategory";
import { CustomerCategoryOrderAppConfig } from "../domain/Configuration";

const BASE_URL = "/mashie/OrderAppConfig";

export const getAllConfigurations =
    async (): Promise<OrgOrderAppConfigurationsResponse> => {
        const url = `${BASE_URL}/get-all`;
        const response = await axios.get<OrgOrderAppConfigurationsResponse>(
            url
        );
        return response.data;
    };

export const updateConfiguraions = async (
    orderAppConfigurations: CustomerCategoryOrderAppConfig[],
    isGeneralInformationVisible: boolean,
    isMealFreeDescriptionVisible: boolean,
    generalInformation: string,
    mealFreeDescription: string
) => {
    const url = `${BASE_URL}/update`;
    const validatedConfigurations = orderAppConfigurations.filter(
        (order) => order.customerCategoryId != ""
    );

    const request = createRequest(
        validatedConfigurations,
        isGeneralInformationVisible,
        isMealFreeDescriptionVisible,
        generalInformation,
        mealFreeDescription
    );
    await axios.post(url, request);
};

export interface OrgOrderAppConfigurationsResponse {
    isGeneralInformationVisible: boolean;
    isMealFreeDescriptionVisible: boolean;
    generalInformation: string;
    mealFreeDescription: string;
    orderAppConfigurations: CustomerCategoryOrderAppConfig[];
    customerCategories: CustomerCategory[];
}

const createRequest = (
    orderAppConfigurations: CustomerCategoryOrderAppConfig[],
    isGeneralInformationVisible: boolean,
    isMealFreeDescriptionVisible: boolean,
    generalInformation: string,
    mealFreeDescription: string
) => {
    return {
        isGeneralInformationVisible: isGeneralInformationVisible,
        isMealFreeDescriptionVisible: isMealFreeDescriptionVisible,
        generalInformation: generalInformation,
        mealFreeDescription: mealFreeDescription,
        CustomerCategoryOrderAppConfigRequests: orderAppConfigurations,
    };
};
