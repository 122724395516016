"use strict";
import http from 'superagent';

var oldEnd = http.Request.prototype.end;
http.Request.prototype.end = function(fn) {
    var self = this;
    return new Promise(function (resolveFn, rejectFn) {
        try {
            oldEnd.call(self, function (err, res) {
                if (res && res.header['mashie-login-required'] === '1') return location.reload();
                if (err) rejectFn(err);
                else if (!res.ok) rejectFn(res);
                else resolveFn(res);

                if (fn) {
                    if (2 == fn.length) return fn(err, res);
                    else if (err) return self.emit('error', err);
                    else fn(res);
                }
            });
        } catch(ex) {
            rejectFn(ex);
        }
    });
};

