"use strict";

export function feature_js_views_administrationorganizationusers_edit(el) {
    var roleEl = el.find('[js-view--role-ddl]');

    function refresh() {
        var role = roleEl.val();
        el.find('[js-view--role]').each(function () {
            var show = $(this).attr('js-view--role').split(',').indexOf(role) > -1;
            $(this).find('input,textarea,select').attr('disabled', !show);
            $(this).toggle(show);
        });
    }

    roleEl.change(refresh);
    refresh();

    const cbmarkall = $("#multipurchaser-container #cb-mark-all");
    const customerCategorySelect = $("#multipurchaser-container #customerCategorySelect");
    const customerListItems = $("#multipurchaser-container .customer-container li");
    const customerCheckboxes = $("#multipurchaser-container .customer-container li input[type='checkbox']");

    $(() => {
        cbmarkall.change(() => {
            setAllVisibleCustomerCheckboxes(cbmarkall.is(":checked"));
        });

        customerCategorySelect.change(() => {
            filterCustomersByCategory();
            setCbMarkall();
        });

        customerCheckboxes.change(function () {
            toggleCheckedClass(this);
            setCbMarkall();
        });
    });

    function setAllVisibleCustomerCheckboxes(value) {
        const categoryId = customerCategorySelect.val();
        $.each(customerListItems, function () {
            const currentLi = $(this);
            const isSelectedCategory = categoryId === "" || currentLi.data("customercategoryid") === categoryId;
            if (isSelectedCategory) {
                const checkbox = currentLi.find("input[type='checkbox']");
                checkbox.prop('checked', value);
                toggleCheckedClass(checkbox);
            }
        });
    }

    function filterCustomersByCategory() {
        const categoryId = customerCategorySelect.val();
        $.each(customerListItems, function () {
            const currentLi = $(this);
            const isSelectedCategory = categoryId === "" || currentLi.data("customercategoryid") === categoryId;
            if (isSelectedCategory) {
                currentLi.show();
            } else {
                currentLi.hide();
            }
        });
    }

    function toggleCheckedClass(checkbox) {
        $(checkbox).parent('label').toggleClass('checked', $(checkbox).is(":checked"));
    }

    function setCbMarkall() {
        const categoryId = customerCategorySelect.val();
        var isAllChecked = true;
        $.each(customerListItems, function () {
            const currentLi = $(this);
            const isSelectedCategory = categoryId === "" || currentLi.data("customercategoryid") === categoryId;
            if (isSelectedCategory && !currentLi.find("input[type='checkbox']").is(":checked")) {
                isAllChecked = false;
                return false;
            }
        });
        cbmarkall.prop('checked', isAllChecked);
    }
}