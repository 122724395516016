import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
    DietGroupedResponse,
    DietPreference,
    MealOption,
} from "../Domain/MealSettings";
import {
    getCachedMealOptions,
    resetCachedStorage,
    setValueToInput,
} from "../Domain/clientDietRequestUtil";
import {
    fetchDietPreferences,
    fetchMealOptions,
} from "../Domain/dietSettingsApi";
import { MealOptionUtils } from "../Domain/mealOptionsUtil";

type MealOptionsState = {
    mealOptions: MealOption[];
    dietPreferencesResponse: DietGroupedResponse[];
};

type MealOptionsActions = {
    initializeState: (clientId: string, categoryId: string) => Promise<void>;
    toggleShow: (mealOption: MealOption) => void;
    toggleToBeOrdered: (mealOption: MealOption) => void;
    toggleFoodFree: (mealOption: MealOption) => void;
    modifyDietPreferences: (
        mealOption: MealOption,
        dietPreferences: DietPreference[]
    ) => void;
    modifyDietPreferencesSetAll: (dietPreferences: DietPreference[]) => void;
    setAllMealOptionShow: (checked: boolean) => void;
    setAllMealOptionTobeOrdered: (checked: boolean) => void;
    setAllMealOptionFoodFree: (checked: boolean) => void;
};

export const useMealOptionsStore = create<
    MealOptionsState & MealOptionsActions
>()(
    persist(
        (set) => ({
            mealOptions: [],
            dietPreferencesResponse: [],
            initializeState: async (clientId: string, categoryId: string) => {
                const clientDietResponse = await fetchMealOptions(
                    clientId,
                    categoryId
                );
                const dietGroupedResponses = await fetchDietPreferences(
                    categoryId
                );
                set((state) => {
                    const initialDietPreferences =
                        (state.dietPreferencesResponse = dietGroupedResponses);

                    let cachedClientId = sessionStorage.getItem("clientId");
                    if (cachedClientId == clientId) {
                        const cachedValues = getCachedMealOptions();
                        return {
                            mealOptions:
                                cachedValues !== null
                                    ? cachedValues
                                    : clientDietResponse.mealOptions,
                            dietPreferencesResponse: initialDietPreferences,
                        };
                    }
                    resetCachedStorage();
                    sessionStorage.setItem("clientId", clientId);

                    return {
                        mealOptions: clientDietResponse.mealOptions,
                        dietPreferencesResponse: initialDietPreferences,
                    };
                });
            },

            toggleShow: (mealOption: MealOption) => {
                set((state) => {
                    var updatedMealOptions: MealOption[];
                    updatedMealOptions = MealOptionUtils.update(
                        state.mealOptions,
                        MealOptionUtils.updateDisabled(
                            mealOption,
                            !mealOption.disabled
                        )
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },

            toggleToBeOrdered: (mealOption: MealOption) => {
                set((state) => {
                    var updatedMealOptions: MealOption[];
                    updatedMealOptions = MealOptionUtils.update(
                        state.mealOptions,
                        MealOptionUtils.updateToBeOrdered(
                            mealOption,
                            !mealOption.toBeOrdered
                        )
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },

            toggleFoodFree: (mealOption: MealOption) => {
                set((state) => {
                    var updatedMealOptions: MealOption[];
                    updatedMealOptions = MealOptionUtils.update(
                        state.mealOptions,
                        MealOptionUtils.updateFoodFree(
                            mealOption,
                            !mealOption.foodFree
                        )
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },

            modifyDietPreferences: (
                mealOption: MealOption,
                dietPreferences: DietPreference[]
            ) => {
                set((state) => {
                    const updatedMealOptions = MealOptionUtils.update(
                        state.mealOptions,
                        MealOptionUtils.updateDietPreferences(
                            mealOption,
                            dietPreferences
                        )
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },

            modifyDietPreferencesSetAll: (
                dietPreferences: DietPreference[]
            ) => {
                set((state) => {
                    const updatedMealOptions = MealOptionUtils.updateAll(
                        state.mealOptions,
                        dietPreferences
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },

            setAllMealOptionShow: (show: boolean) => {
                set((state) => {
                    const updatedMealOptions = state.mealOptions.map((option) =>
                        MealOptionUtils.updateDisabled(option, !show)
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },
            setAllMealOptionTobeOrdered: (checked: boolean) => {
                set((state) => {
                    const updatedMealOptions = state.mealOptions.map((option) =>
                        MealOptionUtils.updateToBeOrdered(option, checked)
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },
            setAllMealOptionFoodFree: (checked: boolean) => {
                set((state) => {
                    const updatedMealOptions = state.mealOptions.map((option) =>
                        MealOptionUtils.updateFoodFree(option, checked)
                    );
                    setValueToInput(updatedMealOptions);
                    return { mealOptions: updatedMealOptions };
                });
            },
        }),
        {
            name: "mealOptions",
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);
