import React from "react";
import { SimpleExportTool } from "../SimpleExportTool";
import { InventoryExportModal } from "../inventoryExportModal";
import { ExportTool } from "../ExportTool";
import Spinner from "../../../../../react-shared/Spinner";

export const ExportStockConfirmDialog = ({ destStocks, destOrgs, onConfirm, onReject }) => {
  if (destStocks.length <= 0 || destOrgs <= 0)
    return (
      <div className={`inventory-export__confirm-dialog`}>
        Välj organisation och lager.
        <div className={`confirm-dialog__footer`}>
          <button type="button" onClick={onReject}>
            Ok
          </button>
        </div>
      </div>
    );

  return (
    <div className={`inventory-export__confirm-dialog`}>
      Är du säker på att du vill exportera lager:
      <ul className={`inventory-export__confirm-dialog--stocks`}>
        {destStocks.map(stock => (
          <li key={stock.id}>{stock.name}</li>
        ))}
      </ul>
      till:
      <ul className={`inventory-export__confirm-dialog--orgs`}>
        {destOrgs.map(org => (
          <li key={org.id}>{org.name}</li>
        ))}
      </ul>
      <div className={`confirm-dialog__footer`}>
        <button type="button" onClick={onConfirm}>
          Ja
        </button>
        <button type="button" onClick={onReject}>
          Avbryt
        </button>
      </div>
    </div>
  );
};

export class InventoryHighOrderComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    stockModalIsOpen: false,
    organizationModalIsOpen: false,
    confirmDialogIsOpen: false,
    isSending: false,
    selected: {
      stock: [],
      organizations: []
    }
  };

  willOpenStockModal = () => this.setState({ stockModalIsOpen: true });
  willCloseStockModal = () => this.setState({ stockModalIsOpen: false });
  willOpenOrganizationModal = () => this.setState({ organizationModalIsOpen: true });
  willCloseOrganizationModal = () => this.setState({ organizationModalIsOpen: false });
  willOpenConfirmModal = () => this.setState({ confirmDialogIsOpen: true });
  willCloseConfirmModal = () => this.setState({ confirmDialogIsOpen: false });

  onChooseOrganization = organizations => {
    this.setState({ selected: { ...this.state.selected, organizations } });
    this.willCloseOrganizationModal();
  };
  onChooseStock = stock => {
    this.setState({ selected: { ...this.state.selected, stock } });
    this.willCloseStockModal();
  };

  onRejectExport = () => {
    this.setState({ selected: { stock: [], organizations: [] }, confirmDialogIsOpen: false });
  };

  onAcceptExport = async () => {
    this.setState({ confirmDialogIsOpen: false });
    this.setState({ isSending: true });
    const stockIds = this.state.selected.stock.map(stock => stock.id);
    const ogrIds = this.state.selected.organizations.map(org => org.id);
    await this.props.api.exportInventory(stockIds, ogrIds);
    this.setState({ isSending: false, selected: { stock: [], organizations: [] } });
  };

  onExportStock = () => {
    this.setState({ confirmDialogIsOpen: true });
  };

  render() {
    return this.state.isSending ? (
      <Spinner />
    ) : (
      <div className="tab__locationandcodes">
        <div className="inventory-export__tab-content">
          <button type="button" onClick={this.willOpenStockModal}>
            Sök Lagerställe
          </button>
          <span className="inventory-export__chosen-values">
            {this.state.selected.stock.map(stock => (
              <span key={stock.id}>{stock.name} </span>
            ))}
          </span>
        </div>
        <div className="inventory-export__tab-content">
          <button type="button" onClick={this.willOpenOrganizationModal}>
            Sök Organisation
          </button>
          <span className="inventory-export__chosen-values">
            {this.state.selected.organizations.map(organization => (
              <span key={organization.id}>{organization.name} </span>
            ))}
          </span>
        </div>
        <button type="button" onClick={this.onExportStock}>
          {Mashie.Resources.GetString("btn_ExportStockArticles")}
        </button>
        <InventoryExportModal isOpen={this.state.stockModalIsOpen} willClose={this.willCloseStockModal} title={"Sök Lagerställe"}>
          <SimpleExportTool api={this.props.api} onSubmit={this.onChooseStock} />
        </InventoryExportModal>
        <InventoryExportModal
          isOpen={this.state.organizationModalIsOpen}
          willClose={this.willCloseOrganizationModal}
          title={"Sök Organisation"}
        >
          <ExportTool api={this.props.api} submitRoute={this.onChooseOrganization} />
        </InventoryExportModal>
        <InventoryExportModal isOpen={this.state.confirmDialogIsOpen} title={`Är du säker?`}>
          <ExportStockConfirmDialog
            destStocks={this.state.selected.stock}
            destOrgs={this.state.selected.organizations}
            onConfirm={this.onAcceptExport}
            onReject={this.onRejectExport}
            willClose={this.willCloseConfirmModal}
          />
        </InventoryExportModal>
      </div>
    );
  }
}
