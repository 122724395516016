import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import DialogHeader from '../../../../../Views/shared/react/DialogHeader';

export const InventoryExportModal = ({ isOpen, willClose, title, children }) => {
  return (
    <Dialog className="inventory-export__modal" open={isOpen} onClose={willClose}>
      <DialogHeader close={willClose}>
        {title}
      </DialogHeader>
      <DialogContent>{React.Children.map(children, child => React.cloneElement(child, { ...child.props, willClose }))}</DialogContent>
    </Dialog>
  );
};
