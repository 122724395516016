type Resources = {
  GetString: (key: string) => string;
};

type Translate = {
  Resources: Resources;
};

const Mashie = (window as any).Mashie as Translate;

var Translate = Mashie.Resources.GetString;

export default Translate;
