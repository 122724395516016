﻿Mashie.Cookie = {};

(function (c) {
    c.GetCookie = function (name) {
        var pos;
        var token = name + "=";
        var tnlen = token.length;
        var cklen = document.cookie.length;
        var i = 0;
        var j;

        while (i < cklen) {
            j = i + tnlen;
            if (document.cookie.substring(i, j) === token) {
                pos = document.cookie.indexOf(";", j);
                if (pos === -1)
                    pos = document.cookie.length;
                return unescape(document.cookie.substring(j, pos));
            }
            i = document.cookie.indexOf(" ", i) + 1;
            if (i === 0) break;
        }

        return null;
    };

    c.SetCookie = function (name, value, days) {
        var today = new Date();
        var expire = new Date();
        if (days === null || days === 0) {
            days = 1;
        }

        expire.setTime(today.getTime() + 3600000 * 24 * days);
        document.cookie = name + "=" + escape(value) + ";expires=" + expire.toGMTString() + ";path=/";
    };

    c.DeleteCookie = function (name) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval = c.GetCookie(name);
        document.cookie = name + "=" + cval + "; expires=" + exp.toGMTString() + ";path=/";
    };
})(window.Mashie.Cookie, jQuery);
