var ConfirmationDialog = function (qustion, header, callback) {
    this.initialized = 0;
    this.qustion = qustion;
    this.header = header;
    this.callback = callback;

    this.inititlialize = function () {

            var yesText = Mashie.Resources.GetString("Yes");
            var noText = Mashie.Resources.GetString("No");

        $('body').append(
            "<div class=\"modal fade confirmationdialog\" id=\"ConfirmationDialog\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"ConfirmationDialogLabel\">" +
            " <!-- ConfirmationDialog Modal -->" +
        "  <div class=\"modal-dialog\" role=\"document\">" +
            "<div class=\"modal-content\">" +
            "<div class=\"modal-header\">" +
            "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">X</span></button>" +
            "<div class=\"modal-title\" id=\"myModalLabel\">" +
            this.header +
            "  </div>" +
            "     </div>" +
            " <div class=\"modal-body\">" +
             this.qustion +
             "<div class=\"buttons row\">" +
             " <div class=\"col-xs-12\">" +
            "<button data-dismiss=\"modal\" id=\"confirmAnswerYesButton\" class=\"mashiebutton1\" >"+yesText+"</button>" +
             "<button data-dismiss=\"modal\" class=\"mashiebutton1\">"+noText+"</button>" +
            " </div>" +
          "  </div>" +
             " </div>" +
         
            "     </div>" +
            "  </div>"
            +
            " <!-- ConfirmationDialog Modal -->" +
            "</div>");
        this.initialized = 1;

        $('#confirmAnswerYesButton').click(callback);
    },

    this.show = function () {
        if (this.initialized === 0) {
            console.log("Initializing confirmation dialog...");
            $(".modal#ConfirmationDialog").remove();

            this.inititlialize(this.qustion, this.callback);

        }
        $('#ConfirmationDialog').modal({ show: 'true' });
    }

   

};

export default ConfirmationDialog;