"use strict";

export function feature_js_table_sortable(el) {
    el.closest('form').on('submit', reorder);

    el.find("> tbody").sortable({
        handle: '[js-table--sortable-grip]',
        axis: 'y',
        containment: el.parent(),
        forcePlaceholderSize: true,
        helper: function(evt, tr) {
            var $children = tr.children();
            var $helper = tr.clone();
            $helper.children().each(function(idx) {
                $(this).width($children.eq(idx).width());
            });
            $helper.width('100%');
            return $helper;
        },
        stop: reorder
    });

    function reorder() {
        el.find('[js-table--sortable-ordinal]').each(function (i) {
            $(this).val(i).change();
        });
    }
}