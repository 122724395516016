import _ from 'lodash';
import http from 'superagent';
import caption from '../../lib/caption';

export function feature_js_loginpage(el) {
    var defaultProviderName = "MashieDefaultLogin", activeOrg = "";
    var url = el.attr('js-loginpage-provider');
    if (url != null) registerOrganizationChangeEvents();
    registerAutoSubmit();

    function registerAutoSubmit() {
        if (el.is('[js-loginpage-autosubmittable]')) {
            jQuery(function () { el.find('[js-loginpage-autosubmit]').click(); });
        }
    }

    function registerOrganizationChangeEvents() {
        var org = el.find('[js-loginpage-provider--organization]');
        var ddl = el.find('[js-loginpage-provider--select]');
        var providers = el.find('[js-loginpage-provider--name]');
        var defaultProvider = providers.filter('[js-loginpage-provider--name=' + defaultProviderName + ']');
        var loginMethods = {};

        var changeOrganization = _.debounce(function() {
            http.get(url)
                .query({ organization: activeOrg })
                .end()
                .then(function(res) {
                    loginMethods = res.body;
                    $(_.map(loginMethods, function(v) {
                        return $("<option>").text(caption("Login_Provider_" + v.Provider)).val(v.Id)[0];
                    })).appendTo(ddl);
                    ddl.val(localStorage[ddl.attr('js-store-field')]);
                    if (!ddl.val()) ddl.val($(ddl.children()[0]).val());
                    ddl.change();
                    if (ddl.children().length > 1) ddl.closest('.form-group').removeClass('hidden');
                });
        }, 500);

        defaultProvider.removeClass('hidden');

        ddl.change(function () {
            var method = _.find(loginMethods, { Id: ddl.val() }) || { Provider: defaultProviderName };
            providers.addClass('hidden').find('input,select,textarea');
            $(_.find(providers, function (p) { return $(p).attr('js-loginpage-provider--name') == method.Provider })).removeClass('hidden');
            if (providers.filter(':not(.hidden)').length === 0) defaultProvider.removeClass('hidden');
        }).change();

        ddl.closest('form').submit(function () {
            providers.filter('.hidden').find('input,select,textarea').attr('disabled', true);
        });

        org.bind('change keyup', function () {
            if (activeOrg == org.val()) return;
            ddl.empty().closest('.form-group').addClass('hidden');
            activeOrg = org.val();
            changeOrganization();
        }).change();
    }
}