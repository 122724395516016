
export const Events = {
    Selected: "Selectable.Table.Rows.Select",
    Deselected: "Selectable.Table.Rows.Deselect"
}

/**
 * Feature expects checkbox inputs to exist in a table column.
 * The feature expects the following heirarchy of DOM elements:
 * 
 * |---<tr>
 * |----|- <td>  
 * |----|---|- <label>
 * |----|---|--|- <input />
 * |----|---|- </label>
 * |----|-</td>
 * |---</tr>
 * 
 * This feature enforces that the entire row be a clickable area tied to the checkbox
 * and does not allow multiple checkboxes in the same row.  All clicks to a checkbox add
 * a class to the parent label class and expect that each input connected to this feature
 * have an attribute of 'js-table-selectable-rows--selectable' and that the header checbox
 * used to select all checkboxes have an attribute of 'js-table-selectable-rows--select-all'.
 * 
 * The feature also offers an export of two events to notify when at least one of the checkboxes
 * has been selected or when no checkboxes are no longer selected. To listen to this event
 * you will need a reference to the featured Table object and listen for the events with jQuery:
 * 
 * var SelectableEvents = require('..pathToFeatureFile').Events
 * 
 * referenceToMyTable.on(SelectableEvents.Selected, function(){
 *  // do something
 * });
 * 
 * All checkboxes are display none by default as it is expected that the featured table 
 * will implement its own display to signify a checked box.  You can otherwise override
 * this by setting a display: block !important on the checkbox element. 
 * 
 */

export function feature_js_table_selectable (table) {
    const SELECTABLE_VISIBLE = 'js-table-selectable-visible';
    const SELECTABLE_SELECTED = 'js-table-selectable--selected'
    const tableHeader = table.find('thead');
    const selectAll = tableHeader.find('input[js-table-selectable--select-all]');
    const tableBody = table.find('tbody');
    const selectables = tableBody.find('tr');
    const selectedRows = [];

    const TableHeaderShouldUpdate = () => {
        if (selectedRows.length === selectables.length) {

            selectAll.prop('checked', true);
            selectAll.parent().removeClass(SELECTABLE_SELECTED);
            selectAll.parent().addClass(SELECTABLE_SELECTED);
            return;
        }
        selectAll.parent().removeClass(SELECTABLE_SELECTED);
        selectAll.prop('checked', false);
    }

    const getInput = row => row.find('input[js-table-selectable-rows--selectable]');
    const RowIsVisible = input => input.closest('label').hasClass(SELECTABLE_VISIBLE);

    const RowDidUpdate = (input, row) => {
        input.prop('checked') ? selectedRows.push(row) : selectedRows.pop(row);
        selectedRows.length > 0 ? table.trigger(Events.Selected) : table.trigger(Events.Deselected);
    }

    const ClassWillUpdate = input => input
        .parent()
        .toggleClass(SELECTABLE_SELECTED);


    const RowWillUpdate = row => {
        const input = getInput(row);
        if (!input) return false;
        input.prop('checked', !input.prop('checked'));
    }

    selectables.on('click', event => {
        event.preventDefault();
        const row = $(event.currentTarget);
        const input = getInput(row);
        if (RowIsVisible(input)) {
            RowWillUpdate(row);
            ClassWillUpdate(input);
            RowDidUpdate(input, row);
            TableHeaderShouldUpdate();
        }
    });


    selectAll.on('click', () => {
        ClassWillUpdate(selectAll);
        selectedRows.length = 0;
        selectables.each((i, row) => {
            const input = getInput($(row))
            if (RowIsVisible(input)) {
                input.prop('checked', selectAll.prop('checked'));
                RowDidUpdate(selectAll, row);
                if (selectAll.prop('checked')) {
                    input.parent().removeClass(SELECTABLE_SELECTED);
                    input.parent().addClass(SELECTABLE_SELECTED);
                }
                else {
                    input.parent().removeClass(SELECTABLE_SELECTED);
                }
            }
        });
    });

};