jQuery.feature('js-menupage', function (el) {

    $(document).ready(function () {
        $("[id$='dialogCopyPortions']").dialog({ autoOpen: false });

        $("[id$='btnCopySelection']").button();

        $("[id$='dialog-confirm']").dialog({ autoOpen: false });

        $("[id$='dialogMealFree']").dialog({ autoOpen: false });
        $("[id$='cbMealFree']").click(function () {
            if ($(this).prop('checked'))
                $("[id$='dialogMealFree']").dialog('open');
        });

        $("[id$='DuplicateHierarchyBarButton']").click(function (e) {
            e.preventDefault();
            if ($("[name$='OrganizationSearch_dialog']").length === 0) {
                $("[id$='orgViewOrgSearchPlaceHolder']").load("../Controls/Windows/OrganizationSearchDialog.aspx?date=" + escape(new Date().valueOf()) + " [name=OrganizationSearch_dialog]", DialogLoadCallback);
            }
            else {
                var orgSearch = new Mashie.OrganizationSearch();
                orgSearch.DialogOpen();
            }
        });

        $("[id$='toolBarControl_DeleteHierarchyBarButton']").click(function (e) {
            e.preventDefault();
            Mashie.Popup.showLoadingPopup();
            var currentMenuId = $("[name$='SearchControl1$ResultList'] option:selected").val();
            if (typeof (currentMenuId) === "undefined") {
                currentMenuId = '00000000-0000-0000-0000-000000000000';
            }
            Avensia.Mashie.WebServices.CommandInvoker.DeleteMenuInHierarchy(currentMenuId, function () {
                Mashie.Popup.disableLoadingPopup(); alert(Mashie.Resources.GetString("MenuMainPageJS_RemovedMenuInfo"));
            });
        });

        var DialogLoadCallback = function () {
            var orgSearch = new Mashie.OrganizationSearch();

            orgSearch.Init(true, $('.hdCurrentMenuId').val());
            $(".ui-dialog-title").html(Mashie.Resources.GetString("MenuMainPageJS_CopyReplaceMenu"));
            $("[id$='trUpdateLocktimeSettingSelection']").css('display', '');
            orgSearch.OnSelect = OrganizationSearchOnSelect;
            orgSearch.DialogOpen();
        };

        var OrganizationSearchOnSelect = function (value, text) {
            Mashie.Popup.showLoadingPopup();

            var type = '';
            var menuIdList = [];
            var updateLockTimeSetting = $("[name='OrganizationSearch_dialog_UpdateLockTimeSetting']").is(":checked");

            var selectedRadioBtnVal = $("[name='OrganizationSearch_dialog'] input:radio[name='choices']:checked").val();
            switch (selectedRadioBtnVal) {
                case 'current':
                    var currentMenuId = $("[name$='SearchControl1$ResultList'] option:selected").val();
                    if (typeof (currentMenuId) === "undefined") {
                        currentMenuId = '00000000-0000-0000-0000-000000000000';
                    }
                    menuIdList.push(currentMenuId);
                    type = 'current';
                    break;

                case 'selection':
                    var selectionMenuIds = $("[name$='SearchControl1$ResultList'] option");
                    if (selectionMenuIds.length <= 0) {
                        menuIdList.push('00000000-0000-0000-0000-000000000000');
                    }
                    else {
                        selectionMenuIds.each(function () {
                            menuIdList.push($(this).val());
                        });
                    }
                    type = 'selection';
                    break;

                case 'all':
                    type = 'all';
                    break;

                default:
            }

            Avensia.Mashie.WebServices.CommandInvoker.CopyAndReplaceMenu(value, menuIdList, type, updateLockTimeSetting, function () {
                Mashie.Popup.disableLoadingPopup(); alert(Mashie.Resources.GetString("MenuMainPageJS_CopyMenuInfo"));
            });
        };
    });

    // ---- FR�N SIDAN INLINE
    (function () {
        Date.prototype.toYMD = Date_toYMD;
        function Date_toYMD() {

            var year, month, day;
            year = String(this.getFullYear());
            month = String(this.getMonth() + 1);
            if (month.length == 1) {
                month = "0" + month;
            }
            day = String(this.getDate());
            if (day.length == 1) {
                day = "0" + day;
            }
            return year + "-" + month + "-" + day;
        }
    })();

    WindowType = 'MenuMainPage';
    var ImageGallerStartCategory = 'e49920cf-bcca-4ca6-a4c5-ece9a84da6a8';

    var currentStartDate;
    var currentEndDate;
    $(function () {
        currentStartDate = $("[id$='dcStartDate']").val();
        currentEndDate = $("[id$='dcEndDate']").val();

        if (newMenuName != $("[id$='infoNameTextBox']").val()) {
            // IE 11: change-event won't trigger
            $("[id$='dcEndDate']").focusout(function () {
                $("[id$='dcEndDate']").change();
            });

            $("[id$='dcEndDate']").change(function () {
                if (ConfirmIfDateChanged() && moment.utc(currentEndDate, 'L', true).isValid()) {
                    var result = confirm(Mashie.Resources.GetString("MenuMainPageJS_ConfirmDateChange"));

                    if (result) {

                        currentEndDate = $("[id$='dcEndDate']").val();
                    }
                    else {
                        $("[id$='dcEndDate']").data('datepicker').setDate(currentEndDate);
                        $("[id$='dcEndDate']").val(currentEndDate);
                    }
                }
            });

            // IE 11: change-event won't trigger
            $("[id$='dcStartDate']").focusout(function () {
                $("[id$='dcStartDate']").change();
            });

            $("[id$='dcStartDate']").change(function () {
                if (moment.utc(currentStartDate, 'L', true).isValid()) {
                    changedStartDate();
                }
            });
        }
    });

    function changedStartDate() {
        var newStart = new Date(moment.utc($("[id$='dcStartDate']").val(), 'L'));
        var currentStart = new Date(moment.utc(currentStartDate, 'L'));

        var days = daysBetweenDates(currentStart, newStart);
        var diff = days % 7;

        if (diff === 0) {
            changeEndDate();
        }
        else {
            alert(Mashie.Resources.GetString("MenuMainPageJS_ChangeStartDateInfo"));
            $("[id$='dcStartDate']").data('datepicker').setDate(currentStart);
            $("[id$='dcStartDate']").val(moment(currentStart).format('L'));
        }
    }

    function changeEndDate() {
        var newStart = new Date(moment.utc($("[id$='dcStartDate']").val(), 'L'));
        var currentStart = new Date(moment.utc(currentStartDate, 'L'));
        var currentEnd = new Date(moment.utc(currentEndDate, 'L'));
        var newEndDate = new Date();

        var days = daysBetweenDates(currentStart, currentEnd);
        newEndDate.setTime(newStart.getTime() + days * 86400000);

        currentStartDate = moment(newStart).format('L');
        currentEndDate = moment(newEndDate).format('L');
        $("[id$='dcEndDate']").data('datepicker').setDate(currentEndDate);
        $("[id$='dcEndDate']").val(currentEndDate);
    }

    function parseDate(input) {
        var parts = input.split('-');
        return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    }

    function daysBetweenDates(dateOne, dateTwo) {
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(Math.abs((dateOne.getTime() - dateTwo.getTime()) / (oneDay)));
    }

    function ConfirmIfDateChanged() {
        var newEndDate = $("[id$='dcEndDate']").val();

        if (moment(newEndDate).format('YYYY-MM-DD') !== moment(currentEndDate).format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    $(function () {
        $("[id$='dialog']:ui-dialog").dialog("destroy");

        $("[id$='dialog-confirm']").dialog({
            resizable: false,
            minHeight: 300,
            width: 500,
            modal: true
        });

    });

});

window.globalShowCopyDialog = function () {
    $("[id$='dialogCopyPortions']").dialog('open');
    $("[id$='dialogCopyPortions']").parent().appendTo($('form:first'));
    return false;
}

var menupage = window.menupage || {};

(function (menupage) {

    menupage.mealFreeCancel = function () {
        $("[id$='dialogMealFree']").dialog('close');
        $("[id$='cbMealFree']").prop('checked', false);
    }

    menupage.mealFreeOk = function () {
        $("[id$='dialogMealFree']").dialog('close');
    }

    var eventargument = 'savebutton';
    menupage.showConfirmDialog = function (event) {
        eventargument = event;
        if ($("[id$='cbPublished']").prop("checked")) {
            switchRichText(false);
            ConfirmBeforeSave();
        } else {
            if (event == 'categoryDropdown') saveMenu();
            return true;
        }

        return false;
    }

    function saveMenu() {
        __doPostBack('manualSave', eventargument);
    }

    function ConfirmBeforeSave() {
        var conflictsExist = false;

        var ccId = $("[id$='infoCustomerCategoryDropDownList'] option:selected").val() ? $("[id$='infoCustomerCategoryDropDownList'] option:selected").val() : '00000000-0000-0000-0000-000000000000'
        var parameter =
        {
            '_menuID': $("[id$='hidId']").val(),
            '_dateFrom': $("[id$='dcStartDate']").val(),
            '_dateTo': $("[id$='dcEndDate']").val(),
            '_customerCategoryID': ccId
        };

        $.ajax({
            type: "post",
            url: "/Mashie/WebForms/MenuMainPage.aspx/ConfirmBeforeSave",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(parameter),
            success: function (conflictList) {
                if (conflictList.d) {
                    var conflicts = $.map(conflictList.d, function (saveConflict) {
                        return {
                            conflictType: saveConflict.conflictType,
                            conflictMessage: saveConflict.conflictMessage,
                            conflictSeverity: saveConflict.conflictSeverity,
                            conflictMenuId: saveConflict.conflictMenuId,
                            conflictDayMenuId: saveConflict.conflictDayMenuId
                        }
                    });
                    if (conflicts.length > 0) {
                        switchRichText(true);
                        changeDialog(conflicts);
                        $("[id$='dialog-confirm']").dialog('open');
                    }
                }
                else {
                    conflictsExist = false;
                    saveMenu();
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                conflictsExist = false;
                alert(Mashie.Resources.GetString("NetworkProblemMessage"));
            }
        });
        return conflictsExist;
    }

    function changeDialog(conflictlist) {
        var critical = false;
        $("[id$='dialog-rows']").empty();
        if (conflictlist.length > 0) {
            var str = "";
            var cls = "";
            var style = "";
            for (i = 0; i < conflictlist.length; i++) {
                switch (conflictlist[i].conflictSeverity) {
                    case 5:
                        cls = 'ui-icon ui-icon-close';
                        style = 'color: red';
                        critical = true;
                        break;
                    default:
                        style = 'color: black';
                        cls = 'ui-icon ui-icon-notice';
                }
                str += "<tr style='" + style + "'><td valign='top'><span><span class='" + cls + "'></span></td><td valign='top' align='left'>" + conflictlist[i].conflictMessage + "</td></tr>";
            }
            $("[id$='dialog-rows']").append(str);
        }

        var buttonDefsMenuMainPage = {};
        if (critical) {
            buttonDefsMenuMainPage[Mashie.Resources.GetString("btn_Cancel")] = function () {
                $(this).dialog("close");
            };

            $("[id$='dialog-confirm']").dialog({
                buttons: buttonDefsMenuMainPage
            });
        }
        else {
            buttonDefsMenuMainPage[Mashie.Resources.GetString("MenuMainPage_ActivateMenu")] = function () {
                $(this).dialog("close");
                saveMenu();
            };
            buttonDefsMenuMainPage[Mashie.Resources.GetString("btn_Cancel")] = function () {
                $(this).dialog("close");
            };
            $("[id$='dialog-confirm']").dialog({
                buttons: buttonDefsMenuMainPage
            });
        }
    }
})(menupage || {});

window.menupage = menupage;
