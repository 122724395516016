import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import http from 'superagent';
import chart from 'chart.js';

export default class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
        this.reRender = this.reRender.bind(this);
        this.state = {
            factor: 1,
            rdi: {
                labels: [],
                datasets: []
            }
        }
    }
    reRender() {
        var canvas = document.getElementById("daymenuspecificationnutrients");
        var ctx = canvas.getContext('2d');
        canvas.height = this.state.rdi.labels.length * 10;
        var rdi = this.state.rdi;
        var el = canvas.parentNode;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var addBarDataPointValue = function () {
            var chartInstance = this.chart;
            var ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                    ctx.fillStyle = "#000";
                    ctx.fillText(dataset.data[index] + " %", bar._model.x + 23, bar._model.y - 5);
                }), this)
            }), this);
        };

        var chartOptions = {
            legend: {
                display: false
            },
            responsive: false,
            hover: {
                mode: 'single'
            },
            title: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            animation: {
                onComplete: addBarDataPointValue
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        fontStyle: "bold",
                        fontSize: 12
                    },
                    ticks: {
                        fontSize: 12
                    },
                    stacked: true
                }],
                xAxes: [{
                    gridLines: {

                    }
                }]
            },

            // yAxisFontStyle: 'bold',
            // yAxisFontSize: 44,
            // yAxisUnitFontSize: 44,
            // xAxisUnitFontSize: 44,
            // xAxisFontSize: 44,
            // inGraphDataShow: true,
            // inGraphDataTmpl: "<%=v3%>%",
            // inGraphDataXPosition: 3,
            // inGraphDataYPosition: 2,
            // xAxisBottom: false
        };

        var render = _.debounce(function () {

            canvas.width = Math.max(50, el.offsetWidth);
            canvas.height = rdi.labels.length * 40;

            var barChart = new Chart(ctx, {
                type: 'horizontalBar',
                data: rdi,
                options: chartOptions
            });

        }, 350);

        render();

    }
    componentDidMount() {
        if (this.props.firstTime) {
            this.update(this.props.daymenuId, this.props.portionTypeId);
        }
        this.reRender();

        jQuery(window).on('resize', this.reRender);

    }
    componentWillReceiveProps(nextProps) {
        var propsValid = typeof nextProps.daymenuId != 'undefined' && typeof nextProps.portionTypeId != 'undefined'
        if (propsValid) {
            this.update(nextProps.daymenuId, nextProps.portionTypeId);
        }
    }

    shouldComponentUpdate(nextProps, state) {
        var newDayMenuId = nextProps.daymenuId != this.state.daymenuId;
        var newPortionTypeId = nextProps.portionTypeId != this.state.portionTypeId;
        var shouldUpdate = newDayMenuId || newPortionTypeId;
        return shouldUpdate;
    }

    update(daymenuId, portionTypeId) {
        $.ajax({
            url: "/mashie/nutrient/graph/rdi?componentId=" + daymenuId + "&portionTypeId=" + portionTypeId + "&factor=" + this.state.factor,
            type: "POST",
            success: function (data) {
                var newrdi = JSON.parse(data);

                this.setState({ rdi: newrdi })
                this.reRender();
                this.forceUpdate();
            }.bind(this)
        })
    }

    render() {
        var canvasStyle = {
            width: "100% !important",
            height: "auto"
        }

        var labelStyle = {
            paddingTop: "5px",
            paddingBottom: "5px",
            fontWeight: "bold"

        }

        var canvasWrapper = {
            textAlign: "center",
            fontSize: "1.5rem",

        }

        return (
            <div style={canvasWrapper}>
                <label style={labelStyle}> {Mashie.Resources.GetString("RDI")} (%) </label>
                <canvas style={canvasStyle} id="daymenuspecificationnutrients"></canvas>
            </div>
        )
    }
}