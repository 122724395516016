import http from 'superagent';

export function feature_recipe_information_active(el) {
    el.click(function (evt) {
        if ($(evt.target).hasClass('recipelabel-templete-label')) {
            var save = $('[js-recipelabel]').find('.active');
            $(save).attr('data-text', tinymce.get($('[js-recipelabel]').find('[js-wysiwyg]').attr('id')).getContent());

            $('[js-recipelabel]').find('.active').removeClass('active');
            $(evt.target).addClass('active');
            if (!tinymce) return;

            var text = $(evt.target).attr('data-text') || $('[js-recipelabel]').find('[js-orginalContent]').html();
            $('[js-recipelabel]').find('[js-wysiwyg]').text(text);
            $('[js-recipelabel]').find('#no-wysiwyg').empty();
            $('[js-recipelabel]').find('#no-wysiwyg').append(text);
            tinymce.get($('[js-recipelabel]').find('[js-wysiwyg]').attr('id')).setContent(text);

            GetAllergens(evt.target);
        }
    });

    function GetAllergens(event) {
        var selectedAllergens = $(event).attr('data-allergens');
        var url = $($('[js-recipelabel]')).find('[js-save-templete]').attr('js-refresh-allergens');

        var readOnly = false;
        var lockedAttr = $('[js-content]').attr('js-is-locked');
        if (typeof lockedAttr !== typeof undefined && lockedAttr !== false) {
            readOnly = true;
        }
        http.post(url).send({ selectedAllergens: JSON.parse(selectedAllergens), readOnly: readOnly }).query({ _: +new Date() }).end(function (result) {
            $('#custom-ingredient-allergens-view').html(result.text);
            $(document).feature();
        });
    }
}