import React from 'react';
import request from 'superagent';
import { Flex, Box } from '../../../react-shared/flexbox';
import { ClimateSearchTextBox } from './ClimateSearchTextBox';
import { ClimateSearchButton } from './ClimateSearchButton'
import { search, searchRecommendations } from './ClimateApiClient'

export class ClimateSearchField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemName: this.props.itemName
        };
    }

    componentDidMount() {
        if (this.props.slvId === '') {
            this.search();
        } else {
            this.searchRecommendation();
        }
    }

    setItemName = (text) => {
        this.setState({ itemName: text });
    }

    search = () => {
        if (this.state.itemName.length == 0) {
            return;
        }

        this.props.setIsSearching(true);

        search(
            this.state.itemName,
            this.props.updateSearchResult,
            () => {
                this.props.updateSearchResult([]);
                this.props.onError();
            },
            () => {
                this.props.setIsSearching(false);
            }
        )
    };

    searchRecommendation = () => {

        this.props.setIsSearching(true);

        searchRecommendations(
            this.props.slvId,
            this.props.updateSearchResult,
            () => {
                this.props.updateSearchResult([]);
                this.props.onError();
            },
            () => {
                this.props.setIsSearching(false);
            }
        )
    }

    render() {
        var isLocked = this.props.isLocked ? (
            <span>
                <span> : </span>
                <span style={{ color: 'red' }}>{Mashie.Resources.GetString('Item.Climate.Locked')}</span>
            </span>
        ) : null;

        return (
            <Flex flexDirection={'row'}>
                <Box flexBasis={'80%'} alignSelf={'flex-end'}>
                    <label>
                        {Mashie.Resources.GetString('Item.Climate.SearchLabel')}
                        {isLocked}
                    </label>
                    <ClimateSearchTextBox
                        value={this.state.itemName}
                        onTextChange={this.setItemName}
                        onEnter={this.search}
                    />
                </Box>
                <Box flexBasis={'20%'} alignSelf={'flex-end'}>
                    <ClimateSearchButton
                        isSearching={this.props.isSearching}
                        onClick={this.search}
                    />
                </Box>
            </Flex>
        );
    }
}