"use strict";

export function Format(text) {
    //copied it from organizationsearchDialogMealpage.2.js

    //http://just3ws.wordpress.com/2007/04/28/javascript-stringformat-method/
    //check if there are two arguments in the arguments list
    if (arguments.length <= 1) {
        //if there are not 2 or more arguments there’s nothing to replace
        //just return the original text
        return text;
    }
    //decrement to move to the second argument in the array
    var tokenCount = arguments.length - 2;
    for (var token = 0; token <= tokenCount; token = token + 1) {
        //iterate through the tokens and replace their placeholders from the original text in order
        text = text.replace(new RegExp("\\{" + token + "\\}", "gi"),
            arguments[token + 1]);
    }
    return text;
}