
export function feature_js_dataview_sort(el) {
    var sortInput = el.find('[js-dataview-sort--input]');
    var asc = sortInput.val().indexOf(':desc') == -1;
    var sortField = sortInput.val().split(':')[0];

    el.find('[js-dataview-sort--key]').each(function() {
        $(this).append($("<span>").addClass("sort fa fa-sort"));
    });

    el.on('click', '[js-dataview-sort--key]', function () {
        var newField = $(this).attr('js-dataview-sort--key');
        asc = (sortField == newField) ? !asc : true;

        sortField = newField;
        sortInput.val(newField + (asc ? "" : ":desc")).change();
        el.closest('form').submit();
    });

    el.find('[js-dataview-sort--key] > .sort').removeClass('fa-sort-down').removeClass('fa-sort-up').addClass('fa-sort');
    el.find('[js-dataview-sort--key="' + sortField + '"] > .sort').removeClass('fa-sort').addClass('fa-sort-' + (asc ? "down" : "up"));
}