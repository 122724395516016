import React from 'react';

var textStyle = {
    borderRadius: '5px  0 0 5px',
    height: '25px'
};

export class ClimateSearchTextBox extends React.Component {
    onTextboxChange = e => {
        this.props.onTextChange(e.target.value);
    }

    onTextboxKeyPressed = e => {
        if (e.charCode == 13) {
            e.preventDefault();
            this.props.onEnter();
        }
    };

    render() {
        return (
            <input
                style={textStyle}
                name="slvName"
                type="text"
                className="form-control"
                value={this.props.value}
                onChange={this.onTextboxChange}
                onKeyPress={this.onTextboxKeyPressed}
            />
        );
    }
}