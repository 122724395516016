"use strict";

export function feature_js_table_filter(el, table) {
    el.on("keyup", function () {
        var match, target = "." + table, criteria = $(this).val().toLocaleLowerCase();
        var hits = $(target).find("[filter]");

        if (hits.length > 0) {
            $.each(hits, function (v, i) {
                var parentRow = $(i).closest("tr");
                match = $(i).text().toLowerCase().match(criteria);

                if (match === null) {
                    $(parentRow).addClass('hidden');
                    $(parentRow).find('[js-table--select-all--row]').prop('checked', false);
                }
                else {
                    $(parentRow).removeClass('hidden');
                }
            });
        }
    });
}