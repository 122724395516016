import { create } from "zustand";
import { CustomerCategory } from "../domain/CustomerCategory";
import { OrderAppConfigState } from "./OrderAppConfigState";
import {
    getAllConfigurations,
    updateConfiguraions,
} from "../apiClient/order-app-config-api";
import { v4 as uuidv4 } from "uuid";

type configurationState = {
    orderAppConfigurations: OrderAppConfigState[];
    customerCategories: CustomerCategory[];
    isGeneralInformationVisible: boolean;
    isMealFreeDescriptionVisible: boolean;
    generalInformation: string;
    mealFreeDescription: string;
};

type configurationsActions = {
    initialState: () => void;
    createConfiguration: () => void;
    updateConfiguration: (configuration: OrderAppConfigState) => void;
    deleteConfiguration: (appConfig: OrderAppConfigState) => void;
    setAllShowIngredients: (checked: boolean) => void;
    setAllShowImages: (checked: boolean) => void;
    setGeneralInformationVisible: (checked: boolean) => void;
    updateGeneralInformation: (information: string) => void;
    setMealFreeDescriptionVisible: (checked: boolean) => void;
    updateMealFreeDescription: (description: string) => void;
    saveOrUpdateConfigurations: () => void;
};

export const useConfigurationsStore = create<
    configurationState & configurationsActions
>((set, get) => ({
    orderAppConfigurations: [],
    customerCategories: [],
    isGeneralInformationVisible: false,
    generalInformation: "",
    isMealFreeDescriptionVisible: false,
    mealFreeDescription: "",
    initialState: async () => {
        const response = await getAllConfigurations();
        set(() => {
            return {
                orderAppConfigurations: response.orderAppConfigurations.map(
                    (config) => {
                        return { ...config, id: `${uuidv4()}` };
                    }
                ),
                customerCategories: response.customerCategories,
                isGeneralInformationVisible:
                    response.isGeneralInformationVisible,
                generalInformation: response.generalInformation,
                isMealFreeDescriptionVisible:
                    response.isMealFreeDescriptionVisible,
                mealFreeDescription: response.mealFreeDescription,
            };
        });
    },
    createConfiguration: () => {
        set((state) => ({
            orderAppConfigurations: [
                ...state.orderAppConfigurations,
                {
                    id: `${uuidv4()}`,
                    customerCategoryId: "",
                    isIngredientVisible: false,
                    isImageVisible: false,
                } as OrderAppConfigState,
            ],
        }));
    },
    updateConfiguration: (configuration) => {
        set((state) => {
            const updatedConfigurations = state.orderAppConfigurations.map(
                (config) =>
                    config.id === configuration.id ? configuration : config
            );
            return { orderAppConfigurations: updatedConfigurations };
        });
    },
    deleteConfiguration: (appConfig) => {
        set((state) => {
            return {
                orderAppConfigurations: state.orderAppConfigurations.filter(
                    (config) => config.id !== appConfig.id
                ),
            };
        });
    },
    setAllShowIngredients: (checked) => {
        set((state) => {
            const updated = state.orderAppConfigurations.map((config) => ({
                ...config,
                isIngredientVisible: checked,
            }));
            return { orderAppConfigurations: updated };
        });
    },
    setAllShowImages: (checked) => {
        set((state) => {
            const updated = state.orderAppConfigurations.map((config) => ({
                ...config,
                isImageVisible: checked,
            }));
            return { orderAppConfigurations: updated };
        });
    },
    setGeneralInformationVisible: (checked) => {
        set(() => {
            return { isGeneralInformationVisible: checked };
        });
    },
    updateGeneralInformation: (information) => {
        set(() => {
            return { generalInformation: information };
        });
    },
    setMealFreeDescriptionVisible: (checked) => {
        set(() => {
            return { isMealFreeDescriptionVisible: checked };
        });
    },
    updateMealFreeDescription: (description) => {
        set(() => {
            return { mealFreeDescription: description };
        });
    },
    saveOrUpdateConfigurations: () => {
        updateConfiguraions(
            get().orderAppConfigurations,
            get().isGeneralInformationVisible,
            get().isMealFreeDescriptionVisible,
            get().generalInformation,
            get().mealFreeDescription
        );
    },
}));
