"use strict";
import http from 'superagent';

export function feature_js_view_calendar(el) {
    el.on('click', '[js-active]', function () {
        var td = $(this);

        http.post($(this).attr('js-active'))
            .end(function () {
                td.closest('tbody').find('td').removeClass('calendar-day-active');
                td.addClass('calendar-day-active');
            });
    });
}