import './new-mail-message';

const load = () => import(/* webpackChunkName: "dashboard" */ "./index.features");

jQuery.feature_import("js-dashboard",
  load,
  mod => mod.feature_js_dashboard
);
jQuery.feature_import("js-widget-iframe",
  load,
  mod => mod.feature_js_widget_iframe
);
jQuery.feature_import("js-waste",
  load,
  mod => mod.feature_js_widget_waste
);
jQuery.feature_import("js-widget-image",
  load,
  mod => mod.feature_js_widget_image
);
jQuery.feature_import("js-widget-orders",
  load,
  mod => mod.feature_js_widget_orders
);
jQuery.feature_import("js-widget-image",
  load,
  mod => mod.feature_js_widget_image
);
jQuery.feature_import("js-widget-poll",
  load,
  mod => mod.feature_js_widget_poll
);
jQuery.feature_import("js-widget-priceListStatus",
  load,
  mod => mod.feature_js_widget_priceListStatus
);
jQuery.feature_import("js-widget-rss",
  load,
  mod => mod.feature_js_widget_rss
);
jQuery.feature_import("js-widget-wysiwyg",
  load,
  mod => mod.feature_js_widget_wysiwyg
);
jQuery.feature_import("js-mail-message",
  () => import("./index.features"),
  mod => mod.feature_js_mail_message
);
jQuery.feature_import("js-widget-videos",
    () => import("./index.features"),
    mod => mod.feature_js_widget_videos
);
jQuery.feature_import("js-txtbox",
  load,
  mod => mod.feature_js_txtbox
);
jQuery.feature_import("js-widget-missingPersonOrders",
    load,
    mod => mod.feature_js_widget_missingPersonOrders
);

jQuery.feature_import("js-widget-hanteraData",
    load,
    mod => mod.feature_js_widget_hanteraData
);