// Import styles - these will be extracted into a separate .css file
import React from "react";
import "font-awesome/css/font-awesome.css";
import "../css/index.less";

import $ from "jquery";
import _ from "lodash";
import caption from "./lib/caption";
import superagent from "superagent";
import moment from "moment";
import { render } from "react-dom";
import App from "./Views/shared/react/App";

// Expose libraries to window, to play nice with other scripts and mashie things
global.jQuery = $;
global.$ = $;
global._ = _;
global.__ = caption;
global.superagent = superagent;
global.moment = moment;

// Add $.browser for plugins that are using it
if (!jQuery.browser) {
  jQuery.browser = {
    msie: false,
    version: 0,
  };
  if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
    jQuery.browser.msie = true;
    jQuery.browser.version = RegExp.$1;
  }
}

jQuery(function () {
  setTimeout(function () {
    $(document.documentElement).removeClass("no-js");
  }, 0);
});

$(document).ajaxSuccess(function () {
  //todo flytta till en fil för saker som ska köras direkt.
  var dirtyAlert = $(".alert-dirty-form");
  dirtyAlert.addClass("hidden");
});

window.UIEVENTS = {};

require("../../Scripts/MashieNamespace.js");
require("../../Scripts/Resources/ResourceHandler.js");
require("./vendor/jquery.browser.js");

require("bootstrap");
require("./vendor/bootstrap-filestyle");
require("./vendor/jquery.feature.js");
require("./vendor/jquery.cookie.js");

require("jquery-ui/themes/base/all.css");

require("jquery-ui/ui/data");
require("jquery-ui/ui/disable-selection");
require("jquery-ui/ui/focusable");
require("jquery-ui/ui/form");
require("jquery-ui/ui/ie");
require("jquery-ui/ui/keycode");
require("jquery-ui/ui/labels");
require("jquery-ui/ui/jquery-patch.js");
require("jquery-ui/ui/plugin");
require("jquery-ui/ui/safe-active-element");
require("jquery-ui/ui/safe-blur");
require("jquery-ui/ui/scroll-parent");
require("jquery-ui/ui/tabbable");
require("jquery-ui/ui/unique-id");
require("jquery-ui/ui/version");
require("jquery-ui/ui/position");
require("jquery-ui/ui/effect");
require("jquery-ui/ui/widget");

require("jquery-ui/ui/widgets/mouse");
require("jquery-ui/ui/widgets/accordion");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/button");
require("jquery-ui/ui/widgets/datepicker");
require("jquery-ui/ui/widgets/dialog");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/droppable");
require("jquery-ui/ui/widgets/menu");
require("jquery-ui/ui/widgets/progressbar");
require("jquery-ui/ui/widgets/resizable");
require("jquery-ui/ui/widgets/selectable");
require("jquery-ui/ui/widgets/selectmenu");
require("jquery-ui/ui/widgets/slider");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/spinner");
require("jquery-ui/ui/widgets/tabs");
require("jquery-ui/ui/widgets/tooltip");

require("jquery-ui/ui/effects/effect-blind");
require("jquery-ui/ui/effects/effect-bounce");
require("jquery-ui/ui/effects/effect-clip");
require("jquery-ui/ui/effects/effect-drop");
require("jquery-ui/ui/effects/effect-explode");
require("jquery-ui/ui/effects/effect-fade");
require("jquery-ui/ui/effects/effect-fold");
require("jquery-ui/ui/effects/effect-highlight");
require("jquery-ui/ui/effects/effect-puff");
require("jquery-ui/ui/effects/effect-pulsate");
require("jquery-ui/ui/effects/effect-scale");
require("jquery-ui/ui/effects/effect-shake");
require("jquery-ui/ui/effects/effect-size");
require("jquery-ui/ui/effects/effect-slide");
require("jquery-ui/ui/effects/effect-transfer");

require("datatables.net");

require("./lib/chartnew.js");
require("./lib/pollyfill.js");
require("./lib/superagent");
require("./lib/language-setup.js");
require("./lib/ConfirmationDialog.js");

require("./features/mediapicker.feature");
require("./features/select-toggler.feature");
require("./features/href-popup");
require("./features/focus-onload.feature");
require("./features/dashboard");
require("./features/inventory-export");
require("./features/domain/recipe/index.js");
require("./features/domain/meal/meal-register-change.feature.js");
require("./features/auto-submit.feature");
require("./features/busy-onclick");
require("./features/input-number-count");
require("./features/stored-query.feature");
require("./features/table");
require("./features/wysiwyg.feature");
require("./features/warn");

require("./features/lockable.feature");
require("./features/form-dirty.feature");
require("./features/domain/item/productdata-init.feature.js");
require("./features/domain/purchase/purchasepage-itemlistcontrol.feature.js");
require("./features/domain/purchase/purchasepage-articlelistcontrol.feature.js");
require("./features/domain/item/itemLink.feature.js");
require("./features/domain/nutrients/nutrients-admin.feature.js");
require("./features/datepicker.feature");
require("./features/store-field.feature");
require("./features/pie-chart.feature.js");
require("./features/charts-bar.feature.js");
require("./features/slider.js");
require("./features/populate.feature.js");
require("./features/draggable-bs-modal.feature.js");
require("./features/open-modal.js");
require("./features/resizable-bs-modal.feature.js");
require("./features/charts/react-pie-chart.feature.js");
require("./features/climate/climate.feature.js");
require("./features/reports/reports.feature.js");
require("./features/person-registry/person-registry.feature.js");
require("./features/react-rdi-old-working.js"); //react-rdi.feature pajade energigrafen (pie-chart)
require("./features/integration-platform/integration-platform.feature.js");
require("./Views/Classification/SystemClassifications.feature.jsx");
require("./Views/OrderListHoliday/OrderListHoliday.feature");
require("./features/mashie-translate-mode.feature");
require("./features/globalization");
require("./features/system-alert/system-alert.feature.js");
require("./features/notifications/notifications.feature.js");
require("./features/meal-economy/meal-economy.feature.js");
require("./features/order-locks/order-locks.feature.js");
require("./features/order-assortment-list/order-assortment-locks.feature");
require("./features/order-assortment-list/order-assortment-clients.feature");
require("./features/notification-admin/notifiation-admin.feature.js");
require("./features/upgradePage/upgrade-page.js");
require('./features/client-diet-settings/client-diet-settings.feature.js');
require('./features/customer-invoice/customer-invoice.js');
require('./features/order-app-config/order-app-config.feature');
require("./features/recycle-bin");
require("./Views/OrderList/js-price-question");

require("../../Scripts/MashieNamespace.js");
require("../../Scripts/CookieHandler.js");
require("../../Scripts/MashieCommon.js");
require("../../Scripts/Resources/ResourceHandler.js");

require("./ui/index");
require("../../Scripts/Pages/MenuMainPage.9.js");
require("../../Scripts/MashieDatePicker.4.js");

require("./Views");

$(document).ready(function () {
  var root = document.createElement("div");
  document.body.appendChild(root);

  render(<App />, root);
});
