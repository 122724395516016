import { observer } from "mobx-react";
import React from "react";
import NotificationAdminStore, { IArea, IScope, IUserAccessFilter } from "./NotificationAdminStore";
import Select from "react-select";
import { Button, Skeleton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, GridCell } from "../../react-shared/Grid/Grid";
import { Modal } from "../../react-shared/Modal/Modal";

@observer
export class NotificationAdminEditDialog extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    await NotificationAdminStore.loadEditModal();
  }

  render() {
    var areaOptions = NotificationAdminStore.getAreas();
    var currentScopes = NotificationAdminStore.getCurrentUserAccessFilter().map(
      (filter: IUserAccessFilter, index: number) => {
        var allScopes = NotificationAdminStore.getScopes(filter.Area);

        return (
          <tr key={index}>
            <td
              style={{ verticalAlign: "top", padding: "10px 10px 10px 0", borderBottom: "1px solid lightgray" }}
              width="300px"
            >
              <Select<IArea>
                onChange={(area) => NotificationAdminStore.editAccessFilter(index, area?.Name as string, undefined)}
                getOptionLabel={(option: IArea) => Mashie.Resources.GetString(option.Name)}
                getOptionValue={(option: IArea) => option.Name}
                noOptionsMessage={() => <div>{Mashie.Resources.GetString("The_List_Is_Empty")}</div>}
                value={filter.Area ? ({ Name: filter.Area } as IArea) : undefined}
                placeholder={Mashie.Resources.GetString("Select_Area")}
                options={areaOptions}
              />
            </td>
            <td style={{ padding: "10px 10px 10px 0", borderBottom: "1px solid lightgray" }} width="300px">
              <Select
                onChange={(scope) => NotificationAdminStore.editAccessFilter(index, filter.Area, scope)}
                getOptionLabel={(scope: IScope) => scope?.Name ?? ""}
                getOptionValue={(scope: IScope) => scope?.Id ?? ""}
                value={[...(filter.Scopes ?? [])]}
                isMulti
                noOptionsMessage={() => <div>{Mashie.Resources.GetString("The_List_Is_Empty")}</div>}
                placeholder={Mashie.Resources.GetString("No_Scope_Filter_Selected")}
                isClearable={true}
                options={allScopes}
              />
            </td>
            <td style={{ padding: "10px 10px 0 10px", borderBottom: "1px solid lightgray", verticalAlign: "top" }}>
              <Button
                color="error"
                style={{ marginLeft: "10px" }}
                variant="outlined"
                onClick={() => NotificationAdminStore.removeAccessFilter(index)}
              >
                <DeleteIcon color="error" fontSize="large" />
              </Button>
            </td>
          </tr>
        );
      }
    );

    return (
      <Modal
        minWidth="750px"
        handleSave={NotificationAdminStore.save}
        handleClose={NotificationAdminStore.unLoadUser}
        isOpen={NotificationAdminStore.isEditMode()}
        headerText={Mashie.Resources.GetString("AddOrEdit")}
      >
        <Grid columnTemplate="auto" rowTemplate="auto">
          <GridCell columnStart="1" columnEnd="1" rowStart="1" rowEnd="1">
            <p>
              {Mashie.Resources.GetString("Username")}: <strong>{NotificationAdminStore.getUser().Username}</strong>
            </p>
            <Button
              style={{ marginBottom: "10px" }}
              variant="contained"
              color="primary"
              disabled={NotificationAdminStore.getAreas().length == 0}
              startIcon={<AddIcon fontSize="large" />}
              onClick={() => NotificationAdminStore.addAccessFilter()}
            >
              {Mashie.Resources.GetString("Add")}
            </Button>
            {NotificationAdminStore.IsLoadingEditModal ? (
              <div>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width="auto" height={30} />
                  <Skeleton variant="rectangular" width="auto" height={30} />
                  <Skeleton variant="rectangular" width="auto" height={30} />
                </Stack>
              </div>
            ) : (
              <table style={{ width: "auto", borderSpacing: "none", borderCollapse: "collapse" }}>
                <tbody>
                  {NotificationAdminStore.getCurrentUserAccessFilter().length > 0 ? (
                    <tr>
                      <td>
                        <strong>{Mashie.Resources.GetString("Area")}</strong>
                      </td>
                      <td>
                        <strong>{Mashie.Resources.GetString("Scope")}</strong>
                      </td>
                    </tr>
                  ) : null}
                  {currentScopes}
                </tbody>
              </table>
            )}
          </GridCell>
        </Grid>
        {/* {JSON.stringify(NotificationAdminStore.getCurrentUserAccessFilter())} */}
      </Modal>
    );
  }
}
