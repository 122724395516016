"use strict";

const allergenGS1Id = '.allergenGS1';
const containsCheckboxId = '.contains input:checkbox';
const canContainCheckboxId = '.canContain input:checkbox';
const containsClassname = 'contains';
const canContainClassname = 'canContain';

export function feature_js_custom_allergens(el) {
    var allergenParentMap = null;

    function init() {
        if (allergenParentMap === null) {
            allergenParentMap = JSON.parse($('#allergenParentMap').val());
        }
    }

    function checkRow(scope, allergenTable) {
        var row = $(scope);
        var gs1 = row.find(allergenGS1Id).val();

        if (row.find(containsCheckboxId).prop('checked') === true) {
            checkParentAllergen(allergenTable, containsCheckboxId, gs1);
        }
        else if (row.find(containsCheckboxId).prop('checked') === false && row.find(allergenGS1Id).val() === el.parent().parent().parent().find(allergenGS1Id).val() && $(el.parent()).hasClass(containsClassname)) {
            uncheckChildAllergens(allergenTable, containsCheckboxId, gs1);
        }

        if (row.find(canContainCheckboxId).prop('checked') === true) {
            checkParentAllergen(allergenTable, canContainCheckboxId, gs1);
        }
        else if (row.find(canContainCheckboxId).prop('checked') === false && row.find(allergenGS1Id).val() === el.parent().parent().parent().find(allergenGS1Id).val() && $(el.parent()).hasClass(canContainClassname)) {
            uncheckChildAllergens(allergenTable, canContainCheckboxId, gs1);
        }
    }

    function checkParentAllergen(allergenTable, checkboxId, gs1) {
        var parent = allergenParentMap[gs1];
        var allergenEl = allergenTable.find(`${allergenGS1Id}[value="${parent}"]`);

        if (allergenEl) {
            var allergenCheckboxEl = allergenEl.parent().parent().find(checkboxId);
            if (allergenCheckboxEl && allergenCheckboxEl.prop('checked') === false) {
                allergenCheckboxEl.prop('checked', true);
            }
        }
    }

    function uncheckChildAllergens(allergenTable, checkboxId, gs1) {
        var children = [];
        Object.keys(allergenParentMap).forEach((key, index) => {
            if (allergenParentMap[key] === gs1) {
                children.push(key)
            }
        })
        for (var i = 0; i < children.length; i++) {
            var allergenEl = allergenTable.find(`${allergenGS1Id}[value="${children[i]}"]`);

            if (allergenEl) {
                var allergenCheckboxEl = allergenEl.parent().parent().find(checkboxId);
                if (allergenCheckboxEl && allergenCheckboxEl.prop('checked') === true) {
                    allergenCheckboxEl.prop('checked', false);
                }
            }
        }
    }

    el.click(function () {
        init();

        var allergenTable = $('#custom-ingredient-allergens-view .tableAllergens > tbody > tr');

        allergenTable.each(function () {
            checkRow(this, allergenTable);
        });

        var allergens = [];

        allergenTable.each(function () {
            var gs1 = $(this).find('.allergenGS1').val();

            if ($(this).find('.contains input:checkbox').prop('checked') === true) {
                allergens.push({ GS1: gs1, Contains: 1 });
                
            }
            else if ($(this).find('.canContain input:checkbox').prop('checked') === true) {
                allergens.push({ GS1: gs1, Contains: 0 });
            }
        });

        $($.find('.recipelabel-templete-continer input:text.active:first')).attr('data-allergens', JSON.stringify(allergens));
    });
}