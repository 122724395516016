import React from 'react';
import ReactDOM from 'react-dom';
import BarChart from '../Views/shared/react/BarChart';


jQuery.feature("js-react-rdi",
    function (element) {

        var graphElement = document.getElementById('specControlRDIGraph');
        var componentId = element.data('id');
        var portionTypeId = element.data('portion');
        ReactDOM.render(<BarChart daymenuId={componentId} portionTypeId={portionTypeId} firstTime={true} />, graphElement);

    });

