import React from "react";
import PropTypes from "prop-types";

export const InvSearchResultRow = ({ onClick, inv, checked }) => {
  const onCheckBoxClick = () => {
    onClick(inv);
  };

  return (
    <li onClick={onCheckBoxClick}>
      <input type="checkbox" checked={checked} />
      <span>{inv.name}</span>
    </li>
  );
};

InvSearchResultRow.propTypes = {
  onClick: PropTypes.func,
  inv: PropTypes.object,
  checked: PropTypes.bool
};
