"use strict";

import superagent from 'superagent';
import rivets from 'rivets';
import _ from 'lodash';
import caption from '../../lib/caption';


export function feature_js_day_menu_information(el) {

    resetPrint();

    var scope = {
        activePortionType: el.find('[js-information-portioning-instruction--portion-typ--select] > option[selected]').val(),
        _100gramsChecked: el.find('[js-information-portioning-instruction--show-100-grams--checkbox]').prop('checked'),
        _100gramsFactor: el.find('[js-information-portioning-instruction--show-100-grams--container]').attr('value'),
        showing: false,
        button: caption("Hide") + " " + caption("TableToolbar_ItemName"),
        nutrientContainer: el.find('[js-day-menu-information-nutrients]'),
        nutrientAmountList: el.find('[js-nutrient-item--amount]'),
        servingSizeList: el.find('[js-information-portioning-instruction--serving-size]'),
    };


    /* EVENTS */
    scope.update = _.throttle(function () {
        updateSelectedPortionType();
        updatePortioningInstructions();
        updateNutrients();
    }, 500);

    scope.update100grams = _.throttle(function () {
        updateNutrients();
    }, 500);

    scope.showDetail = function () {
        var show = (caption("KP_Show") + " " + (caption("TableToolbar_ItemName")));
        var hide = (caption("Hide") + " " + (caption("TableToolbar_ItemName")));
        scope.button = (scope.showing ? hide : show);
        scope.showing = !scope.showing;
        scope.nutrientContainer.toggle('slow');
    };


    /* FUNCTIONS */
    function updateNutrients() {
        var factor;
        if (scope._100gramsChecked) {
            factor = scope._100gramsFactor.replace(',', '.');
        }
        else {
            factor = (scope.activePortionType.split(':')[1].replace(',', '.') / 100).toFixed(5);
        }

        $(scope.nutrientAmountList).each(function () {
            var defaultAmount = $(this).attr('js-default-value').replace(',', '.');
            var newAmount = (defaultAmount * factor).toFixed(2).replace('.', ',');
            $(this).text(newAmount);
        });
    }

    function updatePortioningInstructions() {
        var factor = (scope.activePortionType.split(':')[1].replace(',', '.') / 100).toFixed(5);
       
        $(scope.servingSizeList).each(function () {
            var defaultServingSize = $(this).attr('js-default-value').replace(',', '.');
            var newServingSize = (defaultServingSize * factor).toFixed(2);
            $(this).text(newServingSize);
        });
    }

    function updateSelectedPortionType() {
        var options = $('[js-information-portioning-instruction--portion-typ--select] > option');
        var portionTypes = [];
        options.each(function (index, element) {
            var option = $(element);
            portionTypes.push({
                text: option.text(),
                value: option.val()
            });
        });
        options.remove();

        var portionSelect = $('[js-information-portioning-instruction--portion-typ--select]');

        $.each(portionTypes, function (index, portionType) {
            if (portionType.value === scope.activePortionType) {
                portionSelect.append('<option value="' + portionType.value + '" selected>' + portionType.text + '</option>');
            } else {
                portionSelect.append('<option value="' + portionType.value + '">' + portionType.text + '</option>');
            }
        });
    }

    function resetPrint() {
        $('.information-no-print--container').removeClass('information-no-print--container');
        $(".information-print-modal-checbox--container input:checkbox").attr('checked', true);
    }

    rivets.bind(el, scope);
}