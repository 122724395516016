import React from "react";
import { Flex, Box } from "../../../../react-shared/flexbox";


export class Setting extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const radioStyle = {
      alignSelf: "center",
      margin: "0 10px",
      display: this.props.showRadio === false ? "none" : "block",
    };

    return (
      <div className="note-setting" style={{maxWidth : "300px"}} onClick={this.props.onClick}>
        <Flex
          style={{
            padding: "10px", 
            cursor: "pointer",
            alignItems: "start",
            minHeight: "62px",

          }}
        >
          <Box style={radioStyle}>
            <input              
              readOnly={true}
              checked={this.props.checked}
              type={this.props.checkType}
            ></input>
          </Box>
          <Box style={{ padding: "0px 10px" }}>
            <div style={{ fontSize: "1.3em", fontWeight: "bold" }}>
              {this.props.title}
            </div>
            <div>{this.props.description}</div>
          </Box>
        </Flex>
      </div>
    );
  }
}
