import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import { translate } from "../Translations/labelStrings";

export interface CustomerInvoiceDialogProps {
  open: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  children: ReactNode;
  title?: ReactNode;
  destructive?: boolean;
}

const CustomerInvoiceDialog = ({
  open,
  onConfirm,
  onAbort,
  children,
  title,
}: CustomerInvoiceDialogProps) => {
  return (
    <Dialog open={open} onClose={onAbort} maxWidth="md">
      <DialogTitle style={{textAlign:"center"}}><strong >{title}</strong></DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAbort}>
          <Typography variant="button">{translate("Abort_Button")}</Typography>
        </Button>
        <Button onClick={onConfirm} variant="contained">
          <Typography variant="button">{translate("btn_Save")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerInvoiceDialog;
