import React from 'react';

var buttonStyle = {
    height: '25px',
    width: '50px',
    borderRadius: ' 0 4px 4px 0',
    border: 'none',
    background: '#e6ebf2',
    marginRight: '20px'
};

export class ClimateSearchButton extends React.Component {
    onSearchBtnClick = e => {
        e.preventDefault();
        this.props.onClick();
    }

    render() {
        return (
            <input
                style={buttonStyle}
                id="climate-search"
                type="button"
                value={Mashie.Resources.GetString('Ref_Search')}
                onClick={this.onSearchBtnClick}
                disabled={this.props.isSearching}
            />
        );
    }
}