import React, { useState } from "react";
import {
    MealOption,
    DietPreference,
    DietGroupedResponse,
} from "./Domain/MealSettings";
import DietPreferencesDialog from "./Components/DietPreferencesDialog";
import { useMealOptionsStore } from "./stores/mealOptionsStore";
import {
    DietPreferencesSelection,
    DietPreferencesSingleSelection,
} from "./Components/DietPreferencesSelection";
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    Typography,
    FormControlLabel,
} from "@mui/material";
import { translate } from "./Translations/labelStrings";
import { Item } from "./DietPreferencesShared";
export interface Props {
    open: boolean;
    mealOption: MealOption;
    onConfirm: (
        option: MealOption,
        dietPreferences: DietPreference[],
        setAll: boolean
    ) => void;
    onAbort: () => void;
    isLocked: boolean;
}

const EditDietPreferencesDialog = ({
    mealOption,
    open,
    onAbort,
    onConfirm,
    isLocked,
}: Props) => {
    const loadSelectedPreferences = (
        dietPreferencesResponse: DietGroupedResponse[],
        mealOption: MealOption
    ) => {
        var records: Record<string, string> = {};
        dietPreferencesResponse.forEach((resp) =>
            resp.dietPreferences.forEach((pref) => (records[pref.id] = resp.id))
        );
        var chosenRecords: Record<string, DietPreference[]> = {};
        mealOption.dietPreferences.forEach((pref) => {
            const gruppId = records[pref.id];
            const currentArr = chosenRecords[gruppId] || [];
            chosenRecords[gruppId] = [...currentArr, pref];
        });
        return chosenRecords;
    };

    const { dietPreferencesResponse } = useMealOptionsStore();

    const singleSelectPreferences = dietPreferencesResponse
        .filter((pref) => pref.onlyOne === true)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    const multiSelectPreferences = dietPreferencesResponse
        .filter((pref) => pref.onlyOne !== true)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    const [chosenDietPreferences, setChosenDietPreferences] = useState<
        Record<string, DietPreference[]>
    >(loadSelectedPreferences(dietPreferencesResponse, mealOption));
    const [all, setAll] = useState(false);

    const updateChosenDietPreferences = (
        chosenPreferences: DietPreference[],
        dietGroup: DietGroupedResponse
    ) => {
        chosenDietPreferences[dietGroup.id] = chosenPreferences;
        setChosenDietPreferences({ ...chosenDietPreferences });
    };

    const getChosenPreferences = (): DietPreference[] => {
        return Object.values(chosenDietPreferences).flatMap((array) => array);
    };

    const getChosenByGroup = (
        dietGroup: DietGroupedResponse
    ): DietPreference[] => {
        return chosenDietPreferences[dietGroup.id] || [];
    };

    const onSave = () => {
        onConfirm(mealOption, getChosenPreferences(), all);
    };

    return (
        <Box>
            <DietPreferencesDialog
                open={open}
                onAbort={onAbort}
                onConfirm={onSave}
            >
                <Box>
                    <Typography
                        variant="h4"
                        component={"h1"}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        {mealOption.name}
                    </Typography>
                    {multiSelectPreferences.map((response) => (
                        <DietPreferencesSelection
                            key={response.id}
                            dietGroup={response}
                            onChange={(prefs) =>
                                updateChosenDietPreferences(prefs, response)
                            }
                            selected={getChosenByGroup(response)}
                            isLocked={isLocked}
                        />
                    ))}
                    {singleSelectPreferences.map((response) => (
                        <Box key={response.id} sx={{ flexGrow: 1 }}>
                            <Box>
                                <Typography
                                    variant="h5"
                                    component={"h1"}
                                    fontWeight={"bold"}
                                    paddingBottom={3}
                                    paddingTop={4}
                                >
                                    {response.name}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid>
                                        <Item>
                                            <DietPreferencesSingleSelection
                                                key={response.id}
                                                dietGroup={response}
                                                onChange={(prefs) =>
                                                    updateChosenDietPreferences(
                                                        prefs,
                                                        response
                                                    )
                                                }
                                                selected={getChosenByGroup(
                                                    response
                                                )}
                                                isLocked={isLocked}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))}

                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            border: "none",
                            height: 2,
                            margin: 16,
                        }}
                    ></Divider>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={all}
                                onChange={(e) => setAll(e.target.checked)}
                            />
                        }
                        label={
                            <Typography variant={"h6"} color="text.primary">
                                {translate("CopyMealPreferences")}
                            </Typography>
                        }
                    />
                </Box>
            </DietPreferencesDialog>
        </Box>
    );
};

export default EditDietPreferencesDialog;
