import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { Column } from "../Domain/MealSettings";
import React from "react";
import { FormControlLabel, Typography } from "@mui/material";

interface Props extends CheckboxProps {
    column: Column;
    isLocked: boolean;
}

export const HeaderCheckBox = ({ column, isLocked }: Props) => {
    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={column.checked}
                        onChange={(e) => {
                            if (column.isCheckboxed && column.onChange) {
                                column.onChange(e.target.checked);
                            }
                        }}
                        disabled={isLocked}
                    />
                }
                label={
                    <Typography
                        variant={"h6"}
                        color="text.primary"
                        fontWeight={"bold"}
                    >
                        {column.label}
                    </Typography>
                }
            />
        </>
    );
};
