import rivets from 'rivets';

export function feature_js_populate_origin(element) {

    var scope = {
        origins: []
    }

    function getOrigins() {
        $.ajax({
            url: "/mashie/origin/get",
            success: function (data) {
                var origins = JSON.parse(data);
                scope.origins = origins;
            },
            global: false,
            type : "POST"
        });
    }

    getOrigins();

    rivets.bind(element, scope);
}


export function feature_js_populate_transport_tags(element) {

    var scope = {
        tags: []
    }

    function getTransportTags() {
        $.ajax({
            url: "/mashie/order/get-transport-tags",
            success: function (data) {
                var tags = JSON.parse(data);
                scope.tags = tags;
            },
            global: false,
            type: "POST"
        });
    }

    getTransportTags();

    rivets.bind(element, scope);
}