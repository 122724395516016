import { ThemeProvider } from "@mui/material";
import { createPortal } from "react-dom";
import theme from "./theme";
import usePortals from "./usePortals";
import React from 'react';

const App = ({ }): JSX.Element => {
    const portals = usePortals(state => state.portals);

    return (
        <ThemeProvider theme={theme}>
            {portals.length === 0 && null}
            {portals.map((portal) => createPortal(portal.node, portal.element))}
        </ThemeProvider>
    );
};

export default App;
