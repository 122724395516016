import { ReactNode } from "react";
import create from "zustand";

type PortalDef = {
  node: ReactNode;
  element: HTMLElement;
};

type PortalState = {
  portals: PortalDef[];
  addPortal: (node: ReactNode, element: HTMLElement) => void;
};

const usePortals = create<PortalState>((set) => ({
  portals: [],
  addPortal: (node: ReactNode, element: HTMLElement) => {
    set((state) => ({
      portals: state.portals.concat([
        {
          node,
          element,
        },
      ]),
    }));
  },
}));

// Export singleton instance of addPortal for usage without hooks
export const addPortal = usePortals.getState().addPortal;

export default usePortals;
