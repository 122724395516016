//================================================================//
// Imports
//================================================================//
import React from 'react';
import PropTypes from 'prop-types';
import chartOptions from './DoughnutChartOptions';
import ChartCanvas from './ChartCanvas';
//================================================================//
// Chart Specific Props
//================================================================//
const chartProps = {
    type: "doughnut",
    prefix: "all",
    title: "Energy_Distribution_Chart_Title"
}

let currentId = 0;

/**
 * Class to represent our doughnut chart.  Initiates our chart
 * instance and manages ctx and data opertaions.
 */
class EnergyDistributionDoughnutChart extends React.Component {
    constructor() {
        super();
        this.canvasId = 'EnergyDistributionDoughnutChart-' + currentId++;
    }
    //================================================================//
    // Class Methods
    //================================================================//
     /**
     * Returns a generated object to set our chart with.  
     */
    settings = () => ({
        type: chartProps.type,
        data: this.props.allChart.energy,
        options: chartOptions()
    });

    /**
     * We only want our chart to initiate once the dom is in place, so wait to start it until the 
     * component has updated. 
     */
    componentDidUpdate() {
        const { sum }   = this.props;
        const ctx       = document.getElementById(this.canvasId).getContext('2d');
        const chart     = new Chart(ctx, this.settings());
        //document.getElementById(`${chartProps.prefix}-legend`).innerHTML = generateLegend(chart, sum);
    }
    //================================================================//
    // Render Method
    //================================================================//
    render() {
        const legend = {
            chart: {
                config: { data: this.props.allChart.energy }
            },
            sum: this.props.sum
        };

        return (
            <ChartCanvas 
                prefix={chartProps.prefix}
                canvasId={this.canvasId}
                title={chartProps.title}
                legendSettings={legend}
            />
        );
    }
}

//================================================================//
// Default Props
//================================================================//
EnergyDistributionDoughnutChart.defaultProps = {
}
//================================================================//
// PropTypes
//================================================================//
EnergyDistributionDoughnutChart.propTypes = {
    allChart: PropTypes.object.isRequired,
    sum: PropTypes.number.isRequired
}
//================================================================//
// Export
//================================================================//
export default EnergyDistributionDoughnutChart;