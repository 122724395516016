import React from "react";

export const Dropdown = ({ options, label, type, onChange }) => {
  const selectedOptionDidChange = event => {
    onChange({ [type.name]: event.target.value });
  };

  return (
    <div className="inventory-export__dropdown">
      <label>{label}</label>
      <select onChange={selectedOptionDidChange}>
        {options.map(filter => {
          return (
            <option value={filter.id} key={`${filter.name}${filter.id}`}>
              {filter.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
