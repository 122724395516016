import moment from 'moment';
import _ from 'lodash';
import rivets from 'rivets';
import caption from './caption';


rivets.binders.block = { block: true };

rivets.formatters.eq = function(value, opt) {
    return value == opt;
};

rivets.formatters.lte = function(value, opt) {
    return Number(value) <= Number(opt);
};

rivets.formatters.not = function (value) { return !value; };


rivets.formatters['in'] = function (value) {
    return _.includes(_.map(_.toArray(arguments).slice(1), String), String(value));
};

rivets.formatters.date = {
    read: function(value, format) {
        return moment(value).format(format || 'L');
    },
    publish: function(value, format) {
        return moment(value, format || 'L').format();
    }
};

rivets.formatters.format = function(value, format) {
    var fmt = _.toArray(arguments).slice(1).join(' ');
    return _.template(fmt, value, {
        escape: /\[([\s\S]+?)\]/g,
        evaluate: /\[%([\s\S]+?)%\]/g,
        interpolate: /\[\[([\s\S]+?)\]\]/g
    });
};

rivets.formatters.caption = function(value, format) {
    var key = format ? rivets.formatters.format({ key: value }, format) : value;
    return caption(key);
};

rivets.refresh = function () {} // used for IE8 to detect changes.
if ($('html').is(' .ie8')) {
    rivets.adapters['.'] = (function() {
        var self = {}, _callbacks = [], _callbackCounter = 0, _id = "_rv_pulse";
        self.callbackId = function (obj) { return obj.hasOwnProperty(_id) ? obj[_id] : (obj[_id] = _callbackCounter++); }
        self.callbacks = function (obj) { var id = self.callbackId(obj); return _callbacks[id] || (_callbacks[id] = []); }
        self._callbacks = _callbacks;
        
        self.refresh = function () {
            for(var i=0; i<_callbacks.length; ++i) {
                if (!_callbacks[i] || !_callbacks[i].length) continue;
                for(var j=0; j<_callbacks[i].length; ++j) {
                    _callbacks[i][j].refresh();
                }
            }
        };
        
        self.stub = function stub(obj, keypath, fn) {
            return function() {
                var result = fn.apply(this, arguments);
                var callbacks = self.callbacks(obj);
                for(var i=0; i<callbacks.length; ++i) {
                    if(callbacks[i].keypath === keypath) callbacks[i].callback();
                }
                return result;
            }
        }
        
        function hookArray(obj, arr) {
            if (arr && !arr._hookedArray && Object.prototype.toString.call(arr) === "[object Array]") {
                var stubs = ['push', 'pop', 'shift', 'unshift', 'sort', 'reverse', 'splice'];
                for(var i = 0; i<stubs.length; ++i) arr[stubs[i]] = self.stub(obj, arr[stubs[i]]);
                arr._hookedArray = true;
            }
        }
        
        self.observe = function(obj, keypath, callback) {
            var getter = function() { return self.get(obj, keypath); }, oldValue = _.clone(getter()), callbacks = self.callbacks(obj);
            callbacks.push({ id: self.callbackId(obj), keypath: keypath, callback: callback, refresh: refresh });
            hookArray(obj, keypath, obj[keypath]);
            
            function refresh() {
                var newValue = getter();
                if (_.isEqual(newValue, oldValue)) return;
                hookArray(obj, keypath, newValue);
                oldValue = _.clone(newValue);
                callback();
            };
        };
        
        self.unobserve = function(obj, keypath, callback) {
            var callbacks = self.callbacks(obj);
            for (var i=callbacks.length-1; i>=0; --i) {
                if (callbacks[i].keypath !== keypath || callbacks[i].id !== self.callbackId(obj) || callbacks[i].callback !== callback) continue;
                callbacks.splice(i, 1);
            }
        };
        
        self.get = function(obj, keypath) { return obj[keypath]; };
        self.set = function(obj, keypath, value) { obj[keypath] = value; setTimeout(self.refresh, 50); };
        return self;
    })();

    if (rivets.adapters['.'].refresh) rivets.refresh = rivets.adapters['.'].refresh;
    setInterval(rivets.refresh, 2000);
}

export default rivets;